/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditBudgetName from "../components/Modals/EditBudgetName";
import { BudgetCategoryAmount, BudgetItem, BudgetVariant, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, ReceiptEmailItem, TimeframeItem, TransactionItem, USCurrency } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Button, Grid, IconButton } from "@mui/material";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import BudgetReport from "../components/Modals/BudgetReport";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import moment, { Moment } from "moment";
import Divider from "../components/Inputs/Divider";
import Transaction from "../components/ListItems/Transaction";
import EditTransaction from "../components/Modals/EditTransaction";
import { ChevronDown, ChevronLeft, ChevronRight, CircleFill, PencilSquare } from "react-bootstrap-icons";
import EditBudgetColor from "../components/Modals/EditBudgetColor";
import EditBudgetCategories from "../components/Modals/EditBudgetCategories";
import EditBudgetPeriod from "../components/Modals/EditBudgetPeriod";
import EditBudgetTimeframe from "../components/Modals/EditBudgetTimeframe";
import EditBudget from "../components/Modals/EditBudget";
import DropdownMenu from "../components/Inputs/DropdownMenu";
import EditBudgetPeriodEnd from "../components/Modals/EditBudgetPeriodEnd";
import EditBudgetUsers from "../components/Modals/EditBudgetUsers";
import EditBudgetOther from "../components/Modals/EditBudgetOther";

const BudgetTransactions = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();
	let dispatch = useDispatch();

	let routeInfo = location.pathname.toLowerCase().split('/');
	let budgetId = parseInt(routeInfo[2]);
	let routeDate = moment(routeInfo[3], 'YYYY-MM-DD', true);
	if (isNaN(budgetId) || budgetId < 1 || routeInfo.length < 4 || !routeDate.isValid()) {
		navigate('/budgets');
	}

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				UpdateDate(routeDate);
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		} else {
			UpdateDate(routeDate);
		}
	}, []);

	let [currentDate, setCurrentDate] = useState(new DateTime());
	let curDate: DateTime = new DateTime();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions = repo.SortedTransactions(null) ?? undefined;
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let budgetTree = repo.GetBudgetTree();
	let receiptEmails: ReceiptEmailItem[] = useSelector((state: any) => state.receiptEmails.value);

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}

	let hasExternal = (transactions?.filter(t => t.isExternal)?.length ?? 0) > 0;

	let budget = budgets.find(b => b.budgetId === budgetId);
	if (budget) {
		transactions = transactions?.filter(t => t.transactionDate >= budget!.variant.startDate && t.transactionDate < budget!.endDate! && t.budgetCategoryAmounts.map(c => c.budgetId).includes(budgetId));
	} else {
		transactions = [] as TransactionItem[];
	}

	let catString = categories.filter((c: CategoryItem) => budget?.categoryIds.map(c => c.id).includes(c.categoryId))!.map((c: CategoryItem) => c.description).join(", ")
	catString = catString.length < 18 ?
		catString :
		`${catString.substring(0, 15)}...`;

	return (
		<>
			<Grid container columnSpacing={2} justifyContent="center" className='p-3' style={{ color: budget?.color }}>
				<Grid item xs={8}>
					<h2 className="mt-2 mb-3">{budget?.description}</h2>
				</Grid>
				<Grid item xs={4} className='align-content-center'>
					{user.isHoH && <>
						<PencilSquare className='mx-2 cursor-pointer' onClick={() => OpenBudgetModal('name')} />
						<CircleFill className='mx-2 cursor-pointer' onClick={() => OpenBudgetModal('color')} />
					</>}
				</Grid>
				{budget && <>
					<Grid item xs={8} className='mb-5'>
						Categories: {catString}
					</Grid>
					<Grid item xs={4} className='align-content-top'>
						{user.isHoH && <PencilSquare className='mx-2 cursor-pointer' onClick={() => OpenBudgetModal('cats')} />}
					</Grid>
					<Grid item xs={3} className='mb-3 text-app centered small'>
						{budget.activeTimeframes.filter(t => t.startDate && budget && t.startDate < budget.variant.startDate).length > 0 &&
							<ChevronLeft className="h3" onClick={BackDate} />
						}
					</Grid>
					<Grid item xs={6} className='mb-3 text-app centered small'>
						{((budget!.variant.timeframeId ?? budget!.timeframeId) === -3 && !budget!.variant.endDate) && <>Starting </>}
						{budget!.variant.startDate.toString()}
						{((budget!.variant.timeframeId ?? budget!.timeframeId) !== -3 || budget!.variant.endDate) && <> to {budget!.endDate!.addDays(-1).toString()}</>}
					</Grid>
					<Grid item xs={3} className='mb-3 text-app centered small'>
						{budget.activeTimeframes.filter(t => (!t.endDate && t.id !== -3) || (t.endDate && t.endDate > currentDate)).length > 0 &&
							<ChevronRight className="h3" onClick={ForwardDate} />
						}
					</Grid>
					<Grid item xs={12} className="px-5 text-success">
						{USCurrency(budget!.amountSpent)}
						{(budget!.variant.amount > 0) && <> of {USCurrency(budget!.variant.amount).substring(1)}</>}
					</Grid>
					<Grid item xs={12} className={`mb-3 px-5 ${budget!.amountRemaining > 0 ? 'text-app' : 'text-danger'}`}>
						{(budget!.variant.amount > 0) && <>{USCurrency(Math.abs(budget!.amountRemaining))} {budget!.amountRemaining >= 0 ? "left" : "over"}</>}
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" onClick={OpenModal} className="bg-app text-white">View Report</Button>
					</Grid>
					{user.isHoH && <>
						<Grid item xs={6} className="text-end">
							<DropdownMenu OpenElement={
								<IconButton
									className="text-app"
									aria-label='edit menu'>
									More
									<ChevronDown className='mx-2 px-1' />
								</IconButton>
							}
								Items={AddMenuItems() ?? []}>
							</DropdownMenu>
						</Grid>
					</>}
				</>}
			</Grid>
			<Grid container rowSpacing={3} justifyContent="center" className="mt-1">
				<Grid item xs={12} className='centered'>
					<h3 className="mt-2 text-app">Transactions</h3>
				</Grid>
				{transactions != null && (
					<>
						{transactions.length > 0 && (
							<Grid container>
								<>
									{
										transactions.map(transaction => {
											return (
												<div className="w-100" key={`transactdiv${transaction.accountTransactionId}`}>
													{!IsCurrentDate(transaction) && (
														<Divider
															Text={curDate.toString()}
															key={`date${transaction.accountTransactionId}`} />
													)}
													<Transaction
														Item={transaction}
														Color={transaction.color}
														Categories={categories}
														HasExternal={hasExternal}
														OnClick={() => OpenTransactionModal(transaction.accountTransactionId)}
														key={`transaction${transaction.accountTransactionId}`} />
												</div>
											);
										})
									}
								</>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function BackDate() {
		let start = budget!.variant.startDate.addDays(-1);
		navigate(`/budget/${budgetId}/${start.toQueryString()}`, { replace: true });
		repo.GetBudgetsWithDateAsync(start);
		setCurrentDate(start);
	}

	async function ForwardDate() {
		let end = budget!.endDate!;
		budgets = await repo.GetBudgetsWithDateAsync(end);

		budgetId = parseInt(routeInfo[2]);
		budget = budgets.find(b => b.budgetId === budgetId);
		if (budget?.variant.timeframeId !== -3) {
			end = budget!.endDate!.addDays(-1);
		} else {
			let possibleDates = transactions!.map(t => t.transactionDate.Value.getTime());
			possibleDates.push(curDate.Value.getTime());
			end = new DateTime(new Date(Math.max.apply(null, possibleDates)));
		}

		navigate(`/budget/${budgetId}/${end.toQueryString()}`, { replace: true });
		if (Math.abs(end.dayDiff(new DateTime(routeDate))) > 0) {
			repo.GetBudgetsWithDateAsync(end);
			setCurrentDate(end);
		}
	}

	function AddMenuItems() {
		let result: any[] = [];

		result.push({
			Text: 'Change the goal for the selected period',
			OnClick: () => OpenBudgetModal('per')
		});

		result.push({
			Text: 'End current budget period early/late',
			OnClick: () => OpenBudgetModal('pend')
		});

		result.push({
			Text: 'View/Edit active times for this budget',
			OnClick: () => OpenBudgetModal('time')
		});

		result.push({
			Text: 'Add/Remove users for this budget',
			OnClick: () => OpenBudgetModal('user')
		});

		result.push({
			Text: 'Other budget settings',
			OnClick: () => OpenBudgetModal('oth')
		});

		return result;
	}

	//Budgets
	function SaveBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		let variant = { ...item.variant };
		HideModal();
		repo.UpdateBudgetAsync(item, variant, item.budgetId === 0, false)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetName(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetNameAsync(item)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetColor(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetColorAsync(item)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetCategories(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetCategoriesAsync(item)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetAccount(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetAccountsAsync(item)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetGoal(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		let variant = { ...item.variant };
		repo.UpdateBudgetGoalAsync(item, variant)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetEndDate(item: BudgetItem) {
		if (!user.isHoH) return;
		HideModal();
		let variant = { ...item.variant };
		repo.UpdateBudgetEndDateAsync(item, variant)
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function DeleteBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		repo.UpdateBudgetAsync(item, item.variant, false, true);
		HideModal();
		navigate('/budgets');
	}

	function OpenModal() {
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				categoryIds: budget.categoryIds,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;
			modal = {
				...modal,
				Body: <BudgetReport Budget={modalBudget} Categories={categories} Transactions={transactions} CheckRequests={checkRequests} StartDate={modalBudget.variant.startDate} EndDate={new DateTime()} User={user} OpenBudget={() => OpenBudgetModal("")} Close={HideModal} ViewReport={ViewReport} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenBudgetModal(property: string) {
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				activeTimeframes: budget.activeTimeframes,
				categoryIds: budget.categoryIds,
				endDate: budget.endDate,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate,
						defaultEndDate: budget.variant.defaultEndDate,
						timeframe: budget.variant.timeframe,
						timeframeId: budget.variant.timeframeId
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				isViewTransactions: budget.isViewTransactions,
				isIncome: budget.isIncome,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;

			let item: JSX.Element = <EditBudget Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} Close={HideModal} />;
			switch (property) {
				case "name":
					item = <EditBudgetName Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetName} Close={HideModal} />;
					break;
				case "color":
					item = <EditBudgetColor Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetColor} Close={HideModal} />;
					break;
				case "cats":
					item = <EditBudgetCategories Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetCategories} Close={HideModal} />;
					break;
				case "per":
					item = <EditBudgetPeriod Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetGoal} Close={HideModal} />;
					break;
				case "pend":
					item = <EditBudgetPeriodEnd Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetEndDate} Close={HideModal} />;
					break;
				case "time":
					item = <EditBudgetTimeframe Item={modalBudget} Categories={categories} Timeframes={timeframes} OnDelete={DeleteBudget} Close={HideModal} User={user} CurrentDate={currentDate} />;
					break;
				case "user":
					item = <EditBudgetUsers Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetCategories} Close={HideModal} />;
					break;
				case "oth":
					item = <EditBudgetOther Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudgetCategories} Close={HideModal} />;
					break;
			}

			modal = {
				...modal,
				Body: item,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function ViewReport() {
		HideModal();
		navigate("/report/categories");
	}

	//Transactions
	async function SaveTransaction(item: TransactionItem) {
		HideModal();
		let bcas = [] as BudgetCategoryAmount[];
		for (let i = 0; i < item.budgetCategoryAmounts.length; i++) {
			let amount = { ...item.budgetCategoryAmounts[i] };
			if (item.miles && item.miles > 0) {
				amount.amount = item.miles * (item.rate ?? 0);
			}
			bcas = [...bcas, amount]
		}
		let rec = item.recurringTransaction;
		if (rec) {
			rec.accountId = item.accountId;
			rec.budgetId = item.budgetCategoryAmounts[0].budgetId;
			rec.categoryId = item.budgetCategoryAmounts[0].categoryId;
			rec.description = item.merchant;
			rec.nextAmount = item.budgetCategoryAmounts.reduce((total, t) => total + t.amount, 0);
			rec.nextDate = item.transactionDate.addDays(rec.days).addMonths(rec.months);
		}
		item = {
			...item,
			budgetCategoryAmounts: bcas,
			recurringTransaction: rec
		};
		if (item.receiptEmailIds && item.receiptEmailIds.length > 0) {
			item.receiptEmailIds.forEach(id => {
				repo.UpdateReceiptEmailAsync(receiptEmails.find(r => r.receiptEmailId === id)!, false, true);
			})
		}
		if (item.importedTransactionId) {
			repo.UpdateTransactionAsync(transactions!.find(r => r.accountTransactionId === item.importedTransactionId)!, false, true);
		}
		await repo.UpdateTransactionAsync(item, item.accountTransactionId === 0, false);
		repo.GetBudgetsAsync();
		repo.GetCategoriesAsync();
	}

	function DeleteTransaction(item: TransactionItem) {
		repo.UpdateTransactionAsync(item, false, true);
		HideModal();
	}

	function OpenTransactionModal(idx: number) {
		let transaction = transactions!.find(t => t.accountTransactionId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <EditTransaction Mileage={transaction.miles != null} ReceiptEmails={receiptEmails} Transaction={transaction} Budgets={budgetTree} Transactions={transactions?.filter(t => !t.isExternal)} Categories={categories} OnSave={SaveTransaction} OnDelete={DeleteTransaction} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	async function UpdateDate(newValue: Moment) {
		let end = new DateTime(newValue);
		budgets = await repo.GetBudgetsWithDateAsync(end);

		budgetId = parseInt(routeInfo[2]);
		budget = budgets.find(b => b.budgetId === budgetId);
		if ((budget?.variant.timeframeId ?? budget?.timeframeId) !== -3 || budget?.variant.endDate != null) {
			end = budget!.endDate!.addDays(-1);
		}
		else {
			let possibleDates = transactions!.map(t => t.transactionDate.Value.getTime());
			possibleDates.push(curDate.Value.getTime());
			end = new DateTime(new Date(Math.max.apply(null, possibleDates)));
		}

		if (Math.abs(end.dayDiff(new DateTime(routeDate))) > 0) {
			navigate(`/budget/${budgetId}/${end.toQueryString()}`, { replace: true });
			repo.GetBudgetsWithDateAsync(end);
			setCurrentDate(end);
		}
	}

	function IsCurrentDate(transaction: TransactionItem) {
		if (curDate.Value.getTime() !== new DateTime(transaction.transactionDate.Value).startOfDay().Value.getTime()) {
			curDate = new DateTime(transaction.transactionDate.Value).startOfDay();
			return false;
		}
		return true;
	}
}

export default BudgetTransactions;