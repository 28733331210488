/* eslint-disable react-hooks/exhaustive-deps */
import NavMenu from './NavMenu';
import { useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import { pageLoad } from '../../App';
import { CurrentUser, FamilyFeatureValue } from '../../types';
import { useItems } from '../../features/Fetch/Items';
import Modal from "../Modals/Modal";
import { useAuth } from '../../features/Fetch/Auth';
import NavBar from './NavBar';
import { useLocation } from 'react-router-dom';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { Features } from '../../constants';

const Layout = (props: any) => {
	let isMobile: boolean = useSelector((state: any) => state.isMobile.value);
	let userData: CurrentUser = useSelector((state: any) => state.userData.value);
	let [getData, setGetData] = useState(false);
	let isLoading = false;
	let repo = useItems();
	let auth = useAuth();
	let location = useLocation();

	useEffect(() => {
		if (userData.token && userData.isActive) {
			pageLoad.instance = 2;
			setGetData(true);
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				if (!location.pathname.startsWith('/budget/')) {
					repo.GetBudgetsAsync();
				}
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetAccountsAsync();
				repo.GetTransactionsAsync(100)
					.then(() => repo.GetTransactionsAsync(-1))
					.then(() => {
						setGetData(false);
					});
				repo.GetReceiptEmailsAsync();
				repo.GetUsersAsync();
				repo.GetRecurringTransactionsAsync();
				repo.GetCheckRequestsAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
				setGetData(false);
			});
		}
	}, []);

	return (
		<div>
			{location.pathname.startsWith('/report/') ?
				props.children
				:
				isMobile ? 
					<div className="page">
						<NavMenu GetData={setGetData} />

						<main>
							<article className="content px-1">
								{isLoading &&
									<h1>Loading...</h1>
								}
								{props.children}
							</article>
						</main>

						<div className="bottombar">
							<NavBar TransactionTitle={`${FamilyFeatureValue(userData, Features.TransactionTitle)}s`} />
						</div>
					</div>
					:
					<>
						<Sidebar GetData={setGetData} />
						<div className="desktop-page px-4">
							<main>
								<article className="content">
									{isLoading &&
										<h1>Loading...</h1>
									}
									{ props.children }
								</article>
							</main>
						</div>
					</>
			}
			<Snackbar open={getData} onClose={HandleRetrievingClose}>
				<Alert severity="info" icon={<CircularProgress size={20} />} onClose={HandleRetrievingClose}>
					Retrieving Data
				</Alert>
			</Snackbar>
			<Modal />
		</div>
	);

	function HandleRetrievingClose(event?: SyntheticEvent | Event, reason?: string) {
		if (reason === 'clickaway') return;

		setGetData(false);
	}
}

export default Layout;