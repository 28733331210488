import { useState } from "react";
import { BudgetItem, ColorHexes } from "../../types";
import { Grid } from "@mui/material";
import { Circle } from "@uiw/react-color";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditBudgetColor = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);

	return (<>
		<ModalHeader Title="Budget Color" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
						<Grid item xs={12}>
							Color:
							<Circle color={budget.color} colors={ColorHexes} onChange={UpdateColor} />
						</Grid>
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateColor(newValue: any) {
		let item = ({ ...budget, color: newValue.hex });
		setBudget(item);
	}
}

export default EditBudgetColor;