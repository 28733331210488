import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { DateTime } from "../../types";
import Date from "../Inputs/Date";

const EditTimePeriodDate = (props: any) => {
	let [startDate, setStartDate] = useState((props.Current ?? null) as DateTime | null);

	return (
		<Dialog open={true} onClose={props.Close}>
			<DialogContent className="px-5 mt-3 pb-0">
				<Date Borderless Size={6} Label={props.Label} Value={startDate} OnChange={UpdateStartDate} NoError />
				<Button className="mx-3 my-0" onClick={() => UpdateStartDate(null)} variant="text">Clear</Button>
				{props.Message}
			</DialogContent>
			<DialogActions className="px-5 center">
				<Button className="mx-3 mb-4" onClick={props.Close} variant="outlined">Cancel</Button>
				<Button onClick={() => props.Confirm(startDate)} variant="contained" className="bg-success mx-3 mb-4">Confirm</Button>
			</DialogActions>
		</Dialog>
	);

	function UpdateStartDate(newValue: any) {
		setStartDate(newValue ? new DateTime(newValue) : null);
	}
}

export default EditTimePeriodDate;