/* eslint-disable react/jsx-no-undef */
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { BudgetItem, CategoryIdExtended, CategoryItem, CurrentUser, DateTime, FamilyFeatureValue, TransactionItem } from "../../types";

const CategoryLimited = (props: any) => {
	let category: CategoryItem = props.Item;
	let budget: BudgetItem = props.Budget;
	let active: CategoryIdExtended = props.Active;
	let isActive: boolean = active.active;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let transactions: TransactionItem[] = active.transactions;
	let checkRequests: TransactionItem[] = active.checkRequests;

	let [expanded, setExpanded] = useState((props.IsError || props.IsOverlap) as boolean);
	let [checked, setChecked] = useState(props.Checked);

	useEffect(() => {
		setChecked(props.Checked);
	}, [props.Checked]);

	let tCount = transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).length;
	let cCount = checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).length;
	let counts = [] as string[];
	if (tCount > 0) {
		counts.push(`${tCount} ${FamilyFeatureValue(user, Features.TransactionTitle)}${tCount > 1 ? 's' : ''}`);
	}
	if (cCount > 0) {
		counts.push(`${cCount} Check Request${cCount > 1 ? 's' : ''}`);
	}

	let rowNum = budget.categoryIdsExtended.indexOf(active);

	return (
		<Grid container className={`py-2`} style={{ textAlign: 'left' }}>
			<Accordion className="w-100" expanded={expanded}>
				<AccordionSummary expandIcon={<ChevronDown />} onClick={() => setExpanded(!expanded)}>
					<Grid container>
						{!props.IsError && !props.IsOverlap &&
							<Grid item xs={2} xl={1}>
								<Checkbox checked={checked ?? false} onClick={(e: any) => e.stopPropagation()} onChange={SetChecked} />
							</Grid>
						}
						{expanded ? <>
							<Grid item alignContent="center" xs={10} xl={6}>
								<h3 className={`${(props.IsError || props.IsOverlap || props.Alert) ? 'text-danger' : 'text-app'} mb-0`}>{category.description}{active.isNew ? " (new)" : active.isUpdated ? " (updated)" : ""}</h3>
							</Grid>
						</> : <>
								<Grid item alignContent="center" xs={10} xl={6}>
								<h3 className={`${(props.IsError || props.IsOverlap || props.Alert) ? 'text-danger' : 'text-app'} mb-0`}>{category.description}{active.isNew ? " (new)" : active.isUpdated ? " (updated)" : ""}</h3>
							</Grid>
							<Grid item alignContent="center" xs={12} xl={5}>
								{GetActiveSummaryShort()}
							</Grid>
						</>}
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					{props.IsError ?
						<Grid item className="mt-3 text-danger" alignContent="center" xs={12}>
							{GetUnusageSummary()}
						</Grid>
						: props.IsOverlap ?
							<Grid item className="text-danger" alignContent="center" xs={12}>
								{GetOverlapSummary()}
							</Grid>
							: <>
								<Grid item alignContent="center" xs={12}>
									{GetActiveSummary()}
								</Grid>
								<Grid item className="mt-3" alignContent="center" xs={12}>
									{GetUsageSummary()}
								</Grid>
							</>}
				</AccordionDetails>
			</Accordion>
		</Grid >
	);

	function SetChecked(event: any, newValue: boolean) {
		setChecked(newValue);
		props.OnChecked(rowNum, newValue);
	}

	function GetActiveSummary() {
		if (active.item.startDate && active.item.endDate) {
			return `Active between ${active.item.startDate.toString()} and ${active.item.endDate.toString()}`
		} else if (active.item.startDate) {
			return `Active ${isActive ? 'since' : 'starting'} ${active.item.startDate.toString()}`
		} else if (active.item.endDate) {
			return `${isActive ? 'Expires' : 'Expired'} ${active.item.endDate.toString()}`
		}
		return 'Category is always active';
	}

	function GetOverlapSummary() {
		if (active.item.startDate && active.item.endDate) {
			return `Overlapping Dates: Active between ${active.item.startDate.toString()} and ${active.item.endDate.toString()}`
		} else if (active.item.startDate) {
			return `Overlapping Dates: Active ${isActive ? 'since' : 'starting'} ${active.item.startDate.toString()}`
		} else if (active.item.endDate) {
			return `Overlapping Dates: ${isActive ? 'Expires' : 'Expired'} ${active.item.endDate.toString()}`
		}
		return 'Overlapping Dates: Category is always active';
	}

	function GetActiveSummaryShort() {
		if (active.item.startDate && active.item.endDate) {
			return `${active.item.startDate.toString()} - ${active.item.endDate.toString()}`
		} else if (active.item.startDate) {
			return `${isActive ? 'Since' : 'Starting'} ${active.item.startDate.toString()}`
		} else if (active.item.endDate) {
			return `${isActive ? 'Expires' : 'Expired'} ${active.item.endDate.toString()}`
		}
		return '';
	}

	function GetUsageSummary() {
		let result = `Category has not been assigned to any ${FamilyFeatureValue(user, Features.TransactionTitle)}s`;
		if (counts.length > 0) {
			result = `Usage: ${counts.join(' and ')}`;

			let earliestTransaction = new Date(Math.min.apply(null, transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let earliestCR = new Date(Math.min.apply(null, checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let latestTransaction = new Date(Math.max.apply(null, transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let latestCR = new Date(Math.max.apply(null, checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			if (tCount === 0) {
				earliestTransaction = new Date('2999-12-31');
				latestTransaction = new Date('2000-01-01');
			}
			if (cCount === 0) {
				earliestCR = new Date('2999-12-31');
				latestCR = new Date('2000-01-01');
			}

			let first = new DateTime(earliestTransaction < earliestCR ? earliestTransaction : earliestCR);
			let last = new DateTime(latestTransaction > latestCR ? latestTransaction : latestCR);

			if (first.startOfDay() === last.startOfDay()) {
				result += ` on ${first.toString()}`;
			} else {
				result += ` from ${first.toString()} to ${last.toString()}`;
			}
		}
		return result;
	}

	function GetUnusageSummary() {
		let result = `Category has not been assigned to any ${FamilyFeatureValue(user, Features.TransactionTitle)}s`;
		if (counts.length > 0) {
			result = `Category has ${counts.join(' and ')}`;

			let earliestTransaction = new Date(Math.min.apply(null, transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let earliestCR = new Date(Math.min.apply(null, checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let latestTransaction = new Date(Math.max.apply(null, transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			let latestCR = new Date(Math.max.apply(null, checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).map(t => t.transactionDate.Value.getTime())));
			if (tCount === 0) {
				earliestTransaction = new Date('2999-12-31');
				latestTransaction = new Date('2000-01-01');
			}
			if (cCount === 0) {
				earliestCR = new Date('2999-12-31');
				latestCR = new Date('2000-01-01');
			}

			let first = new DateTime(earliestTransaction < earliestCR ? earliestTransaction : earliestCR);
			let last = new DateTime(latestTransaction > latestCR ? latestTransaction : latestCR);

			if (first.startOfDay() === last.startOfDay()) {
				result += ` on ${first.toString()}`;
			} else {
				result += ` between ${first.toString()} and ${last.toString()}`;
			}
			result += " that are not covered by an Active period."
		}
		return result;
	}
}

export default CategoryLimited;