/* eslint-disable react/jsx-no-undef */
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { BudgetItem, CategoryIdExtended, CategoryItem, CurrentUser, FamilyFeatureValue, TransactionItem } from "../../types";

const CategoryLimitedOverlap = (props: any) => {
	let category: CategoryItem = props.Item;
	let budget: BudgetItem = props.Budget;
	let active: CategoryIdExtended = props.Active;
	let isActive: boolean = active.active;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let transactions: TransactionItem[] = active.transactions;
	let checkRequests: TransactionItem[] = active.checkRequests;

	let tCount = transactions?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).length;
	let cCount = checkRequests?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === category.categoryId && c.budgetId === budget.budgetId).length > 0).length;
	let counts = [] as string[];
	if (tCount > 0) {
		counts.push(`${tCount} ${FamilyFeatureValue(user, Features.TransactionTitle)}${tCount > 1 ? 's' : ''}`);
	}
	if (cCount > 0) {
		counts.push(`${cCount} Check Request${cCount > 1 ? 's' : ''}`);
	}

	let rowNum = budget.categoryIdsExtended.indexOf(active);

	return (
		<Grid container className={`py-2`} style={{ textAlign: 'left' }}>
			<Grid item className="text-danger" alignContent="center" xs={12}>
				<h3 className='text-danger mb-0 d-inline'>{category.description}{active.isNew ? " (new)" : active.isUpdated ? " (updated)" : ""}</h3>
				, ({GetOverlapSummary()}) has active dates that overlap a duplicate category.
			</Grid>
		</Grid >
	);

	function GetOverlapSummary() {
		if (active.item.startDate && active.item.endDate) {
			return `active between ${active.item.startDate.toString()} and ${active.item.endDate.toString()}`
		} else if (active.item.startDate) {
			return `active ${isActive ? 'since' : 'starting'} ${active.item.startDate.toString()}`
		} else if (active.item.endDate) {
			return `${isActive ? 'expires' : 'expired'} ${active.item.endDate.toString()}`
		}
		return 'always active';
	}
}

export default CategoryLimitedOverlap;