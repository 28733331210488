import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CurrentUser, FamilyFeatureValue, FamilyHasAccess, TransactionItem, UserItem } from '../../types';
import Divider from '../Inputs/Divider';
import { useAuth } from '../../features/Fetch/Auth';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ArrowClockwise, ArrowLeftCircle, CaretDownFill, PlusLg } from 'react-bootstrap-icons';
import { useItems } from '../../features/Fetch/Items';
import { Features } from '../../constants';
import { useState } from 'react';

const Sidebar = (props: any) => {
	let currentUser: CurrentUser = useSelector((state: any) => state.userData.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let location = useLocation();
	let auth = useAuth();
	let repo = useItems();
	let navigate = useNavigate();
	let [menuOpen, setMenuOpen] = useState(false);
	let [anchor, setAnchor] = useState<null | HTMLElement>(null);

	if (!currentUser) return <></>;

	const isHoH = props.PassedIn ? props.IsHoH : currentUser.isActive && currentUser.isHoH;
	let canUseTransactions = FamilyHasAccess(currentUser, Features.Transactions);
	let links: any[] = [];
	if (canUseTransactions) {
		links.push({
			to: "/",
			text: `${FamilyFeatureValue(currentUser, Features.TransactionTitle)}s`,
			icon: "bi-currency-dollar",
			alt: "/"
		});
	}
	if (FamilyHasAccess(currentUser, Features.Budgets)) {
		links.push({
			to: "/Budgets",
			text: "Budgets",
			icon: "bi-piggy-bank"
		});
	}
	if (isHoH && FamilyHasAccess(currentUser, Features.Recurring)) {
		links.push({
			to: "/Recurrings",
			text: "Repeating",
			icon: "bi-arrow-repeat"
		});
	}
	if (FamilyHasAccess(currentUser, Features.Reports)) {
		links.push({
			to: "/Reports",
			text: "Reports",
			icon: "bi-file-bar-graph"
		});
	}
	if (FamilyHasAccess(currentUser, Features.CheckRequests)) {
		links.push({
			to: "/CheckRequests",
			text: "Check Requests",
			icon: "bi-card-text"
		});
	}
	let links2: any[] = [];
	if (isHoH && FamilyHasAccess(currentUser, Features.Users)) {
		links2.push({
			to: "/Users",
			text: `Manage Users`
		});
	}
	if (currentUser.isActive) {
		links2.push({
			to: "/Settings",
			text: "Settings"
		});
	}

	let needsApproval = props.PassedIn ? props.NeedsApproval : (transactions?.filter(t => t.needsMyApproval).length > 0 || checkRequests?.filter(c => c.needsMyApproval || c.checkRequest!.needsMyApproval).length > 0) && canUseTransactions;
	let hasRejections = props.PassedIn ? props.HasRejections : transactions?.filter(i => i.isMine && i.budgetCategoryAmounts.find(b => b.isRejected)).length > 0 && canUseTransactions;
	const hasNeedsReview = props.PassedIn ? props.HasNeedsReview : isHoH && transactions?.filter(t => t.isAdminReview).length > 0 && canUseTransactions;

	let path = location.pathname + location.hash;

	let specialLinks: any[] = [];
	if (needsApproval) {
		specialLinks.push({
			to: "/Approval",
			text: `Needs Approval`
		});
	}
	if (hasRejections) {
		specialLinks.push({
			to: "/Rejections",
			text: "Rejected Transactions"
		});
	}
	if (hasNeedsReview) {
		specialLinks.push({
			to: "/Review",
			text: "Needs Review"
		});
	}

	return (
		<nav className="flex-column leftbar">
			<header className="top-leftbar">
				<div className='d-flex align-items-center brandbar'>
					<div className="flex-grow-1"></div>
					<div>
						<Link to="/" className="navbar-brand">
							<div className="logo"></div>
							<div className="text-white align-self-center">Shekl</div>
						</Link>
					</div>
					<div className="flex-grow-1" style={{ textAlign: 'right' }}>
						<IconButton
							className="text-white"
							aria-label='filter'
							onClick={ReloadData}>
							<ArrowClockwise />
						</IconButton>
					</div>
				</div>
			</header>
				<div className="centered py-3 mt-3">
					<div onClick={OpenMenu}>
						<h4 className="text-white align-self-center d-inline-flex">{currentUser.familyName} Family</h4>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="end">
							<CaretDownFill style={{ color: 'white' }} size="15" />
						</IconButton>
					</div>
					<Menu
						anchorEl={anchor}
						open={menuOpen}
						onClose={CloseMenu}
						closeAfterTransition={true}
						anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
						<div className='px-3 pt-1'>
							<ArrowLeftCircle className='mr-2' onClick={CloseMenu} />&nbsp;
							Switch Family
						</div>
						<Divider />
						<MenuItem disabled>
							<ListItemText>
								{currentUser.familyName} Family
							</ListItemText>
						</MenuItem>
					{users && users.find(u => u.isMe)!.families!.filter(f => f.familyName !== currentUser.familyName).map(family =>
						<MenuItem onClick={() => ChangeFamilyContext(family.familyId)} key={`sbfamilies${family.familyId}`}>
								<ListItemText>
									{family.familyName}
								</ListItemText>
							</MenuItem>
						)}
					<Divider />
					<MenuItem onClick={() => { navigate('/addfamily'); CloseMenu(); }}>
							<ListItemIcon>
								<PlusLg />
							</ListItemIcon>
							<ListItemText>
								Connect To New Family
							</ListItemText>
						</MenuItem>
					</Menu>
				</div>
			{currentUser.isActive && links.map(link => {
				return (
					<div className="nav-item px-3" key={links.indexOf(link)}>
						<Link to={link.to} className={`nav-link ${(path === link.to || path === link.alt) ? "active" : ""}`} onClick={props.OnClick}>
							<span className={`bi ${link.icon} col-1 mx-2`} aria-hidden="true"></span> {link.text}
						</Link>
					</div>
				);
			})}
			{currentUser.isActive && specialLinks.map(link => {
				return (
					<div className="nav-item px-3" key={`special${links2.indexOf(link)}`}>
						<Link to={link.to} className={`nav-link mx-2 ${(path === link.to || path === link.alt) ? "active" : "highlighted"}`} onClick={props.OnClick}>
							{link.text}
						</Link>
					</div>
				);
			})}
			<Divider />
			{links2.map(link => {
				return (
					<div className="nav-item px-3" key={`link2${links2.indexOf(link)}`}>
						<Link to={link.to} className={`nav-link mx-4 ${(path === link.to || path === link.alt) ? "active" : ""}`} onClick={props.OnClick}>
							{link.text}
						</Link>
					</div>
				);
			})}
			<div className="nav-item px-3">
				<Link to="/" className={`nav-link mx-4`} onClick={Logout}>
					Logout
				</Link>
			</div>
		</nav>
	);

	function Logout() {
		auth.logout();
		props.OnClick();
	}

	function ReloadData() {
		props.GetData(true);
		if (currentUser.token && currentUser.isActive) {
			auth.setUserContext().then(v => {
				let fetchQuest = () => {
					Promise.all([
						//repo.GetLocalItems(),
						repo.GetAccountsAsync(),
						repo.GetBudgetsAsync(),
						repo.GetCategoriesAsync(),
						repo.GetTimeframesAsync(),
						repo.GetTransactionsAsync(-1),
						repo.GetReceiptEmailsAsync(),
						repo.GetUsersAsync(),
						repo.GetRecurringTransactionsAsync(),
						repo.GetCheckRequestsAsync()
					]).then(p => {
						props.GetData(false);
					});
				}
				fetchQuest();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
				props.GetData(false);
			});
		}
	}

	async function ChangeFamilyContext(familyId: number) {
		CloseMenu();
		await auth.changeFamilyContext(familyId);
		window.location.reload();
	}

	function OpenMenu(event: React.MouseEvent<HTMLElement>) {
		setMenuOpen(true);
		setAnchor(event.currentTarget);
	}
	function CloseMenu() {
		setMenuOpen(false);
		setAnchor(null);
	}
}

export default Sidebar;