import Transactions from "./pages/Transactions";
import CheckRequests from "./pages/CheckRequests";
import Budgets from "./pages/Budgets";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import Approval from "./pages/Approval";
import Recurrings from "./pages/Recurrings";
import AddFamily from "./pages/AddFamily";
import FilteredBudgets from "./pages/FilteredBudgets";
import BudgetTransactions from "./pages/BudgetTransactions";
import BalanceBudgets from "./pages/BalanceBudgets";

const AppRoutes = [
	{
		index: true,
		element: <Transactions />
	},
	{
		path: '/approval',
		element: <Approval />
	},
	{
		path: '/rejections',
		element: <Transactions />
	},
	{
		path: '/review',
		element: <Transactions />
	},
	{
		path: '/unbudget',
		element: <Transactions />
	},
	{
		path: '/emails',
		element: <Transactions />
	},
	{
		path: '/transactions',
		element: <Transactions />
	},
	{
		path: '/budgets',
		element: <Budgets />
	},
	{
		path: '/budgets/*',
		element: <FilteredBudgets />
	},
	{
		path: '/budget/*',
		element: <BudgetTransactions />
	},
	{
		path: '/balancebudgets',
		element: <BalanceBudgets />
	},
	{
		path: '/checkrequests',
		element: <CheckRequests />
	},
	{
		path: '/recurrings',
		element: <Recurrings />
	},
	{
		path: '/users',
		element: <Users />
	},
	{
		path: '/settings',
		element: <Settings />
	},
	{
		path: '/reports',
		element: <Reports />
	},
	{
		path: '/addfamily',
		element: <AddFamily />
	}
];

export default AppRoutes;
