import { useState } from "react";
import Date from "../Inputs/Date";
import { BudgetItem, DateTime } from "../../types";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditBudgetPeriodEnd = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);

	return (<>
		<ModalHeader Title="Edit Budget Period" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Date Size={12} Label="Set a Budget Period Custom End Date" Value={budget.variant.endDate ?? budget.variant.defaultEndDate} OnChange={UpdateDate} NoError />
				{`The new date must be between ${budget.variant.defaultEndDate.addDays(-4)} and ${budget.variant.defaultEndDate.addDays(2)}.`}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		if (budget.variant.endDate && (budget.variant.endDate.Value > budget.variant.defaultEndDate.addDays(2).Value || budget.variant.endDate.Value < budget.variant.defaultEndDate.addDays(-4).Value)) return false;
		return true;
	}

	function UpdateDate(newValue: any) {
		let variant = { ...budget.variant, endDate: new DateTime(newValue) }
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
}

export default EditBudgetPeriodEnd;