/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditTimeframe from "../components/Modals/EditTimeframe";
import { AccountItem, BudgetItem, CategoryItem, CurrentUser, DateTime, FamilyFeatureValue, FamilyHasAccess, SelectableItem, TimeframeItem, TransactionItem, UserEmailItem, UserItem } from "../types";
import { Button, Grid } from "@mui/material";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { useItems } from "../features/Fetch/Items";
import EditCategory from "../components/Modals/EditCategory";
import TextInput from "../components/Inputs/TextInput";
import Error from "../components/Error";
import PasswordInput from "../components/Inputs/PasswordInput";
import Amount from "../components/Inputs/Amount";
import { PlusLg } from "react-bootstrap-icons";
import Category from "../components/ListItems/Category";
import Timeframe from "../components/ListItems/Timeframe";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import UserEmail from "../components/ListItems/UserEmail";
import EditUserEmail from "../components/Modals/EditUserEmail";
import Dropdown from "../components/Inputs/Dropdown";
import { Features } from "../constants";
import Account from "../components/ListItems/Account";
import EditAccount from "../components/Modals/EditAccount";
import Toggle from "../components/Inputs/Toggle";
import Link from "../components/Inputs/Link";
import Divider from "../components/Inputs/Divider";
import UpdateLink from "../components/Inputs/UpdateLink";
import RemoveLink from "../components/Inputs/RemoveLink";

const Settings = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let accounts: AccountItem[] = useSelector((state: any) => state.accounts.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let primaryEmail = user.emails!.find(e => e.email === user.email);

	let navigate = useNavigate();
	let [section, setSection] = React.useState(1);
	let [error, setError] = useState('');
	let [error2, setError2] = useState('');
	let [hasLength, setHasLength] = useState(false);
	let [hasLower, setHasLower] = useState(false);
	let [hasUpper, setHasUpper] = useState(false);
	let [hasNumber, setHasNumber] = useState(false);
	let [hasSpecial, setHasSpecial] = useState(false);
	let isValidPassword = hasLength && hasLower && hasUpper && hasNumber && hasSpecial;

	let me = users?.find(u => u.isMe);
	let setting = {
		FirstName: me?.firstName,
		LastName: me?.lastName,
		Email: user.email,
		Password: '',
		ConfirmPassword: '',
		Rate: user.mileageRate,
		AllowUnassigned: user.unassignedBudgets,
		IncludeAllUsersInBudgets: user.includeAllUsersInBudgets,
		FamilyName: user.familyName
	};
	let [settings, setSettings] = useState(setting);
	if (settings.FirstName !== setting.FirstName && !settings.FirstName) {
		let me = users?.find(u => u.isMe);
		let s = {
			...settings,
			FirstName: me?.firstName,
			LastName: me?.lastName,
		};
		setSettings({ ...s });
	}

	let useEmail = FamilyHasAccess(user, Features.UserEmails);
	let useCategories = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Categories);
	let useTimeframes = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Timeframes);
	let useAccounts = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Accounts);

	let instit: number = 0;

	let sections: SelectableItem[] = [
		{ Id: 1, Value: 'My Profile' }
	];
	if (useEmail) {
		sections.push({ Id: 2, Value: 'My Email Addresses' });
	}
	if (user.isHoH) {
		sections.push({ Id: 3, Value: 'Family Settings' });
	}
	if (useCategories) {
		sections.push({ Id: 4, Value: 'Family Categories' });
	}
	if (useTimeframes) {
		sections.push({ Id: 5, Value: 'Family Timeframes' });
	}
	if (useAccounts) {
		sections.push({ Id: 6, Value: 'Family Accounts' });
	}

return (<>
	{users && user && <>
		<Dropdown Size={4}
			Class="mb-5 right"
			Variant="standard"
			Options={sections}
			Value={section}
			OnChange={setSection} />
		{section === 1 &&
			<Grid container spacing={2} className="my-5">
				<TextInput Required Id="inputFirst" Label="First Name" Value={settings.FirstName} Size={6} OnChange={(e: any) => FirstNameChange(e.target.value)} />
				<TextInput Required Id="inputLast" Label="Last Name" Value={settings.LastName} Size={6} OnChange={(e: any) => LastNameChange(e.target.value)} />
				{useEmail &&
					<Dropdown Size={12}
						Class="mb-0 bg-white"
						Label="Primary Email"
						Options={GetEmailDict()}
						Value={user.emails!.find(e => e.email === settings.Email)!.userEmailId}
						OnChange={EmailChange} />
				}
				<PasswordInput Id="inputPassword" Label="Create New Password" Value={settings.Password} Size={12} OnChange={(e: any) => PasswordChange(e.target.value)} />
				<PasswordInput Id="confirmPassword" Label="Confirm New Password" Value={settings.ConfirmPassword} Size={12} OnChange={(e: any) => ConfirmPasswordChange(e.target.value)} />
				<Grid item xs={7} textAlign="left" style={{ display: settings.Password.length > 0 ? "block" : "none" }}>
					{(!hasLength || !hasLower || !hasUpper || !hasNumber || !hasSpecial) &&
						<>
							<div>Password must have the following:</div>
							<div style={{ color: "orange", display: hasLength ? "none" : "block" }}>8 or more characters</div>
							<div style={{ color: "orange", display: hasLower ? "none" : "block" }}>Lowercare letter</div>
							<div style={{ color: "orange", display: hasUpper ? "none" : "block" }}>Capital letter</div>
							<div style={{ color: "orange", display: hasNumber ? "none" : "block" }}>Number</div>
							<div style={{ color: "orange", display: hasSpecial ? "none" : "block" }}>Special character</div>
						</>
					}
				</Grid>
				<Grid xs={3}></Grid>
				<Grid xs={6} style={{ display: ShowSave() ? "block" : "none" }}>
					<Button variant="contained" className="centered col-12 bg-success btn-large mt-4" onClick={SaveSettings}>SAVE</Button>
				</Grid>
				<Grid xs={12} style={{ display: (user.email !== settings.Email && ShowSave()) ? "block" : "none" }}>
					<div className="text-warning mx-3 centered">
						Changing your primary email address will require you to log in to the site again.
					</div>
				</Grid>
				<Grid item xs={12}>
					<Error Text={error} />
				</Grid>
			</Grid>
		}
		{section === 3 &&
			<Grid container spacing={2} className="my-5">
				{user.isHoH && user.isActive && <>
					<TextInput Required Id="inputFamilyName" Label="Family Name" Value={settings.FamilyName} Size={12} OnChange={(e: any) => FamilyNameChange(e.target.value)} />
					<Amount NoDollar Size={12} Value={settings.Rate} Label="Mileage Rate" OnChange={(val: any) => RateChange(val.target.value)} />
					<Grid xs={1}></Grid>
					<Toggle Size={12} OnChange={AllUnassignedChange} Checked={settings.AllowUnassigned} Label={`Allow limited users to assign ${FamilyFeatureValue(user, Features.TransactionTitle)}s to all budgets`} />
					<Toggle Size={12} OnChange={IncludeAllUsersInBudgetsChange} Checked={settings.IncludeAllUsersInBudgets} Label={`Automatically include all users in new budgets`} />
				</>}
				<Grid xs={3}></Grid>
				<Grid xs={6} style={{ display: ShowSave2() ? "block" : "none" }}>
					<Button variant="contained" className="centered col-12 bg-success btn-large mt-4" onClick={SaveSettings2}>SAVE</Button>
				</Grid>
				<Grid item xs={12}>
					<Error Text={error2} />
				</Grid>
			</Grid>
		}
		{useEmail && section === 2 && <>
				<UserEmail Item={primaryEmail} OnClick={() => { }} IsPrimary />

				{user.emails!.filter(e => e.email !== user.email).slice().sort((a, b) => a.email.localeCompare(b.email)).map(email =>
					<UserEmail Item={email} OnClick={() => OpenUserEmailModal(email.userEmailId)} />
				)}
				<div className="col-12">
					<div className="btn btn-userEmail btn-list text-app" onClick={() => OpenNewUserEmailModal()}>
						<h3>
							<PlusLg /> Add New Email Address
						</h3>
					</div>
				</div>
		</>}
		{useCategories && section === 4 && <>
			{categories.slice().sort((a, b) => a.description.localeCompare(b.description)).map(category =>
				<Category Item={category} OnClick={() => OpenCategoryModal(category.categoryId)} />
			)}
			<div className="col-12">
				<div className="btn btn-category btn-list text-app" onClick={() => OpenNewCategoryModal()}>
					<h3>
						<PlusLg /> Add New Category
					</h3>
				</div>
			</div>
		</>}
		{useTimeframes && section === 5 && <>
			{timeframes.slice().sort((a, b) => a.description.localeCompare(b.description)).map(timeframe =>
				<Timeframe Item={timeframe} OnClick={() => OpenTimeframeModal(timeframe.timeframeId)} />
			)}
			<div className="col-12">
				<div className="btn btn-timeframe btn-list text-app" onClick={() => OpenNewTimeframeModal()}>
					<h3>
						<PlusLg /> Add New Timeframe
					</h3>
				</div>
			</div>
		</>}
		{useAccounts && section === 6 && <>
			&nbsp;
			{accounts
				.filter(a => a.financialInstitution)
				.slice()
				.sort((a, b) => a.description.localeCompare(b.description))
				.sort((a, b) => a.differentiator! - b.differentiator!)
				.sort((a, b) => a.financialInstitution!.localeCompare(b.financialInstitution!))
				.map(account =>
					<>
						{!IsCurrentInstitution(account) && (<>
							<Divider
								Text={account.financialInstitution}
								key={`institution${account.financialInstitution}`} />
							<Grid container justifyContent="right">
								<UpdateLink Account={account} />
								<RemoveLink Id={account.differentiator} />
							</Grid>
						</>)}
						<Account Item={account} OnClick={() => OpenAccountModal(account.accountId)} />
					</>
				)}
			<Link />
		</>}
	</>}
</>);

	function IsCurrentInstitution(account: AccountItem) {
		if (instit !== account.differentiator) {
			instit = account.differentiator!;
			return false;
		}
		return true;
	}
	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//UserEmails
	async function SaveUserEmail(item: UserEmailItem) {
		let response: UserEmailItem = await repo.UpdateUserEmailAsync(item, item.userEmailId === 0, false);
		if (response.isDuplicate) {
			modal = {
				...modal,
				Body: <EditUserEmail Item={response} OnSave={SaveUserEmail} OnDelete={response.userEmailId > 0 ? DeleteUserEmail : undefined} Close={HideModal} />,
			}
			dispatch(setProps(modal));
		} else {
			auth.setUserContext();
			HideModal();
		}
	}

	function DeleteUserEmail(item: UserEmailItem) {
		repo.UpdateUserEmailAsync(item, false, true);
		auth.setUserContext();
		HideModal();
	}

	function OpenNewUserEmailModal() {
		let userEmail = {
			userEmailId: 0,
			email: '',
			isDuplicate: false,
			isVerified: false
		} as UserEmailItem;
		modal = {
			...modal,
			Body: <EditUserEmail Item={userEmail} OnSave={SaveUserEmail} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenUserEmailModal(idx: number) {
		let userEmail = user.emails!.find(t => t.userEmailId === idx);
		if (userEmail) {
			modal = {
				...modal,
				Body: <EditUserEmail Item={userEmail} OnSave={SaveUserEmail} OnDelete={DeleteUserEmail} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function GetEmailDict() {
		let result: SelectableItem[] = [];
		user.emails!.filter(e => e.isVerified).forEach(e => result.push({
			Id: e.userEmailId,
			Value: e.email
		}));
		return result;
	}

	//Categories
	async function SaveCategory(item: CategoryItem) {
		repo.UpdateCategoryAsync(item, item.categoryId === 0, false);
		HideModal();
	}

	function DeleteCategory(item: CategoryItem) {
		repo.UpdateCategoryAsync(item, false, true);
		HideModal();
	}

	function OpenNewCategoryModal() {
		let category = {
			categoryId: 0,
			description: '',
			isViewTransactions: true
		};
		modal = {
			...modal,
			Body: <EditCategory Item={category} OnSave={SaveCategory} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenCategoryModal(idx: number) {
		let category = categories.find(t => t.categoryId === idx)!;

		let tCount = transactions?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
		let bCount = budgets?.filter(b => b.categoryIds.map(c => c.id).includes(category.categoryId)).length;
		let cCount = checkRequests?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
		let inUse = tCount > 0 || bCount > 0 || cCount > 0;

		if (category) {
			modal = {
				...modal,
				Body: <EditCategory Item={category} OnSave={SaveCategory} OnDelete={inUse ? undefined : DeleteCategory} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	//Timeframes
	function SaveTimeframe(item: TimeframeItem) {
		repo.UpdateTimeframeAsync(item, item.timeframeId === 0, false);
		HideModal();
	}

	function DeleteTimeframe(item: TimeframeItem) {
		repo.UpdateTimeframeAsync(item, false, true);
		HideModal();
	}

	function OpenNewTimeframeModal() {
		let timeframe = {
			timeframeId: 0,
			days: 0,
			months: 0,
			description: '',
			startDate: new DateTime()
		};
		modal = {
			...modal,
			Body: <EditTimeframe Item={timeframe} OnSave={SaveTimeframe} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenTimeframeModal(idx: number) {
		let timeframe = timeframes.find(t => t.timeframeId === idx)!;

		let bCount = budgets?.filter(b => b.timeframeId === timeframe.timeframeId).length;
		let inUse = bCount > 0;

		if (timeframe) {
			modal = {
				...modal,
				Body: <EditTimeframe Item={timeframe} OnSave={SaveTimeframe} OnDelete={inUse ? undefined : DeleteTimeframe} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	//Accounts
	async function SaveAccount(item: AccountItem) {
		await repo.UpdateAccountAsync(item, item.accountId === 0, false);
		repo.GetAccountsAsync();
		HideModal();
	}

	function DeleteAccount(item: AccountItem) {
		repo.UpdateAccountAsync(item, false, true);
		HideModal();
	}

	function OpenAccountModal(idx: number) {
		let account = accounts.find(t => t.accountId === idx);
		if (account) {
			modal = {
				...modal,
				Body: <EditAccount Item={account} Users={users} OnSave={SaveAccount} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	//User and Fmaily Settings
	function FirstNameChange(newValue: string) {
		setSettings({ ...settings, FirstName: newValue });
	}
	function LastNameChange(newValue: string) {
		setSettings({ ...settings, LastName: newValue });
	}
	function EmailChange(newValue: string) {
		setSettings({ ...settings, Email: user.emails!.find(e => e.userEmailId === parseInt(newValue))!.email });
	}
	function PasswordChange(newValue: string) {
		let validationRegex = [
			{ regex: /.{8,}/ },
			{ regex: /[0-9]/ },
			{ regex: /[a-z]/ },
			{ regex: /[A-Z]/ },
			{ regex: /[^A-Za-z0-9]/ },
		];

		setHasLength(validationRegex[0].regex.test(newValue));
		setHasNumber(validationRegex[1].regex.test(newValue));
		setHasLower(validationRegex[2].regex.test(newValue));
		setHasUpper(validationRegex[3].regex.test(newValue));
		setHasSpecial(validationRegex[4].regex.test(newValue));

		setSettings({ ...settings, Password: newValue });
	}
	function ConfirmPasswordChange(newValue: string) {
		setSettings({ ...settings, ConfirmPassword: newValue });
	}
	function RateChange(newValue: string) {
		setSettings({ ...settings, Rate: parseFloat(newValue) });
	}
	function AllUnassignedChange(event: any, newValue: boolean) {
		setSettings({ ...settings, AllowUnassigned: newValue });
	}
	function IncludeAllUsersInBudgetsChange(event: any, newValue: boolean) {
		setSettings({ ...settings, IncludeAllUsersInBudgets: newValue });
	}
	function FamilyNameChange(newValue: string) {
		setSettings({ ...settings, FamilyName: newValue });
	}

	function ShowSave() {
		let should = false;
		me = users.find(u => u.isMe);
		if (me !== undefined && (settings.FirstName !== me.firstName || settings.LastName !== me.lastName)) {
			should = true;
		}
		if (settings.Email !== setting.Email) {
			should = true;
		}
		if (settings.Password) {
			if (!settings.Password || !isValidPassword) {
				error = 'Your password must fit the complexity requirements.';
				return false;
			} else if (settings.Password !== settings.ConfirmPassword) {
				error = 'Your passwords must match.';
				return false;
			} else {
				should = true;
			}
		}
		return should;
	}
	function ShowSave2() {
		let should = false;
		if (settings.Rate !== setting.Rate || settings.AllowUnassigned !== setting.AllowUnassigned || settings.IncludeAllUsersInBudgets !== setting.IncludeAllUsersInBudgets || settings.FamilyName !== setting.FamilyName) {
			should = true;
		}
		return should;
	}

	async function SaveSettings() {
		me = users.find(u => u.isMe);
		if (me !== undefined && (settings.FirstName !== me.firstName || settings.LastName !== me.lastName)) {
			await repo.UpdateUserAsync({
				...me,
				firstName: settings.FirstName ?? me.firstName,
				lastName: settings.LastName ?? me.lastName
			}, false, false);
		}
		if (settings.Password) {
			if (!settings.Password || !isValidPassword) {
				setError('Your password must fit the complexity requirements.');
			} else if (settings.Password !== settings.ConfirmPassword) {
				setError('Your passwords must match.');
			} else {
				let result = await repo.UpdateUserPasswordAsync({ FirstName: "First", LastName: "Last", Code: "NotReal", Email: settings.Email, Password: settings.Password, PasswordConfirm: settings.ConfirmPassword });
				setError(result);
			}
		}
		if (settings.Email !== setting.Email) {
			await repo.UpdatePrimaryEmailAsync(settings.Email ?? setting.Email ?? "");
			auth.logout();
			navigate("/");
		} else {
			auth.setUserContext();
		}
	}
	async function SaveSettings2() {
		if (settings.Rate !== setting.Rate || settings.AllowUnassigned !== setting.AllowUnassigned || settings.IncludeAllUsersInBudgets !== setting.IncludeAllUsersInBudgets || settings.FamilyName !== setting.FamilyName) {
			let result = await auth.updateUserContext({
				...user,
				mileageRate: settings.Rate,
				unassignedBudgets: settings.AllowUnassigned,
				familyName: settings.FamilyName,
				includeAllUsersInBudgets: settings.IncludeAllUsersInBudgets
			});
			setError2(result);
		}
		auth.setUserContext();
	}
}

export default Settings;