/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BudgetCategoryAmount, BudgetItem, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, RoundToCent, TimeframeItem, TransactionItem, USCurrency } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Button, Divider, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import AmountWithTooltip from "../components/Inputs/AmountWithTooltip";
import { QuestionCircle } from "react-bootstrap-icons";

const BalanceBudgets = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();

	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let filteredBudgets = budgets.filter(b => !b.isInactive);

	let canLoad = transactions && timeframes && categories && budgets && user;

	let earliest = new DateTime();
	let sumUnbudgeted = 0;

	filteredBudgets = filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0);
	earliest = new DateTime([...filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0)].map(b => b.variant.startDate.Value).reduce((minDate: Date, currentDate: Date) => currentDate < minDate ? currentDate : minDate, new DateTime().Value));
	sumUnbudgeted = ([] as BudgetCategoryAmount[]).concat.apply([], transactions?.filter(t => t.transactionDate.startOfDay().Value >= earliest.Value).map(t => t.budgetCategoryAmounts?.filter(a => a.budgetId < 1))).reduce((total, t) => total + t.amount, 0);

	let sumLimited = filteredBudgets.reduce((total, t) => total + t.amountSpent, 0);
	let limitLimited = filteredBudgets.reduce((total, t) => total + t.variant.amount, 0);
	let overLimit = sumLimited > limitLimited;
	let ttColor = 'info';

	let [newTarget, setNewTarget] = useState(false);
	let [target, setTarget] = useState(RoundToCent(limitLimited - sumLimited));
	let [newBudgets, setNewBudgets] = useState(filteredBudgets);
	let [showSave, setShowSave] = useState(false);

	if (filteredBudgets.length !== newBudgets.length) {
		setNewBudgets(filteredBudgets);
		setTarget(RoundToCent(limitLimited - sumLimited));
	}

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}
	if (!FamilyHasAccess(user, Features.Income)) {
		navigate("/budgets/all");
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<h2 className="mt-2 text-app">Balance Budgets</h2>
				</Grid>
				<Grid item xs={3} className={`mt-3`}>
					<Tooltip
						disableFocusListener
						title={<>These numbers represent the amount <b>remaining</b> in your budgets based on transactions that have been entered and budgeted to date. Changing the values in the following boxes, will adjust the budgets <b>for only the current period</b> so that the amount remaining is equal to the number entered in the box.</>}
					>
						<QuestionCircle />
					</Tooltip>
				</Grid>
				<Grid item xs={12} className={`centered ${overLimit ? 'text-danger' : 'text-app'}`}>
					<div className='centered mt-2'>
						{overLimit ? <>
							{USCurrency(sumLimited - limitLimited)} over total budget
						</> : <>
							{USCurrency(limitLimited - sumLimited)} total budget remaining
						</>}
					</div>
				</Grid>
				<Grid item xs={2}>
				</Grid>
				<Grid item xs={8} className={`centered pb-3`}>
					{newTarget ?
						<AmountWithTooltip
							Size={12}
							Class="mb-0"
							Tooltip={DisplayBudgetedAmounts(true)}
							TooltipClass={`tooltip-${ttColor}`}
							ArrowClass={`arrow-${ttColor}`}
							AllowZero
							Value={RoundToCent(target)}
							OnChange={(val: any) => UpdateAmount(val.target.value, -1)} />
						:
						<Button fullWidth variant="contained" onClick={() => setNewTarget(true)} className="bg-success text-white">Set New Target Budget</Button>
					}
				</Grid>
				<Grid item xs={2}>
				</Grid>
				{canLoad && (
					<Grid container className="px-3">
						{
							newBudgets.slice().sort((a, b) => a.description.localeCompare(b.description)).map(budget => {
								var index = budget.budgetId;
								return (<>
									<Grid item xs={7} className={`right pb-2`}>
										{budget.description}<br />
										{USCurrency(budget.amountSpent + budget.amountRemaining)}
									</Grid>
									<Grid item xs={5}>
										<AmountWithTooltip
											Label="Remaining"
											Size={12}
											Class="mb-0"
											Tooltip={DisplayBudgetedAmounts(true)}
											TooltipClass={`tooltip-${ttColor}`}
											ArrowClass={`arrow-${ttColor}`}
											AllowZero
											Value={budget.amountRemaining}
											OnChange={(val: any) => UpdateAmount(val.target.value, index)} />
									</Grid>
								</>);
							})
						}
					</Grid>
				)}
				{DisplayBudgetedAmounts()}
			</Grid>
			<Grid container>
				<Grid item xs={3}>
					<Button fullWidth variant="contained" onClick={SaveBudgets} disabled={!showSave} className={`${showSave ? 'bg-success' : 'bg-disabled'} text-white btn-list`}>Save</Button>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={8}>
					<Button fullWidth variant="outlined" onClick={() => navigate("/budgets")} className="border-success text-success btn-list">Cancel</Button>
				</Grid>
			</Grid>
		</>
	);

	function ActualTotal() {
		return RoundToCent(newBudgets.reduce((tot, t) => tot + t.amountRemaining, 0));
	}

	function SaveBudgets() {
		if (!user.isHoH) return;
		newBudgets.forEach(budget => {
			let variant = { ...budget.variant };
			var newAmount = RoundToCent(budget.amountRemaining + budget.amountSpent);
			if (RoundToCent(variant.amount) !== newAmount) {
				variant.amount = newAmount
				repo.UpdateBudgetAsync(budget, variant, false, false);
				repo.GetBudgetsAsync();
			}
		});
		navigate("/budgets");
	}

	function UpdateAmount(newValue: string, index: number) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
			if (isNaN(val)) {
				return;
			}
		}
		if (index === -1) {
			setTarget(RoundToCent(val));
		}
		if (index > -1) {
			var items = [] as BudgetItem[];
			for (let i in newBudgets) {
				let amount = newBudgets[i].amountRemaining;
				if (newBudgets[i].budgetId === index) {
					amount = val;
				}
				items.push({ ...newBudgets[i], amountRemaining: amount });
			}
			setNewBudgets(items);
		}
	}

	function DisplayBudgetedAmounts(isTooltip: boolean = false) {
		let actualTotal = ActualTotal();
		if (actualTotal === target) {
			let text = `All ${USCurrency(target ?? 0)} Budgeted!`;
			if (isTooltip) {
				ttColor = 'success';
				return text;
			}
			if (!showSave) {
				setShowSave(true)
			}
			return (<>
				<Grid item className="text-success mx-3">
					{text}
				</Grid>
				<Divider />
			</>);
		} else if ((actualTotal ?? 0) > (target ?? 0)) {
			let text = `Budgeted ${USCurrency((actualTotal ?? 0) - (target ?? 0))} over the target budget`;
			if (isTooltip) {
				ttColor = 'error';
				return text;
			}
			if (showSave) {
				setShowSave(false);
			}
			return (<>
				<Grid item className="text-danger mx-3">
					{text}
				</Grid>
				<Divider />
			</>);
		} else {
			let text = `${USCurrency((target ?? 0) - (actualTotal ?? 0))} remaining to reach the target budget`;
			if (isTooltip) {
				ttColor = 'info';
				return text;
			}
			if (showSave) {
				setShowSave(false);
			}
			return (<>
				<Grid item className="text-app mb-3 mx-3" xs={12}>
					{text}
				</Grid>
				<Divider />
			</>);
		}
	}
}

export default BalanceBudgets;