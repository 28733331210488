import { useState } from "react";
import { BudgetItem, CurrentUser, FamilyFeatureValue, FamilyHasAccess } from "../../types";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import Toggle from "../Inputs/Toggle";
import { Features } from "../../constants";

const EditBudgetOther = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	return (<>
		<ModalHeader Title="Other Settings" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Toggle Size={12} Label="Require approval from unassigned users" OnChange={UpdateRequiresApproval} Checked={budget.requiresApproval} className="my-3" />
				<Toggle Size={12} Label={`Exclude items in this budget from ${FamilyFeatureValue(user, Features.TransactionTitle)} page`} OnChange={UpdateIsViewTransactions} Checked={!budget.isViewTransactions} className="my-3" />
				{FamilyHasAccess(user, Features.Income) &&
					<Toggle Size={12} Label="Use Budget for Income Only" OnChange={UpdateIsIncome} Checked={budget.isIncome} className="my-3" />
				}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateRequiresApproval(event: any, newValue: boolean) {
		let item = ({ ...budget, requiresApproval: newValue });
		setBudget(item);
	}
	function UpdateIsViewTransactions(event: any, newValue: boolean) {
		let item = ({ ...budget, isViewTransactions: !newValue });
		setBudget(item);
	}
	function UpdateIsIncome(event: any, newValue: boolean) {
		let item = ({ ...budget, isIncome: newValue });
		setBudget(item);
	}
}

export default EditBudgetOther;