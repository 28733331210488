import { useState } from "react";
import Amount from "../Inputs/Amount";
import { BudgetItem } from "../../types";
import { Grid } from "@mui/material";
import Toggle from "../Inputs/Toggle";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditBudgetPeriod = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);

	return (<>
		<ModalHeader Title="Edit Budget Period" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Amount Size={12} Value={budget.variant.amount} OnChange={(val: any) => UpdateAmount(val.target.value)} />
				<Toggle Size={12} Label="Apply updated amount to future budgets" OnChange={UpdateIsDefault} Checked={budget.variant.isDefault ?? false} className="mb-5" />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateAmount(newValue: string) {
		let val: number = 0;
		if (newValue) val = parseFloat(newValue);
		let variant = { ...budget.variant, amount: val };
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
	function UpdateIsDefault(event: any, newValue: boolean) {
		let variant = { ...budget.variant, isDefault: newValue };
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
}

export default EditBudgetPeriod;