import { useState } from "react";
import Dropdown from "../Inputs/Dropdown";
import { BudgetItem, SelectableItem, UserItem } from "../../types";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditBudgetUsers = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	return (<>
		<ModalHeader Title="Users" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Dropdown Size={12}
					Class="mb-0"
					Label="Assigned Users"
					Options={users
						.map(u => {
							return {
								Id: u.userAccountId,
								Value: `${u.firstName} ${u.lastName}`
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.userIds}
					Multiple
					OnChange={UpdateUsers} />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateUsers(newValue: any) {
		let item = ({ ...budget, userIds: newValue });
		setBudget(item);
	}
}

export default EditBudgetUsers;