import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material";
import dayjs from "dayjs";

//Custom Variables
export class DateTime {
    constructor(value?: any) {
		if (value) {
			if (value.toString().length === 10 && value.toString().includes('-')) {
				let dateParts = value.split('-');
				this.Value = new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2], 0, 0, 0, 0);
			} else {
				this.Value = new Date(value);
			}
        } else {
            this.Value = new Date();
		}
    }
    Value: Date;
    toString() {
        return this.Value.toLocaleDateString();
    }
	toQueryString() {
		return dayjs(this.Value).format('YYYY-MM-DD');
	}
    toJSON() {
		return [
			this.Value.getFullYear(),
			(this.Value.getMonth() + 1).toString().padStart(2, '0'),
			this.Value.getDate().toString().padStart(2, '0'),
		].join('-');
    }
    valueOf() {
        return this.Value.getTime();
    }
    addDays(value: number) {
        return new DateTime(dayjs(this.Value).add(value, 'day').toDate());
    }
    addMonths(value: number) {
        return new DateTime(dayjs(this.Value).add(value, 'month').toDate());
    }
    diff(item: DateTime) {
        return dayjs(this.Value).diff(dayjs(item.Value));
    }
    dayDiff(item: DateTime) {
        return dayjs(this.Value).diff(dayjs(item.Value), 'day');
    }
    monthDiff(item: DateTime) {
        return dayjs(this.Value).diff(dayjs(item.Value), 'month');
	}
	startOfDay() {
		return new DateTime(dayjs(this.Value).startOf('day').toDate());
	}
}

export interface SelectableItem {
	Id: number,
	Value: string
}

export interface LocalData {
	ShowNeedsEdit: boolean,
	ShowHasTaxes: boolean,
	ShowRepeating: boolean,
	ShowNeedsReimbursement: boolean
}

export interface KeyValuePair {
	Key: any,
	Value: any
}

export interface Grouping {
	Key: any,
	Value: any[]
}

//Account
export interface AccountItem {
	accountId: number,
	accountTypeId: number,
	description: string,
	amount: number,
	isDefault: boolean,
	autoAdjust: boolean,
	financialInstitution?: string,
	mask?: string,
	differentiator?: number,
	userAccountId: number
}

//Budget
export interface BudgetItem {
    budgetId: number,
    timeframeId: number,
	description: string,
	color: string,
	categoryIds: IdStartEnd[],
	userIds: number[],
	isIncome: boolean,
	isMine: boolean,
	isViewTransactions: boolean,
	isInactive: boolean,
	requiresApproval: boolean,
	variant: BudgetVariant,
	amountSpent: number,
	amountRemaining: number,
	endDate?: DateTime,
	activeTimeframes: IdStartEnd[],
	unapprovedTransactions?: TransactionItem[],
	categories: CategoryItem[],
	categoryIdsExtended: CategoryIdExtended[]
}
export interface BudgetVariant {
	budgetVariantId: number,
	budgetId: number,
	amount: number,
	startDate: DateTime,
	endDate: DateTime | null,
	defaultEndDate: DateTime,
	isDefault: boolean,
	isInactive: boolean,
	timeframeId?: number,
	timeframe?: TimeframeItem,
	isFinal?: boolean
}

export interface CategoryIdExtended {
	item: IdStartEnd,
	selected: boolean,
	active: boolean,
	isNew?: boolean,
	isUpdated?: boolean,
	transactions: TransactionItem[],
	checkRequests: TransactionItem[]
}

export interface IdStartEnd {
	id: number,
	startDate?: DateTime | null,
	endDate?: DateTime | null,
	timeframe?: TimeframeItem,
	category?: CategoryItem
}

//Category
export interface CategoryItem {
    categoryId: number,
	description: string,
	parentCategoryId?: number | null,
	isExpanded?: boolean,
	isViewTransactions: boolean,
	childCategories?: CategoryItem[]
}

//Current User
export interface CurrentUser {
	email?: string,
	error?: string,
	isActive?: boolean,
	isAuthenticated?: boolean,
	isHoH?: boolean,
	mileageRate: number,
	familyName: string,
	token?: string,
	unassignedBudgets: boolean,
	includeAllUsersInBudgets: boolean,
	emails?: UserEmailItem[],
	features?: FamilyTypeFeature[]
}
export interface FamilyTypeFeature {
	featureId: number,
	value: string
}
export interface UserEmailItem {
	userEmailId: number,
	email: string,
	isVerified: boolean,
	isDuplicate: boolean
}

//Familiy
export interface Family {
	familyId: number,
	familyName: string,
	familyTypeId: number
}

//Family Code
export interface FamilyCode {
	familyCodeId: number,
	code: string,
	isAdmin: boolean
}

//Quick Receipt
export interface ReceiptEmailItem {
	receiptEmailId: number,
	userEmailId: number,
	familyId?: number | null,
	emailSubject: string,
	hasAttachment: boolean,
	receipts?: string[] | undefined,
	receiptEmailIds?: number[]
}

//Recurring
export interface RecurringItem {
	recurringTransactionId: number,
	accountId: number,
	budgetId: number,
	categoryId: number,
	days: number,
	months: number,
	description: string,
	nextAmount: number,
	isVariable: boolean,
	minAmount: number,
	maxAmount: number,
	nextDate: DateTime,
	isIncome: boolean,
	previousAmount?: number,
	previousTransaction?: TransactionItem,
	categoryName?: string
}

//Reports
export interface BudgetTransaction {
	Budget: BudgetItem,
	Category: CategoryItem | undefined,
    User?: UserItem,
	Transaction: TransactionItem,
	Amount: BudgetCategoryAmount
}
export interface CustomReportSettings {
	title: string,
	grouping: string,
	columns: string[],
	filter: CustomReportFilter
}
export interface CustomReportFilter {
	startDate?: DateTime,
	endDate?: DateTime,
	createdById?: number[],
	nameIncludes?: string,
	merchantIncludes?: string,
	descriptionIncludes?: string,
	budgetIds?: number[],
	categoryIds?: number[],
	minAmount?: number,
	maxAmount?: number,
	includeSpending: boolean,
	includeMiles: boolean,
	isSeparateReportForMiles: boolean,
	includeCheckRequests: boolean,
	combineSplitBudgets: boolean,
	ignoreCombinedIfAnyNotIncluded: boolean,
	includeReceipts: boolean,
	groupReceipts: boolean
}

//Timeframe
export interface TimeframeItem {
    timeframeId: number,
    days: number,
    months: number,
    description: string,
	startDate: DateTime,
	endDate1?: DateTime | null,
	endDate2?: DateTime | null
}

//Transaction
export interface TransactionItem {
    accountId: number,
    accountTransactionId: number,
	budgetCategoryAmounts: BudgetCategoryAmount[],
	checkRequest?: TransactionCheckRequestItem,
	color: string,
	completedReimbursement: boolean,
	created: string,
	editedReceipt?: boolean,
	hasReceipt: boolean,
    isAdminReview?: boolean | null,
	isCheckRequest: boolean,
	isExternal?: boolean,
	isIncome: boolean,
	isMiles: boolean,
	isMine: boolean,
	lastUpdated: string,
    merchant: string,
	miles?: number | null,
	needsMyApproval: boolean,
	needsReimbursement: boolean,
	rate?: number | null,
	receipts: string[],
	recurringTransaction?: RecurringItem,
    recurringTransactionId?: number | null,
    transactionDate: DateTime,

	receiptEmailIds?: number[],
	isRecurring?: boolean,
	importedTransactionId?: number
}
export interface TransactionImage {
	accountTransactionId: number,
	image: HTMLImageElement,
	width: number,
	height: number
}
export interface BudgetCategoryAmount {
	amount: number,
	approvalNote: string,
	budgetId: number,
	categoryId: number,
	categoryName?: string,
	details?: string,
	isApproved: boolean,
	isRejected: boolean,
	reviewerId: number | null
}
export interface TransactionCheckRequestItem {
	accountTransactionCheckRequestId: number,
	amount: number,
	reviewerId: number,
	dateNeeded: DateTime,
	needsMailed: boolean,
	mailingAddress?: string,
	city?: string,
	stateName?: string,
	zipCode?: string,
	approvalState: number,
	approvalNote?: string,
	needsMyApproval: boolean,
	isMine: boolean,
	created: string,
	reviewer?: string,
	createdDate: DateTime
}

//Transaction Filter
export interface AccountTransactionFilterModel {
	AccountId: number | undefined,
	IsRecurring: boolean,
	IsNotRecurring: boolean,
	NeedsReceipt: boolean,
	NeedsReimbursement: boolean,
	IsAdminReview: boolean,
	IsMine: boolean,
	IsRejected: boolean,
	IsIncome: boolean,
	IsExpense: boolean,
	Merchant: string,
	User: string,
	StartDate: Date | undefined,
	EndDate: Date | undefined,
	IncludeAccountIds: number[],
	IncludeBudgetIds: number[],
	ExcludeBudgetIds: number[],
	IncludeCategoryIds: number[],
	ExcludeCategoryIds: number[],
	MinAmount: number | undefined,
	MaxAmount: number | undefined
}

//User
export interface UserItem {
	userAccountId: number,
	familyId: number,
	firstName: string,
	lastName: string,
	isAdmin: boolean,
	canApproveChecks: boolean,
	isActive: boolean,
	isMe: boolean,
	lastLogin?: DateTime,
	createDate?: DateTime,
	families?: Family[],
	budgetIds?: number[]
}

//Functions
export const FamilyHasAccess = (user: CurrentUser, feature: number) => {
	return (user.features?.filter(f => f.featureId === feature).length ?? 0) > 0;
}

export const FamilyFeatureValue = (user: CurrentUser, feature: number) => {
	return user.features?.find(f => f.featureId === feature)?.value;
}

export const GroupBy: (list: any[], key: string) => Grouping[] = (list: any[], key: string) => {
	return list.reduce((result: Grouping[], item: any) => {
		let res = result.find(r => r.Key === item[key]);
		if (res) {
			res.Value.push(item);
		}
		else {
			res = {
				Key: item[key],
				Value: [item]
			};
			result.push(res);
		}
		return result;
	}, []);
}

export const USCurrency: (value: number) => string = (value: number) =>
	value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD'
	});

export const RoundToCent: (value: number) => number = (value: number) =>
	Math.round(value * 100) / 100;

export const BudgetTransactionTotal: (group: BudgetTransaction[]) => number = (group: BudgetTransaction[]) =>
	group.reduce((total: number, b: BudgetTransaction) => b.Amount.amount + total, 0);

export const GroupTransactionTotal: (items: Grouping[]) => number = (items: Grouping[]) =>
	items.reduce(
		(total: number, g: Grouping) =>
			BudgetTransactionTotal(g.Value as BudgetTransaction[]) + total, 0);

export const DictToSelectable = (item: { [index: number]: string }) => {
	let keys = Object.keys(item);
	let result: SelectableItem[] = [];
	for (let id of keys) {
		result.push({ Id: parseInt(id), Value: item[parseInt(id)] })
	}
	return result;
}

export const TimeframeDays: (timeframe: TimeframeItem | undefined) => number = (timeframe: TimeframeItem | undefined) => {
	return ((timeframe?.days ?? 0) + ((timeframe?.months ?? 0) * 30));
}

//UI Types
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	[`&:nth-of-type(odd)`]: {
		backgroundColor: theme.palette.action.hover
	},
	[`&:last-child td, &last-child th`]: {
		border: 0
	}
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.grey[400],
		color: theme.palette.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	},
	[`&.${tableCellClasses.footer}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
}));

export const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
})

//Constants
export const ColorHexes: string[] =
[
	"#78d3b1", "#00b1d3", "#d361d3", "#d30061", "#d3d300",
	"#78d300", "#00619b", "#7800b1", "#d31515", "#d36100",
	"#15d315", "#00009b", "#61009b", "#780000", "#9b6100"
];

export const TimeframeDescription = (timeframe: TimeframeItem) => {
	let additional = "";
	let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	if (timeframe.days > 0) {
		if (timeframe.days % 7 === 0) {
			additional = ` on ${weekdays[timeframe.startDate.Value.getDay()]}`;
		} else {
			additional = ` starting ${timeframe.startDate.toString()}`;
		}
		return `Every ${timeframe.days} Days${additional}`;
	} else if (timeframe.months > 1) {
		return `Every ${timeframe.months} Months on the ${GetDateString(timeframe.startDate.Value.getDate())}`;
	} else if (timeframe.months > 0) {
		return `Every Month on the ${GetDateString(timeframe.startDate.Value.getDate())}`;
	} else if (!timeframe.endDate1) {
		return timeframe.description;
	} else {
		let first = timeframe.endDate1!.Value.getDate();
		let second = timeframe.endDate2!.Value.getDate();
		let firstString = GetDateString(first);
		let secondString = GetDateString(second);
		if (second > 28) {
			secondString = "the Last Day";
		}
		return `On the ${firstString} and ${secondString} of every month`;
	}
}

function GetDateString(date: number) {
	switch (date) {
		case 1:
		case 21:
		case 31:
			return `${date}st`;
		case 2:
		case 22:
			return `${date}nd`;
		case 3:
		case 23:
			return `${date}rd`;
		default:
			return `${date}th`;
	}
}
