/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Budget from "../components/ListItems/Budget";
import EditBudget from "../components/Modals/EditBudget";
import { BudgetCategoryAmount, BudgetItem, CategoryIdExtended, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, TimeframeItem, TransactionItem, UserItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid, IconButton } from "@mui/material";
import { BoxArrowUpRight, FileEarmarkSpreadsheetFill, PlusLg } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import Date from "../components/Inputs/Date";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import Unbudget from "../components/ListItems/Unbudget";

const FilteredBudgets = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();
	let dispatch = useDispatch();

	let [currentDate, setCurrentDate] = useState(new DateTime());

	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let filteredBudgets = budgets.filter(b => !b.isInactive);
	let inactiveBudgets = budgets.filter(b => b.isInactive);

	let canLoad = transactions && timeframes && categories && budgets && user;

	let earliest = new DateTime();
	let sumUnbudgeted = 0;

	let title = '';
	switch (location.pathname.toLowerCase()) {
		case "/budgets/income":
			filteredBudgets = filteredBudgets.filter(b => b.isIncome);
			title = 'Income';
			break;
		case "/budgets/limited":
			filteredBudgets = filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0);
			title = 'Limited';
			earliest = new DateTime([...filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0)].map(b => b.variant.startDate.Value).reduce((minDate: Date, currentDate: Date) => currentDate < minDate ? currentDate : minDate, new DateTime().Value));
			sumUnbudgeted = ([] as BudgetCategoryAmount[]).concat.apply([], transactions?.filter(t => t.transactionDate.startOfDay().Value >= earliest.Value).map(t => t.budgetCategoryAmounts?.filter(a => a.budgetId < 1))).reduce((total, t) => total + t.amount, 0);
			break;
		case "/budgets/unlimited":
			filteredBudgets = filteredBudgets.filter(b => !b.isIncome && b.variant.amount === 0);
			title = 'Unlmited';
			break;
		case "/budgets/all":
			title = 'All';
			break;
		case "/budgets/inactive":
			title = 'Inactive';
			filteredBudgets = inactiveBudgets;
			break;
		default:
			navigate('/budgets');
	}

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}
	if (!FamilyHasAccess(user, Features.Income) && title !== 'All') {
		navigate("/budgets/all");
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<h2 className="mt-2 text-app">{title} Budgets</h2>
				</Grid>
				<Grid item xs={3}>
					{!canLoad && (< p ><em>Loading...</em></p>)}
					{canLoad && user.isHoH && (
						<>
							<IconButton
								className="text-app right"
								aria-label='new expense'
								onClick={OpenNewModal}>
								<PlusLg />
							</IconButton>
						</>
					)}
				</Grid>
				{canLoad && (
					<div className="border border-app mb-3 start-hint budget-container">
						<div className="budget-list">
							{
								filteredBudgets.filter(b => user.isHoH || b.isMine).slice().sort((a, b) => a.description.localeCompare(b.description)).map(budget => {
									return (
										<Budget
											Item={budget}
											Categories={categories}
											OnClick={() => navigate(`/budget/${budget.budgetId}/${currentDate.toQueryString()}`)}
											key={`budget${budget.budgetId}`} />
									);
								})
							}
							{sumUnbudgeted > 0 &&
								<Unbudget
									Since={earliest}
									Total={sumUnbudgeted}
									OnClick={() => navigate(`/unbudget`)}
									key={`budget0`} />
							}
						</div>
                        <h2 className="btn-list btn-total btn-report">
							<Grid container onClick={() => navigate("/reports")}>
								<Grid item xs={2} className="centered">
									<FileEarmarkSpreadsheetFill />
								</Grid>
								<Grid item xs={10}>
									View Reports
								</Grid>
							</Grid>
						</h2>
					</div>
				)}
				{budgets.length > 0 ? (
					<>
						<Grid item xs={6} className="centered">
							<Date Borderless Size={12} Label="As of..." Value={currentDate} OnChange={UpdateDate} />
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={3} className="centered align-self-center">
						</Grid>
						<Grid item xs={6} className="centered">
							<Date Size={12} Label="As of..." Value={currentDate} OnChange={UpdateDate} />
						</Grid>
						<Grid item xs={3} className="centered align-self-center">
						</Grid>
						<Grid item xs={3}></Grid>
						<Grid item xs={6} className="centered">
							{user.isHoH ? "Select the Plus(+) button above to add your first budget" : "You currently have no budgets assigned to you"}
						</Grid>
						<Grid item xs={3}></Grid>
					</>
				)}
			</Grid>
			{inactiveBudgets.length > 0 && filteredBudgets !== inactiveBudgets &&
				<Grid container className="border border-app my-1 budget-summary-container bg-white cursor-pointer" style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: -1, paddingRight: -1 }} onClick={() => navigate('/budgets/inactive')}>
					<Grid item xs={12} className='centered py-3 text-app'>
						<h3 className='mb-0 align-content-center'>View Inactive Budgets<BoxArrowUpRight style={{ marginLeft: '1rem' }} /></h3>
					</Grid>
				</Grid>
			}
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function SaveBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		let variant = { ...item.variant };
		repo.UpdateBudgetAsync(item, variant, item.budgetId === 0, false);
		HideModal();
		repo.GetBudgetsWithDateAsync(currentDate);
	}

	function OpenNewModal() {
		let budget: BudgetItem = {
			budgetId: 0,
			description: "",
			color: "#00619b",
			activeTimeframes: [],
			categoryIds: [{ id: categories[0].categoryId }],
			categoryIdsExtended: [] as CategoryIdExtended[],
			timeframeId: timeframes[0].timeframeId,
			variant:
			{
				budgetVariantId: 0,
				budgetId: 0,
				amount: 0,
				startDate: new DateTime(),
				endDate: null,
				defaultEndDate: new DateTime(),
				isDefault: true,
				isInactive: false
			},
			userIds: user.includeAllUsersInBudgets ? users.map(u => u.userAccountId) : [],
			isIncome: false,
			isMine: false,
			isViewTransactions: true,
			isInactive: false,
			requiresApproval: true,
			categories: [],
			amountSpent: 0,
			amountRemaining: 0
		} as BudgetItem;
		modal = {
			...modal,
			Body: <EditBudget Item={budget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function UpdateDate(newValue: any) {
		newValue = new DateTime(newValue);
		repo.GetBudgetsWithDateAsync(newValue);
		setCurrentDate(newValue);
	}
}

export default FilteredBudgets;