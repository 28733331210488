/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditBudget from "../components/Modals/EditBudget";
import { BudgetCategoryAmount, BudgetItem, CategoryIdExtended, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, IdStartEnd, TimeframeItem, TransactionItem, USCurrency, UserItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Button, Grid, IconButton } from "@mui/material";
import { BoxArrowUpRight, PlusLg } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import Date from "../components/Inputs/Date";

const Budgets = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();
	let dispatch = useDispatch();

	let [currentDate, setCurrentDate] = useState(new DateTime());

	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let canLoad = transactions && timeframes && categories && budgets && user;
	let earliest = new DateTime([...budgets.filter(b => !b.isIncome && b.variant.amount > 0)].map(b => b.variant.startDate.Value).reduce((minDate, currentDate) => currentDate < minDate ? currentDate : minDate, new DateTime().Value));
	let sumUnbudgeted = ([] as BudgetCategoryAmount[]).concat.apply([], transactions?.filter(t => t.transactionDate.startOfDay().Value >= earliest.Value).map(t => t.budgetCategoryAmounts?.filter(a => a.budgetId < 1))).reduce((total, t) => total + t.amount, 0);
	let sumLimited = budgets.filter(b => !b.isIncome && b.variant.amount > 0).reduce((total, t) => total + t.amountSpent, 0);
	let limitLimited = budgets.filter(b => !b.isIncome && b.variant.amount > 0).reduce((total, t) => total + t.variant.amount, 0);
	let overLimit = sumLimited > limitLimited;
	let overLimitItems = budgets.filter(b => !b.isIncome && b.variant.amount > 0 && b.amountRemaining < 0);

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}
	if (!FamilyHasAccess(user, Features.Income)) {
		navigate("/budgets/all");
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<h2 className="mt-2 text-app">Budgets</h2>
				</Grid>
				<Grid item xs={3}>
					{!canLoad && (< p ><em>Loading...</em></p>)}
					{canLoad && user.isHoH && (
						<>
							<IconButton
								className="text-app right"
								aria-label='new expense'
								onClick={OpenNewModal}>
								<PlusLg />
							</IconButton>
						</>
					)}
				</Grid>
				{canLoad && (<>
					<Grid container className="border border-app my-3 mx-3 budget-summary-container bg-white" style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: -1, paddingRight: -1 }}>
						<Grid item xs={2} className='centered text-app'>
						</Grid>
						<Grid item xs={8} className='my-3'>
							<Grid item xs={12} className='centered text-app'>
								<b>Limited Budgets</b>
							</Grid>
							<Grid item xs={12} className={`centered pb-2 ${overLimit ? 'text-danger' : 'text-app'}`} onClick={() => navigate('/budgets/limited')}>
								{USCurrency(sumLimited)} of {USCurrency(limitLimited)} spent
								<div className='centered mt-2'>
									{overLimit ? <>
										{USCurrency(sumLimited - limitLimited)} over
									</> : <>
										{USCurrency(limitLimited - sumLimited)} remaining
									</>}
								</div>
								<div className='centered text-danger small mt-2'>
									{overLimitItems.map(i => `${i.description}: ${USCurrency(i.amountRemaining)} | `)}
								</div>
								{sumUnbudgeted > 0 &&
									<div className='centered text-secondary small mt-2'>
										Unbudgeted since {earliest.toString()} - {USCurrency(sumUnbudgeted)}
									</div>
								}
							</Grid>
							{user.isHoH &&
								<Grid item xs={12} className={`centered pb-2`}>
									<Button fullWidth variant="contained" onClick={() => navigate("/balancebudgets")} className="bg-success text-white">Balance Budgets</Button>
								</Grid>
							}
						</Grid>
						<Grid item xs={2} className='centered text-app cursor-pointer align-self-center' onClick={() => navigate('/budgets/limited')}>
							<div className='h-100 w-100 centered align-content-center'>
								<h3><BoxArrowUpRight /></h3>
							</div>
						</Grid>
					</Grid>
					<Grid container className="border border-app my-1 mx-3 budget-summary-container bg-white cursor-pointer" style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: -1, paddingRight: -1 }} onClick={() => navigate('/budgets/all')}>
						<Grid item xs={12} className='centered py-3 text-app'>
							<h3 className='mb-0 align-content-center'>View All Budgets<BoxArrowUpRight style={{ marginLeft: '1rem' }} /></h3>
						</Grid>
					</Grid>
					<Grid item xs={3} className="centered align-self-center">
					</Grid>
					<Grid item xs={6} className="centered">
						<Date Borderless Size={12} Label="As of..." Value={currentDate} OnChange={UpdateDate} />
					</Grid>
					<Grid item xs={3} className="centered align-self-center">
					</Grid>
				</>)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function SaveBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		let variant = { ...item.variant };
		repo.UpdateBudgetAsync(item, variant, item.budgetId === 0, false);
		HideModal();
		UpdateDate(currentDate);
	}

	function OpenNewModal() {
		let budget: BudgetItem = {
			budgetId: 0,
			description: "",
			color: "#00619b",
			activeTimeframes: [],
			categoryIds: [] as IdStartEnd[],
			categoryIdsExtended: [] as CategoryIdExtended[],
			timeframeId: timeframes[0].timeframeId,
			variant:
			{
				budgetVariantId: 0,
				budgetId: 0,
				amount: 0,
				startDate: new DateTime(),
				endDate: null,
				defaultEndDate: new DateTime(),
				isDefault: true,
				isInactive: false
			},
			userIds: user.includeAllUsersInBudgets ? users.map(u => u.userAccountId) : [],
			isIncome: false,
			isMine: false,
			isViewTransactions: true,
			isInactive: false,
			requiresApproval: true,
			categories: [],
			amountSpent: 0,
			amountRemaining: 0
		} as BudgetItem;
		modal = {
			...modal,
			Body: <EditBudget Item={budget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function UpdateDate(newValue: any) {
		newValue = new DateTime(newValue);
		repo.GetBudgetsWithDateAsync(newValue);
		setCurrentDate(newValue);
	}
}

export default Budgets;