import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { DateTime, IdStartEnd } from "../../types";
import Date from "../Inputs/Date";

const EditTimePeriodStart = (props: any) => {
	let [period, setPeriod] = useState(props.Item as IdStartEnd);

	return (
		<Dialog open={true} onClose={props.Close}>
			<DialogContent className="px-5 mt-3">
				<Date Size={6} Label="Start Date" Value={props.Item.startDate} OnChange={UpdateStartDate} NoError />
				{`The new date cannot overlap other active time periods.`}
			</DialogContent>
			<DialogActions className="px-3">
				<Button className="mx-3 my-4" onClick={props.Close} variant="outlined">Cancel</Button>
				<Button onClick={() => props.Confirm(period, props.Item)} variant="contained" className="bg-success mx-3">Confirm</Button>
			</DialogActions>
		</Dialog>
	);

	function UpdateStartDate(newValue: any) {
		let item = ({ ...period, startDate: new DateTime(newValue) });
		setPeriod(item);
	}
}

export default EditTimePeriodStart;