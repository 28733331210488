import { useState } from "react";
import { BudgetItem, CurrentUser, DateTime, IdStartEnd, TimeframeDescription, TimeframeItem } from "../../types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton } from "@mui/material";
import { ExclamationCircle, Trash3Fill } from "react-bootstrap-icons";
import ModalHeader from "./ModalHeader";
import Divider from "../Inputs/Divider";
import { useItems } from "../../features/Fetch/Items";
import AddTimePeriod from "./AddTimePeriod";
import { useSelector } from "react-redux";
import EditTimePeriodStart from "./EditTimeperiodStart";
import EditTimePeriodEnd from "./EditTimeperiodEnd";
import EditTimePeriodTimeframe from "./EditTimeperiodTimeframe";

const EditBudgetTimeframe = (props: any) => {
	let budget: BudgetItem = useSelector((state: any) => state.budgets.value).find((b: BudgetItem) => b.budgetId === props.Item.budgetId);
	let timeframes: TimeframeItem[] = props.Timeframes;
	let user: CurrentUser = props.User;
	let currentDate: DateTime = props.CurrentDate;
	let repo = useItems();

	let [showAdd, setShowAdd] = useState(false);
	let [showDelete, setShowDelete] = useState(false);
	let [showStart, setShowStart] = useState(-1);
	let [showEnd, setShowEnd] = useState(-1);
	let [showTimeframe, setShowTimeframe] = useState(-1);
	let [showDeletePeriod, setShowDeletePeriod] = useState(-1);

	return (<>
		<ModalHeader Title="Change Timeframe" Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				<Grid item xs={12} className="mx-2">
					This budget is active during the following time periods:
				</Grid>
				<Divider />
				{budget.activeTimeframes.map(atf => {
					var rowNum = budget.activeTimeframes.indexOf(atf);
					return (<>
						<Grid item xs={12} className="mx-2 mb-3">
							{`From ${atf.startDate}${atf.endDate == null ? "" : ` to ${atf.endDate}`}: Budget Resets ${TimeframeDescription(timeframes.find(t => t.timeframeId === atf.id)!)}`}
						</Grid>
						<Grid item xs={3} className="mx-2 mb-3">
							<Button fullWidth variant="contained" onClick={() => setShowStart(rowNum)} className="bg-app text-white">Change Start Date</Button>
						</Grid>
						<Grid item xs={3} className="mx-2 mb-3">
							<Button fullWidth variant="contained" onClick={() => setShowEnd(rowNum)} className="bg-app text-white">{`${atf.endDate == null ? "Set End Date" : "Change End Date"}`}</Button>
						</Grid>
						<Grid item xs={3} className="mx-2 mb-3">
							<Button fullWidth variant="contained" onClick={() => setShowTimeframe(rowNum)} className="bg-app text-white">Change Reset Day</Button>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								className="cursor-pointer text-danger"
								aria-label='delete'
								onClick={() => setShowDeletePeriod(rowNum)}>
								<Trash3Fill />
							</IconButton>
						</Grid>
						<Divider />
					</>);
				})}
				<Grid item xs={5} className="mx-2 mb-3">
					<Button fullWidth variant="contained" onClick={() => setShowAdd(true)} className="bg-app text-white">Add New Active Times</Button>
				</Grid>
				<Grid item xs={5} className="mx-2 mb-3">
					<Button fullWidth variant="contained" onClick={() => setShowDelete(true)} className="bg-app text-white">Delete This Budget</Button>
				</Grid>
				<Grid item className="small" xs={2}>
				</Grid>
				<Grid item className="small mb-5" xs={8}>
					<ExclamationCircle className="mx-2" />{`Changes made on this screen, such as removing active times or changing the reset day, may result in the permanent loss of some or all previoius changes made to individual budgetting periods.`}
				</Grid>
			</Grid>
			{showAdd &&
				<AddTimePeriod Close={() => setShowAdd(false)} Confirm={SaveBudgetTimeframe} />
			}
			{showStart > -1 &&
				<EditTimePeriodStart Close={() => setShowStart(-1)} Item={budget.activeTimeframes[showStart]} Confirm={SaveBudgetTimeframeStartDate} />
			}
			{showEnd > -1 &&
				<EditTimePeriodEnd Close={() => setShowEnd(-1)} Item={budget.activeTimeframes[showEnd]} Confirm={SaveBudgetTimeframeEndDate} />
			}
			{showTimeframe > -1 &&
				<EditTimePeriodTimeframe Close={() => setShowTimeframe(-1)} Item={budget.activeTimeframes[showTimeframe]} Confirm={SaveBudgetTimeframeTimeframe} />
			}
			{showDeletePeriod > -1 &&
				<Dialog open={true} onClose={() => setShowDeletePeriod(-1)}>
					<DialogContent className="px-5 mt-3">
						<DialogContentText>
							Are you sure you want to delete this time period?
						</DialogContentText>
					</DialogContent>
					<DialogActions className="px-3">
						<Button className="mx-3 my-4" onClick={() => setShowDeletePeriod(-1)} variant="outlined">Cancel</Button>
						<Button onClick={() => SaveBudgetTimeframeDelete(showDeletePeriod)} variant="contained" className="bg-danger mx-3">Delete</Button>
					</DialogActions>
				</Dialog>
			}
			{showDelete &&
				<Dialog open={true} onClose={() => setShowDelete(false)}>
					<DialogContent className="px-5 mt-3">
						<DialogContentText>
							Are you sure you want to delete the entire budget?
						</DialogContentText>
					</DialogContent>
					<DialogActions className="px-3">
						<Button className="mx-3 my-4" onClick={() => setShowDelete(false)} variant="outlined">Cancel</Button>
						<Button onClick={() => props.OnDelete(budget)} variant="contained" className="bg-danger mx-3">Delete</Button>
					</DialogActions>
				</Dialog>
			}
		</div>
	</>);

	function HideModal() {
		setShowAdd(false);
		setShowDelete(false);
		setShowStart(-1);
		setShowEnd(-1);
		setShowTimeframe(-1);
		setShowDeletePeriod(-1);
	}

	function SaveBudgetTimeframe(item: IdStartEnd) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetTimeframeAsync({ ...budget, activeTimeframes: [item] })
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetTimeframeStartDate(item: IdStartEnd, original: IdStartEnd) {
		if (!user.isHoH) return;
		HideModal();
		item = { ...item, timeframe: timeframes.find(t => t.timeframeId === item.id) };
		repo.UpdateBudgetTimeframeStartDateAsync({ ...budget, activeTimeframes: [original, item] })
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetTimeframeEndDate(item: IdStartEnd, original: IdStartEnd) {
		if (!user.isHoH) return;
		HideModal();
		item = { ...item, timeframe: timeframes.find(t => t.timeframeId === item.id) };
		repo.UpdateBudgetTimeframeEndDateAsync({ ...budget, activeTimeframes: [original, item] })
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetTimeframeTimeframe(item: IdStartEnd, original: IdStartEnd) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetTimeframeTimeframeAsync({ ...budget, activeTimeframes: [original, item] })
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
	function SaveBudgetTimeframeDelete(item: number) {
		if (!user.isHoH) return;
		HideModal();
		repo.UpdateBudgetTimeframeDeleteAsync({ ...budget, activeTimeframes: [budget.activeTimeframes[item], budget.activeTimeframes[item]] })
			.then(() => repo.GetBudgetsWithDateAsync(currentDate));
	}
}

export default EditBudgetTimeframe;