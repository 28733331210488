/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Transaction from "../components/ListItems/Transaction";
import EditTransaction from "../components/Modals/EditTransaction";
import { AccountItem, AccountTransactionFilterModel, BudgetCategoryAmount, CategoryItem, CurrentUser, DateTime, FamilyFeatureValue, FamilyHasAccess, ReceiptEmailItem, TransactionItem, VisuallyHiddenInput } from "../types";
import Divider from "../components/Inputs/Divider";
import { useItems } from "../features/Fetch/Items";
import { Button, Fab, Grid, IconButton } from "@mui/material";
import { ArrowRepeat, CameraFill, CardText, CarFrontFill, Cash, CheckLg, ChevronUp, ExclamationCircle, EyeFill, PencilSquare, PlusLg, ReceiptCutoff, Search, Tag, Wallet } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useLocation, useNavigate } from "react-router-dom";
import EditFilter from "../components/Modals/EditFilter";
import dayjs from "dayjs";
import { pageLoad } from "../App";
import { useAuth } from "../features/Fetch/Auth";
import { Features } from "../constants";
import ReceiptEmail from "../components/ListItems/ReceiptEmail";
import EditReceiptEmail from "../components/Modals/EditReceiptEmail";
import DropdownMenu from "../components/Inputs/DropdownMenu";
import FileResizer from "react-image-file-resizer";

const Transactions = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetAccountsAsync();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTransactionsAsync();
				repo.GetReceiptEmailsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let accounts: AccountItem[] = useSelector((state: any) => state.accounts.value);
	let budgetTree = repo.GetBudgetTree();
	let categories: CategoryItem[] = repo.SortedCategories();
	let receiptEmails: ReceiptEmailItem[] = useSelector((state: any) => state.receiptEmails.value);
	let isMobile: boolean = useSelector((state: any) => state.isMobile.value);

	let emptyFilter: AccountTransactionFilterModel = {
		AccountId: undefined,
		IsRecurring: false,
		IsNotRecurring: false,
		NeedsReceipt: false,
		NeedsReimbursement: false,
		IsAdminReview: false,
		IsMine: FamilyFeatureValue(user, Features.ShowTransactionsAllUsers) !== 'true',
		IsRejected: false,
		IsIncome: false,
		IsExpense: false,
		Merchant: '',
		User: '',
		StartDate: undefined,
		EndDate: undefined,
		IncludeAccountIds: [],
		IncludeBudgetIds: [],
		ExcludeBudgetIds: budgetTree?.filter(b => !b.isViewTransactions)?.map(b => b.budgetId) ?? [],
		IncludeCategoryIds: [],
		ExcludeCategoryIds: categories?.filter(c => !c.isViewTransactions)?.map(c => c.categoryId) ?? [],
		MinAmount: undefined,
		MaxAmount: undefined
	};
	let tFilter = { ...emptyFilter }
	let navigate = useNavigate();
	let location = useLocation();
	let isNotification = ['/review', '/rejections', '/unbudget'].includes(location.pathname.toLowerCase());
	if (isNotification) {
		switch (location.pathname.toLowerCase()) {
			case "/review":
				if (user.isHoH) {
					tFilter.IsAdminReview = true;
					tFilter.IsMine = false;
					tFilter.ExcludeBudgetIds = [];
					tFilter.ExcludeCategoryIds = [];
				}
				break;
			case "/rejections":
				tFilter.IsRejected = true;
				tFilter.ExcludeBudgetIds = [];
				tFilter.ExcludeCategoryIds = [];
				break;
			case "/unbudget":
				tFilter.IncludeBudgetIds = [0];
				tFilter.ExcludeBudgetIds = [];
				tFilter.ExcludeCategoryIds = [];
				break;
		}
	}
	let [filter, setFilter] = useState(tFilter);
	let [wasNotification, setWasNotification] = useState(isNotification);
	let [showReceiptEmails, setShowReceiptEmails] = useState(false);
	let [budgetCount, setBudgetCount] = useState(budgetTree.length);
	let [catCount, setCatCount] = useState(budgetTree.length);
	let [attachingReceipt, setAttachingReceipt] = useState(undefined as ReceiptEmailItem | undefined);

	if ((filter.IsAdminReview !== tFilter.IsAdminReview || filter.IsRejected !== tFilter.IsRejected || filter.IsMine !== tFilter.IsMine || filter.IncludeBudgetIds.length !== tFilter.IncludeBudgetIds.length) && (isNotification || wasNotification)) {
		setFilter(tFilter);
		setWasNotification(isNotification);
		HideModal();
	}
	if (!isNotification && budgetTree && budgetTree.length > 1 && budgetCount === 1) {
		setBudgetCount(budgetTree.length);
		setFilter({
			...filter,
			ExcludeBudgetIds: tFilter.ExcludeBudgetIds
		});
	}
	if (!isNotification && categories && categories.length > 1 && catCount === 1) {
		setCatCount(categories.length);
		setFilter({
			...filter,
			ExcludeCategoryIds: tFilter.ExcludeCategoryIds
		});
	}

	let transactions = repo.SortedTransactions(filter);
	let localData = repo.GetLocalData();

	let curDate: DateTime = new DateTime();

	let hasExternal = (transactions?.filter(t => t.isExternal)?.length ?? 0) > 0;

	if (!FamilyHasAccess(user, Features.Transactions)) {
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center" className="list-header">
				<Grid item xs={3} className="mt-2">
					{!isNotification &&
						<IconButton
							className="text-app"
							aria-label='filter'
							onClick={OpenFilter}>
							<Search />
						</IconButton>
					}
					<DropdownMenu OpenElement={
						<IconButton
							className="text-app"
							aria-label='new menu'>
							<EyeFill />
						</IconButton>
					}
						Items={AddViewMenuItems() ?? []}
						KeepOpen>
					</DropdownMenu>
				</Grid>
				<Grid item xs={6}>
					<h2 className="mt-2 text-app">{FamilyFeatureValue(user, Features.TransactionTitle)}s</h2>
				</Grid>
				<Grid item xs={2}>
					{(!transactions || !accounts) && (< p ><em>Loading...</em></p>)}
					{transactions && accounts && !isNotification && !attachingReceipt && (
						<DropdownMenu OpenElement={
							<IconButton
								className="text-app"
								aria-label='new menu'>
								<PlusLg />
							</IconButton>
						}
							Items={AddMenuItems() ?? []}>
						</DropdownMenu>
					)}
				</Grid>
			</Grid>
			<Grid container rowSpacing={3} justifyContent="center" className="list-body">
				{transactions != null && (
					<>
						{transactions.length === 0 && !FilterIsDefault() && (
							<Grid container onClick={ClearFilter} className="cursor-pointer">
								<Grid item xs={2}>
									<ExclamationCircle />
								</Grid>
								<Grid item xs={10}>
									No {FamilyFeatureValue(user, Features.TransactionTitle)}s match your current filter. Click here to reset the filter.
								</Grid>
							</Grid>
						)}
						{(transactions.length > 0 || FilterIsDefault()) && (

							<Grid container>
								<>
									{receiptEmails && receiptEmails.length > 0 && !attachingReceipt && <>
										{showReceiptEmails ?
											<div className="w-100 sticky-top info modal-dialog border-app" style={{ top: 56, overflowX: 'hidden' }}>
												<div className="w-100 pt-2 sticky-top bg-white" style={{ top: '-2.1vh' }} key={`receiptdiv-1`}>
													<Grid container className="centered text-success">
														<Grid item xs={12} >
															<h3>Quick Receipts</h3>
														</Grid>
													</Grid >
												</div>
												{
													receiptEmails.map(receipt => {
														return (
															<div className="w-100" key={`receiptdiv${receipt.receiptEmailId}`}>
																<ReceiptEmail
																	Item={receipt}
																	OnClick={() => OpenReceiptEmailModal(receipt.receiptEmailId)}
																	key={`receipt${receipt.receiptEmailId}`} />
															</div>
														);
													})
												}
												<div className="w-100 centered sticky-bottom bg-white text-app" key={`receiptdiv-1`}>
													<IconButton onClick={() => setShowReceiptEmails(false)}><ChevronUp /></IconButton>
												</div>
											</div> :
											<div className="w-100 centered my-2 sticky-top" style={{ top: 56 }} key={`receiptdiv-1`}>
												<Button variant="contained" onClick={() => setShowReceiptEmails(true)}><h3 className="my-1">View Quick Receipts</h3></Button>
											</div>
										}
									</>}
									{attachingReceipt !== undefined && <>
										<div className="w-100 centered info btn border-app mb-3 sticky-top" style={{ top: 56 }}>
											Attaching Quick Receipt
											<h4>{attachingReceipt!.emailSubject}</h4>
											<Button className="mb-4" variant="contained" onClick={() => setAttachingReceipt(undefined)}><h3 className="my-1">Cancel</h3></Button>
										</div>
									</>}
									{isMobile &&
										<Fab
											className="fab"
											component="label"
											color="primary">
											<h1 className="mb-1">
												<CameraFill />
											</h1>
											<VisuallyHiddenInput type="file" onChange={UploadFile} />
										</Fab>
									}
									{
										transactions.map(transaction => {
											return (
												<div className="w-100" key={`transactdiv${transaction.accountTransactionId}`}>
													{!IsCurrentDate(transaction) && (
														<Divider
															Text={curDate.toString()}
															key={`date${transaction.accountTransactionId}`} />
													)}
													<Transaction
														Item={transaction}
														Color={transaction.color}
														Categories={categories}
														HasExternal={hasExternal}
														OnClick={() => OpenModal(transaction.accountTransactionId)}
														key={`transaction${transaction.accountTransactionId}`} />
												</div>
											);
										})
									}
								</>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);

	function IsCurrentDate(transaction: TransactionItem) {
        if (curDate.Value.getTime() !== dayjs(transaction.transactionDate.Value).startOf('day').toDate().getTime()) {
            curDate.Value = dayjs(transaction.transactionDate.Value).startOf('day').toDate();
			return false;
		}
		return true;
	}

	function AddViewMenuItems() {
		let result: any[] = [];

		result.push({
			Info: true,
			InfoText: <b>{`View Icons on ${FamilyFeatureValue(user, Features.TransactionTitle)}s`}</b>
		});

		if (FamilyHasAccess(user, Features.Income)) {
			result.push({
				Icon: localData.ShowNeedsEdit ? <CheckLg /> : <></>,
				Text: <div><PencilSquare className='mx-2 text-app' />Items without budget, category or receipt</div>,
				OnClick: () => repo.UpdateLocalData({
					...localData,
					ShowNeedsEdit: !localData.ShowNeedsEdit
				})
			});
		}

		if (FamilyHasAccess(user, Features.Taxes)) {
			result.push({
				Icon: localData.ShowHasTaxes ? <CheckLg /> : <></>,
				Text: <div><Tag className='mx-2 text-app' />Items containing sales tax</div>,
				OnClick: () => repo.UpdateLocalData({
					...localData,
					ShowHasTaxes: !localData.ShowHasTaxes
				})
			});
		}

		if (FamilyHasAccess(user, Features.Recurring)) {
			result.push({
				Icon: localData.ShowRepeating ? <CheckLg /> : <></>,
				Text: <div><ArrowRepeat className='mx-2 text-app' />Recurring Items</div>,
				OnClick: () => repo.UpdateLocalData({
					...localData,
					ShowRepeating: !localData.ShowRepeating
				})
			});
		}

		result.push({
			Icon: localData.ShowNeedsReimbursement ? <CheckLg /> : <></>,
			Text: <div><Cash className='mx-2 text-app' />Items needing reimbursement</div>,
			OnClick: () => repo.UpdateLocalData({
				...localData,
				ShowNeedsReimbursement: !localData.ShowNeedsReimbursement
			})
		});

		return result;
	}

	function AddMenuItems() {
		let result: any[] = [];

		result.push({
			Icon: <ReceiptCutoff />,
			Text: 'Expense',
			OnClick: () => OpenNewModal(false)
		});

		if (FamilyHasAccess(user, Features.Income)) {
			result.push({
				Icon: <Wallet />,
				Text: 'Income',
				OnClick: () => OpenNewModal(true)
			});
		}

		result.push({
			Icon: <CarFrontFill />,
			Text: 'Mileage',
			OnClick: () => OpenNewMileageModal()
		});

		if (FamilyHasAccess(user, Features.CheckRequests)) {
			result.push({
				Icon: <CardText />,
				Text: 'Check Request',
				OnClick: () => navigate('/CheckRequests#new')
			});
		}

		result.push({
			Icon: <ReceiptCutoff />,
			Text: 'Quick Receipts',
			OnClick: () => OpenNewReceiptEmailModal()
		});

		return result;
	}

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//Filter
	function OpenFilter() {
        modal = {
			...modal,
			Body: <EditFilter Mileage Filter={filter} Accounts={accounts} Budgets={budgetTree} Categories={categories} OnSave={SaveFilter} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function ClearFilter() {
		setFilter({ ...emptyFilter });
		navigate("/");
	}

	function SaveFilter(item: AccountTransactionFilterModel) {
		setFilter({ ...item });
		HideModal();
	}

	function FilterIsDefault() {
		return (
			filter.AccountId === emptyFilter.AccountId &&
			filter.IsRecurring === emptyFilter.IsRecurring &&
			filter.IsNotRecurring === emptyFilter.IsNotRecurring &&
			filter.NeedsReceipt === emptyFilter.NeedsReceipt &&
			filter.NeedsReimbursement === emptyFilter.NeedsReimbursement &&
			filter.IsAdminReview === emptyFilter.IsAdminReview &&
			filter.IsMine === emptyFilter.IsMine &&
			filter.IsRejected === emptyFilter.IsRejected &&
			filter.IsIncome === emptyFilter.IsIncome &&
			filter.IsExpense === emptyFilter.IsExpense &&
			filter.Merchant === emptyFilter.Merchant &&
			filter.User === emptyFilter.User &&
			filter.StartDate === emptyFilter.StartDate &&
			filter.EndDate === emptyFilter.EndDate &&
			filter.IncludeBudgetIds === emptyFilter.IncludeBudgetIds &&
			filter.ExcludeBudgetIds === emptyFilter.ExcludeBudgetIds &&
			filter.IncludeCategoryIds === emptyFilter.IncludeCategoryIds &&
			filter.ExcludeCategoryIds === emptyFilter.ExcludeCategoryIds &&
			filter.MinAmount === emptyFilter.MinAmount &&
			filter.MaxAmount === emptyFilter.MaxAmount
		);
	}

	//Transactions
	async function SaveTransaction(item: TransactionItem) {
		HideModal();
		let bcas = [] as BudgetCategoryAmount[];
		for (let i = 0; i < item.budgetCategoryAmounts.length; i++) {
			let amount = { ...item.budgetCategoryAmounts[i] };
			if (item.miles && item.miles > 0) {
				amount.amount = item.miles * (item.rate ?? 0);
			}
			bcas = [...bcas, amount]
		}
		let rec = item.recurringTransaction;
		if (rec) {
			rec.accountId = item.accountId;
			rec.budgetId = item.budgetCategoryAmounts[0].budgetId;
			rec.categoryId = item.budgetCategoryAmounts[0].categoryId;
			rec.description = item.merchant;
			rec.nextAmount = item.budgetCategoryAmounts.reduce((total, t) => total + t.amount, 0);
			rec.nextDate = item.transactionDate.addDays(rec.days).addMonths(rec.months);
		}
		item = {
			...item,
			budgetCategoryAmounts: bcas,
			recurringTransaction: rec
		};
		if (item.receiptEmailIds && item.receiptEmailIds.length > 0) {
			item.receiptEmailIds.forEach(id => {
				repo.UpdateReceiptEmailAsync(receiptEmails.find(r => r.receiptEmailId === id)!, false, true);
			})
		}
		if (item.importedTransactionId) {
			repo.UpdateTransactionAsync(transactions!.find(r => r.accountTransactionId === item.importedTransactionId)!, false, true);
		}
		await repo.UpdateTransactionAsync(item, item.accountTransactionId === 0, false);
		repo.GetBudgetsAsync();
		repo.GetCategoriesAsync();
	}

	function DeleteTransaction(item: TransactionItem) {
		repo.UpdateTransactionAsync(item, false, true);
		HideModal();
	}

	function OpenNewModal(income: boolean, hasReceipt: boolean = false, receiptEmail: ReceiptEmailItem | undefined = undefined) {
		let selectedBudget = budgetTree?.find(b => b.isMine && (!b.activeTimeframes || b.activeTimeframes.length === 0 || b.activeTimeframes.filter(t => (t.startDate?.startOfDay() ?? new DateTime()) < new DateTime() && (!t.endDate || t.endDate!.addDays(1).startOfDay() > new DateTime())).length > 0));
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => b.budgetId === 0);
		}
        let transaction: TransactionItem = {
			accountTransactionId: 0,
			merchant: "",
			receipts: hasReceipt ? receiptEmail!.receipts! : [],
			receiptEmailIds: hasReceipt ? [...[], receiptEmail!.receiptEmailId] : undefined,
			hasReceipt: hasReceipt,
			budgetCategoryAmounts: [{ budgetId: selectedBudget?.budgetId, categoryId: selectedBudget?.categories[0].categoryId, amount: 0 } as BudgetCategoryAmount],
			accountId: 0,
            transactionDate: new DateTime(),
			isMine: true,
			needsMyApproval: true,
			needsReimbursement: false,
			completedReimbursement: false,
			isIncome: income,
			created: "",
			lastUpdated: "",
			color: "",
			isMiles: false,
			isCheckRequest: false
		};
		modal = {
			...modal,
			Body: <EditTransaction Transaction={transaction} Budgets={budgetTree} ReceiptEmails={receiptEmails} OnSave={SaveTransaction} Close={HideModal} AttachedReceipt={receiptEmail} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenNewMileageModal() {
		let selectedBudget = budgetTree?.find(b => ["mileage", "miles"].includes(b.description.toLowerCase()));
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => ["travel"].includes(b.description.toLowerCase()));
		}
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => b.isMine && (!b.activeTimeframes || b.activeTimeframes.length === 0 || b.activeTimeframes.filter(t => (t.startDate?.startOfDay() ?? new DateTime()) < new DateTime() && (!t.endDate || t.endDate!.addDays(1).startOfDay() > new DateTime())).length > 0));
		}
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => b.budgetId === 0);
		}
		let selectedCategory = selectedBudget!.categories.find(b => ["mileage", "miles"].includes(b.description.toLowerCase()));
		if (!selectedCategory) {
			selectedCategory = selectedBudget!.categories.find(b => ["travel"].includes(b.description.toLowerCase()));
		}
		if (!selectedCategory) {
			selectedCategory = selectedBudget!.categories[0];
		}
		let transaction: TransactionItem = {
			accountTransactionId: 0,
			merchant: "",
			receipts: [],
			hasReceipt: false,
			budgetCategoryAmounts: [{ budgetId: selectedBudget!.budgetId, categoryId: selectedCategory.categoryId, amount: 0 } as BudgetCategoryAmount],
			accountId: 0,
			transactionDate: new DateTime(),
			isCheckRequest: false,
			isMine: true,
			isMiles: true,
			needsMyApproval: true,
			needsReimbursement: true,
			completedReimbursement: false,
			isIncome: false,
			rate: user.mileageRate,
			created: "",
			lastUpdated: "",
			color: "",
			miles: null
		};
		modal = {
			...modal,
			Body: <EditTransaction Transaction={transaction} Budgets={budgetTree} OnSave={SaveTransaction} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenModal(idx: number) {
		let transaction = transactions!.find(t => t.accountTransactionId === idx);
		if (transaction) {
			transaction = {
				...transaction,
				isRecurring: (transaction!.recurringTransactionId ?? 0) !== 0
			}
			modal = {
				...modal,
				Body: <EditTransaction Mileage={transaction.miles != null} ReceiptEmails={receiptEmails} Transaction={transaction} Budgets={budgetTree} Transactions={transactions} Categories={categories} OnSave={SaveTransaction} OnDelete={DeleteTransaction} Close={HideModal} AttachedReceipt={attachingReceipt} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
		setAttachingReceipt(undefined);
	}

	//ReceiptEmails
	async function SaveReceiptEmail(items: ReceiptEmailItem[]) {
		HideModal();
		for (let item of items) {
			await repo.UpdateReceiptEmailAsync({ ...item, userEmailId: user.emails!.find(u => u.email === user.email)!.userEmailId }, item.receiptEmailId === 0, false);
		}
		repo.GetReceiptEmailsAsync();
	}

	function DeleteReceiptEmail(item: ReceiptEmailItem) {
		repo.UpdateReceiptEmailAsync(item, false, true);
		HideModal();
	}

	function ConvertReceiptEmail(item: ReceiptEmailItem) {
		OpenNewModal(false, true, item);
	}

	function AttachReceiptEmail(item: ReceiptEmailItem) {
		setAttachingReceipt(item);
		modal = {
			...modal,
			IsOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenNewReceiptEmailModal() {
		let emptyReceiptEmail: ReceiptEmailItem = {
			receiptEmailId: 0,
			userEmailId: 0,
			emailSubject: "",
			hasAttachment: false
		};

		modal = {
			...modal,
			Body: <EditReceiptEmail ReceiptEmail={emptyReceiptEmail} OnSave={SaveReceiptEmail} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenReceiptEmailModal(idx: number) {
		let transaction = receiptEmails!.find(t => t.receiptEmailId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <EditReceiptEmail ReceiptEmail={transaction} Budgets={budgetTree} OnSave={SaveReceiptEmail} OnDelete={DeleteReceiptEmail} Close={HideModal} CreateNew={ConvertReceiptEmail} AttachReceipt={AttachReceiptEmail} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		let { files } = event.target;
		if (!files) return;

		let realFiles = files as FileList;
		let newReceipts = [] as ReceiptEmailItem[];
		for (let i = 0; i < realFiles.length; i++) {
			let file = realFiles[i];
			let resizedFile = await new Promise(resolve =>
				FileResizer.imageFileResizer(file, 1280, 1280, file.type.replace("image/", ""), 100, 0, uri => resolve(uri))
			);
			let item: ReceiptEmailItem = {
				receiptEmailId: 0,
				userEmailId: 0,
				emailSubject: `Quick Capture ${(new Date()).toLocaleDateString()} ${(new Date()).toLocaleTimeString()}`,
				hasAttachment: false,
				receipts: [ resizedFile!.toString().substring(resizedFile!.toString().indexOf(',') + 1) ]
			};
			newReceipts.push(item);
		}

		let items = [
			...newReceipts
		];
		SaveReceiptEmail(items);
	}

}

export default Transactions;