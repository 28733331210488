import { useState } from "react";
import { BudgetItem, CategoryIdExtended, CategoryItem, DateTime, IdStartEnd, TransactionItem } from "../../types";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Grid, IconButton, TextField, Typography } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import { useItems } from "../../features/Fetch/Items";
import CategoryLimited from "../ListItems/CategoryLimited";
import Divider from "../Inputs/Divider";
import { CheckSquareFill, ChevronDown, DashSquare, Square, ThreeDots, ThreeDotsVertical } from "react-bootstrap-icons";
import DropdownMenu from "../Inputs/DropdownMenu";
import { useSelector } from "react-redux";
import EditTimePeriodDate from "./EditTimeperiodDate";
import CategoryLimitedError from "../ListItems/CategoryLimitedError";
import CategoryLimitedOverlap from "../ListItems/CategoryLimitedOverlap";

const EditBudgetCategories = (props: any) => {
	let repo = useItems();

	let categories: CategoryItem[] = repo.SortedCategories();
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value ?? []);
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value ?? []);
	let isMobile: boolean = useSelector((state: any) => state.isMobile.value);

	let [transactionCache, setTransactionCache] = useState(transactions);
	let [crCache, setCrCache] = useState(checkRequests);
	let [budget, setBudget] = useState({ ...props.Item as BudgetItem, categoryIdsExtended: [] as CategoryIdExtended[] });
	let categoriesSorted = [] as IdStartEnd[];
	if (transactions && checkRequests && budget && (transactionCache.length !== transactions.length || crCache.length !== checkRequests.length || budget.categoryIds.length !== budget.categoryIdsExtended.length)) {
		categoriesSorted = SortCategories(budget.categoryIds);
		SetExtendedCategories();
		setTransactionCache(transactions);
		setCrCache(checkRequests);
	}

	let activeCategories = budget.categoryIdsExtended.filter(c => c.active)?.map(c => c.item);
	let inactiveCategories = budget.categoryIdsExtended.filter(c => !c.active)?.map(c => c.item);
	let activeChecked = budget.categoryIdsExtended.filter((c, i) => activeCategories.includes(budget.categoryIdsExtended[i].item) && c.selected).length;
	let inactiveChecked = budget.categoryIdsExtended.filter((c, i) => inactiveCategories.includes(budget.categoryIdsExtended[i].item) && c.selected).length;
	let uncoveredCategories = GetUncoveredTransactions();
	let overlappingCategories = GetOverlappingTimePeriods();

	let [showStart, setShowStart] = useState(false);
	let [showEnd, setShowEnd] = useState(false);

	return (<>
		<ModalHeader Title="Categories" Close={props.Close} />
		<div className="modal-body mt-0">
			<Grid container>
				<Autocomplete
					multiple
					className="w-100 sticky-top pt-5"
					id="tags-outlined"
					options={categories
						.map(c => c.categoryId)}
					getOptionLabel={GetValueFromId}
					value={[]}
					filterSelectedOptions
					freeSolo
					renderInput={(params) => (
						<TextField
							{...params}
							label="Add Categories"
							className="bg-white"
						/>
					)}
					onChange={OnChange}
				/>
				{uncoveredCategories.map(categoryId => {
					return (
						<Grid item xs={12}>
							<CategoryLimitedError
								Active={categoryId}
								Item={categories.find(c => c.categoryId === categoryId.item.id)}
								Budget={budget}
								Add={AddCategoryWithDates}
								Extend={ExtendCategoryDates}
							/>
						</Grid>
					);
				})}
				{overlappingCategories.map(categoryId => {
					return (
						<Grid item xs={12}>
							<CategoryLimitedOverlap
								Active={categoryId}
								Item={categories.find(c => c.categoryId === categoryId.item.id)}
								Budget={budget}
							/>
						</Grid>
					);
				})}
				<Grid item xs={12}>
					<h3 className="text-app text-center mt-5">Active</h3>
					<Divider />
				</Grid>
				<Grid item xs={10} xl={6} className="text-start">
					<DropdownMenu
						OpenElement={
							<IconButton
								className="text-app"
								aria-label='edit menu'>
								{activeChecked === 0 ?
									<Square className='mx-1 px-1' />
									: activeChecked === activeCategories.length ?
										<CheckSquareFill className='mx-1 px-1' />
										:
										<DashSquare className='mx-1 px-1' />
								}
								<h6 className="mb-0">Select Multiple ({activeChecked} Selected)</h6>
							</IconButton>
						}
						Items={AddActiveMenuItems() ?? []}>
					</DropdownMenu>
				</Grid>
				{activeChecked > 0 &&
					<Grid item xs={2} xl={6} className="text-end">
						<DropdownMenu
							OpenElement={
								<IconButton
									className="text-app"
									aria-label='edit menu'>
									{!isMobile ? <>
										<h6 className="mb-0">Action&nbsp;</h6>
										<ThreeDots />
									</> :
										<ThreeDotsVertical />
									}
								</IconButton>
							}
							Items={AddActiveActionItems() ?? []}>
						</DropdownMenu>
					</Grid>
				}
				{activeCategories.map(categoryId => {
					let item = budget.categoryIdsExtended.find(c => c.item === categoryId)!;
					return (
						<Grid item xs={12}>
							<CategoryLimited
								Alert={uncoveredCategories.filter(c => c.item.id === categoryId.id).length > 0 || overlappingCategories.filter(c => c === item).length > 0}
								Active={item}
								Item={categories.find(c => c.categoryId === categoryId.id)}
								Budget={budget}
								Checked={item.selected}
								UpdateStartDate={UpdateStartDate}
								UpdateEndDate={UpdateEndDate}
								OnChecked={UpdateChecked}
								Add={AddOne}
								/>
						</Grid>
					);
				})}
				{inactiveCategories.length > 0 &&
					<Accordion className="w-100 my-5">
						<AccordionSummary expandIcon={<ChevronDown />}>
							<Typography>Inactive Categories</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								<Grid item xs={6} className="text-start">
									<DropdownMenu
										OpenElement={
											<IconButton
												className="text-app"
												aria-label='edit menu'>
												{inactiveChecked === 0 ?
													<Square className='mx-1 px-1' />
													: inactiveChecked === inactiveCategories.length ?
														<CheckSquareFill className='mx-1 px-1' />
														:
														<DashSquare className='mx-1 px-1' />
												}
												<h6 className="mb-0">Select Multiple ({activeChecked} Selected)</h6>
											</IconButton>
										}
										Items={AddInactiveMenuItems() ?? []}>
									</DropdownMenu>
								</Grid>
								{inactiveChecked > 0 &&
									<Grid item xs={6} className="text-end">
										<DropdownMenu
											OpenElement={
												<IconButton
													className="text-app"
													aria-label='edit menu'>
													{!isMobile ? <>
														<h6 className="mb-0">Action&nbsp;</h6>
														<ThreeDots />
													</> :
														<ThreeDotsVertical />
													}
												</IconButton>
											}
											Items={AddInactiveActionItems() ?? []}>
										</DropdownMenu>
									</Grid>
								}
								{inactiveCategories.map(categoryId => {
									let item = budget.categoryIdsExtended.find(c => c.item === categoryId)!;
									return (
										<Grid item xs={12}>
											<CategoryLimited
												Alert={uncoveredCategories.filter(c => c.item.id === categoryId.id).length > 0 || overlappingCategories.filter(c => c === item).length > 0}
												Active={item}
												Item={categories.find(c => c.categoryId === categoryId.id)}
												Budget={budget}
												Checked={item.selected}
												UpdateStartDate={UpdateStartDate}
												UpdateEndDate={UpdateEndDate}
												OnChecked={UpdateChecked}
												Add={AddOne}
												/>
										</Grid>
									);
								})}
							</Grid>
						</AccordionDetails>
					</Accordion>
				}
			</Grid>
			{showStart &&
				<EditTimePeriodDate Label="Activation Date" Close={() => setShowStart(false)} Confirm={UpdateStartDateMultiple} />
			}
			{showEnd &&
				<EditTimePeriodDate Label="Expiration Date" Close={() => setShowEnd(false)} Confirm={UpdateEndDateMultiple} />
			}
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function SortCategories(categoryIds: IdStartEnd[]) {
		return [...categoryIds as IdStartEnd[]].sort((a, b) => {
			let firstStart = new Date(b.startDate?.Value ?? 0);
			firstStart.setHours(0, 0, 0, 0);
			let secondStart = new Date(a.startDate?.Value ?? 0);
			secondStart.setHours(0, 0, 0, 0);
			let firstEnd = new Date(b.endDate?.Value ?? '2999-12-31');
			firstEnd.setHours(0, 0, 0, 0);
			let secondEnd = new Date(a.endDate?.Value ?? '2999-12-31');
			secondEnd.setHours(0, 0, 0, 0);
			return (categories.find(c => c.categoryId === a.id)?.description.localeCompare(categories.find(c => c.categoryId === b.id)?.description ?? "") || secondStart.getTime() - firstStart.getTime()) || (secondEnd.getTime() - firstEnd.getTime())
		});
	}

	function SetExtendedCategories() {
		setBudget({
			...budget,
			categoryIds: categoriesSorted,
			categoryIdsExtended: categoriesSorted.map(cat => {
				let trs = transactions.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === cat.id && c.budgetId === budget.budgetId).length > 0 && (!cat.startDate || cat.startDate.startOfDay() <= t.transactionDate) && (!cat.endDate || cat.endDate.addDays(1).startOfDay() > t.transactionDate));
				let crs = checkRequests.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === cat.id && c.budgetId === budget.budgetId).length > 0 && (!cat.startDate || cat.startDate.startOfDay() <= t.transactionDate) && (!cat.endDate || cat.endDate.addDays(1).startOfDay() > t.transactionDate));
				return {
					item: cat,
					selected: false,
					active: categoriesSorted.filter(c => (c.startDate === null || c.startDate! <= new DateTime()) && (c.endDate === null || c.endDate! >= new DateTime())).includes(cat),
					transactions: trs,
					checkRequests: crs
				} as CategoryIdExtended
			})
		});
	}

	function AddActiveMenuItems() {
		let result: any[] = [];

		if (activeChecked < activeCategories.length) {
			result.push({
				Text: 'Select All',
				OnClick: () => UpdateCheckedMultiple('all', true)
			});
		}

		result.push({
			Text: 'All New',
			OnClick: () => UpdateCheckedMultiple('new', true)
		});

		result.push({
			Text: 'All Updated',
			OnClick: () => UpdateCheckedMultiple('updated', true)
		});

		result.push({
			Text: 'No Time Limit',
			OnClick: () => UpdateCheckedMultiple('unlimited', true)
		});

		result.push({
			Text: 'Unused',
			OnClick: () => UpdateCheckedMultiple('unused', true)
		});

		result.push({
			Text: 'No Activation Date',
			OnClick: () => UpdateCheckedMultiple('nostart', true)
		});

		result.push({
			Text: 'No Expiration Date',
			OnClick: () => UpdateCheckedMultiple('noend', true)
		});

		result.push({
			Text: 'Has Activation Date',
			OnClick: () => UpdateCheckedMultiple('start', true)
		});

		result.push({
			Text: 'Has Expiration Date',
			OnClick: () => UpdateCheckedMultiple('end', true)
		});

		if (activeChecked > 0) {
			result.push({
				Text: 'Deselect All',
				OnClick: () => UpdateCheckedMultiple('none', true)
			});
		}

		return result;
	}

	function AddInactiveMenuItems() {
		let result: any[] = [];

		if (inactiveChecked < inactiveCategories.length) {
			result.push({
				Text: 'Select All',
				OnClick: () => UpdateCheckedMultiple('all', false)
			});
		}

		result.push({
			Text: 'All New',
			OnClick: () => UpdateCheckedMultiple('new', false)
		});

		result.push({
			Text: 'All Updated',
			OnClick: () => UpdateCheckedMultiple('updated', false)
		});

		result.push({
			Text: 'Unused',
			OnClick: () => UpdateCheckedMultiple('unused', false)
		});

		result.push({
			Text: 'No Activation Date',
			OnClick: () => UpdateCheckedMultiple('nostart', false)
		});

		result.push({
			Text: 'No Expiration Date',
			OnClick: () => UpdateCheckedMultiple('noend', false)
		});

		result.push({
			Text: 'Future Activation',
			OnClick: () => UpdateCheckedMultiple('start', false)
		});

		result.push({
			Text: 'Already Expired',
			OnClick: () => UpdateCheckedMultiple('end', false)
		});

		if (inactiveChecked > 0) {
			result.push({
				Text: 'Deselect All',
				OnClick: () => UpdateCheckedMultiple('none', false)
			});
		}

		return result;
	}

	function AddActiveActionItems() {
		let result: any[] = [];

		result.push({
			Text: 'Remove',
			OnClick: () => RemoveMultiple(true)
		});

		result.push({
			Text: 'Set Activation Date',
			OnClick: () => setShowStart(true)
		});

		result.push({
			Text: 'Set Expiration Date',
			OnClick: () => setShowEnd(true)
		});

		/*result.push({
			Text: 'Create New Active Period',
			OnClick: () => setShowStart(true)
		});
		*/

		return result;
	}

	function AddInactiveActionItems() {
		let result: any[] = [];

		result.push({
			Text: 'Remove',
			OnClick: () => RemoveMultiple(false)
		});

		result.push({
			Text: 'Set Activation Date',
			OnClick: () => setShowStart(true)
		});

		result.push({
			Text: 'Set Expiration Date',
			OnClick: () => setShowEnd(true)
		});

		/*result.push({
			Text: 'Create New Active Period',
			OnClick: () => setShowStart(true)
		});
		*/

		return result;
	}

	function ShouldShowSave() {
		if (!budget.description) return false;
		if (uncoveredCategories.length > 0) return false;
		if (overlappingCategories.length > 0) return false;
		return true;
	}

	function AddCategoryWithDates(newValue: IdStartEnd) {
		let newCategoryId = { ...newValue };
		let categoryIds = [...budget.categoryIds];
		let categoryIdsExtended = [...budget.categoryIdsExtended];
		categoryIds.push(newCategoryId);
		categoryIds = SortCategories(categoryIds);
		categoryIdsExtended.splice(categoryIds.indexOf(newCategoryId), 0, {
			item: newCategoryId,
			selected: false,
			active: newCategoryId.startDate! <= new DateTime() && newCategoryId.endDate! >= new DateTime(),
			isNew: true,
			transactions: GetTransactionsForNewDates(newCategoryId, transactions),
			checkRequests: GetTransactionsForNewDates(newCategoryId, transactions)
		} as CategoryIdExtended);
		let item = ({
			...budget,
			categoryIds: categoryIds,
			categoryIdsExtended: categoryIdsExtended
		});
		setBudget(item);
	}

	function UpdateCategories(newValue: number) {
		let newCategoryId = { id: newValue, startDate: null, endDate: null } as IdStartEnd;
		let categoryIds = [...budget.categoryIds];
		let categoryIdsExtended = [...budget.categoryIdsExtended];
		categoryIds.push(newCategoryId);
		categoryIds = SortCategories(categoryIds);
		categoryIdsExtended.splice(categoryIds.indexOf(newCategoryId), 0, { item: newCategoryId, selected: false, active: true, isNew: true, transactions: GetTransactionsForNewDates(newCategoryId, transactions), checkRequests: GetTransactionsForNewDates(newCategoryId, transactions) } as CategoryIdExtended);
		let item = ({
			...budget,
			categoryIds: categoryIds,
			categoryIdsExtended: categoryIdsExtended
		});
		setBudget(item);
	}

	function UpdateStartDate(newValue: DateTime | null, idx: number) {
		let categoryIdsClone = [...budget.categoryIdsExtended];
		categoryIdsClone[idx] = {
			...categoryIdsClone[idx],
			item: { ...categoryIdsClone[idx].item, startDate: newValue },
			selected: false,
			isUpdated: true,
			active: (newValue === null || newValue <= new DateTime()) && (categoryIdsClone[idx].item.endDate === null || categoryIdsClone[idx].item.endDate! >= new DateTime())
		};
		categoryIdsClone[idx].transactions = GetTransactionsForNewDates(categoryIdsClone[idx].item, transactions);
		categoryIdsClone[idx].checkRequests = GetTransactionsForNewDates(categoryIdsClone[idx].item, checkRequests);
		let item = ({
			...budget,
			categoryIds: categoryIdsClone.map(c => c.item),
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
	}

	function UpdateStartDateMultiple(newValue: DateTime | null) {
		let categoryIdsClone = [...budget.categoryIdsExtended];

		for (let idx in categoryIdsClone) {
			if (categoryIdsClone[idx].selected) {
				categoryIdsClone[idx] = {
					...categoryIdsClone[idx],
					item: { ...categoryIdsClone[idx].item, startDate: newValue },
					selected: false,
					isUpdated: true,
					active: (newValue === null || newValue <= new DateTime()) && (categoryIdsClone[idx].item.endDate === null || categoryIdsClone[idx].item.endDate! >= new DateTime())
				};
				categoryIdsClone[idx].transactions = GetTransactionsForNewDates(categoryIdsClone[idx].item, transactions);
				categoryIdsClone[idx].checkRequests = GetTransactionsForNewDates(categoryIdsClone[idx].item, checkRequests);
			}
		}
		let item = ({
			...budget,
			categoryIds: categoryIdsClone.map(c => c.item),
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
		setShowStart(false);
	}

	function UpdateEndDate(newValue: DateTime | null, idx: number) {
		let categoryIdsClone = [...budget.categoryIdsExtended];
		categoryIdsClone[idx] = {
			...categoryIdsClone[idx],
			item: { ...categoryIdsClone[idx].item, endDate: newValue },
			selected: false,
			isUpdated: true,
			active: (categoryIdsClone[idx].item.startDate === null || categoryIdsClone[idx].item.startDate! <= new DateTime()) && (newValue === null || newValue >= new DateTime())
		};
		categoryIdsClone[idx].transactions = GetTransactionsForNewDates(categoryIdsClone[idx].item, transactions);
		categoryIdsClone[idx].checkRequests = GetTransactionsForNewDates(categoryIdsClone[idx].item, checkRequests);
		let item = ({
			...budget,
			categoryIds: categoryIdsClone.map(c => c.item),
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
	}

	function UpdateEndDateMultiple(newValue: DateTime | null) {
		let categoryIdsClone = [...budget.categoryIdsExtended];

		for (let idx in categoryIdsClone) {
			if (categoryIdsClone[idx].selected) {
				categoryIdsClone[idx] = {
					...categoryIdsClone[idx],
					item: { ...categoryIdsClone[idx].item, endDate: newValue },
					selected: false,
					isUpdated: true,
					active: (categoryIdsClone[idx].item.startDate === null || categoryIdsClone[idx].item.startDate! <= new DateTime()) && (newValue === null || newValue >= new DateTime())
				};
				categoryIdsClone[idx].transactions = GetTransactionsForNewDates(categoryIdsClone[idx].item, transactions);
				categoryIdsClone[idx].checkRequests = GetTransactionsForNewDates(categoryIdsClone[idx].item, checkRequests);
			}
		}
		let item = ({
			...budget,
			categoryIds: categoryIdsClone.map(c => c.item),
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
		setShowEnd(false);
	}

	function ExtendCategoryDates(newValue: IdStartEnd) {
		let categoryIdsClone = [...budget.categoryIdsExtended];
		let matches = categoryIdsClone.filter(c => c.item.id === newValue.id);
		if (matches.length === 0 || matches.length > 1) return;
		if (matches.length === 1) {
			newValue = {
				...newValue,
				startDate: (matches[0].item.startDate && matches[0].item.startDate > newValue.startDate!) ? newValue.startDate : matches[0].item.startDate,
				endDate: (matches[0].item.endDate && matches[0].item.endDate < newValue.endDate!) ? newValue.endDate : matches[0].item.endDate
			};

			let idx = categoryIdsClone.indexOf(matches[0]);
			categoryIdsClone[idx] = {
				...categoryIdsClone[idx],
				item: {
					...categoryIdsClone[idx].item,
					startDate: newValue.startDate,
					endDate: newValue.endDate
				},
				selected: false,
				isUpdated: true,
				active: (!newValue.startDate || newValue.startDate! <= new DateTime()) && (!newValue.endDate ||newValue.endDate! >= new DateTime()),
			};
			categoryIdsClone[idx].transactions = GetTransactionsForNewDates(categoryIdsClone[idx].item, transactions);
			categoryIdsClone[idx].checkRequests = GetTransactionsForNewDates(categoryIdsClone[idx].item, checkRequests);
			let item = ({
				...budget,
				categoryIds: categoryIdsClone.map(c => c.item),
				categoryIdsExtended: categoryIdsClone
			});
			setBudget(item);

		}
	}

	function UpdateChecked(idx: number, newValue: boolean) {
		let categoryIdsClone = [...budget.categoryIdsExtended];
		categoryIdsClone[idx] = {
			...categoryIdsClone[idx], selected: newValue
		};
		let item = ({
			...budget,
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
	}

	function UpdateCheckedMultiple(option: string, isActive: boolean) {
		let categoryIdsClone = [...budget.categoryIdsExtended];
		switch (option) {
			case "all":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item)) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					}
				}
				break;
			case "new":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].isNew) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "updated":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].isUpdated) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "unlimited":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].item.startDate === null && budget.categoryIdsExtended[i].item.endDate === null) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "unused":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && categoryIdsClone[i].transactions.length === 0) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "nostart":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].item.startDate === null) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "noend":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].item.endDate === null) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "start":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].item.startDate !== null) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "end":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item) && budget.categoryIdsExtended[i].item.endDate !== null) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: true
						};
					} else {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "none":
				for (let i in categoryIdsClone) {
					if (isActive === activeCategories.includes(budget.categoryIdsExtended[i].item)) {
						categoryIdsClone[i] = {
							...categoryIdsClone[i], selected: false
						};
					}
				}
				break;
			case "":
				break;
		}
		let item = ({
			...budget,
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
	}

	function AddOne(id: number) {
		let newCategoryId = { id: id, startDate: null, endDate: null } as IdStartEnd;
		let item = ({
			...budget,
			categoryIds: [...budget.categoryIds, newCategoryId],
			categoryIdsExtended: [...budget.categoryIdsExtended, { item: newCategoryId, selected: false, active: true, transactions: [], checkRequests: [] } as CategoryIdExtended]
		});
		setBudget(item);
	}

	function RemoveMultiple(isActive: boolean) {
		let categoryIdsClone = [...budget.categoryIdsExtended];

		let id = categoryIdsClone.findIndex(c => c.selected);
		while (id > -1) {
			if (isActive === activeCategories.includes(budget.categoryIdsExtended[id].item)) {
				categoryIdsClone.splice(id, 1);
			} else {
				id++;
			}
			let newId = categoryIdsClone.slice(id).findIndex(c => c.selected);
			id = newId === -1 ? -1 : id + newId;
		}
		let item = ({
			...budget,
			categoryIds: categoryIdsClone.map(c => c.item),
			categoryIdsExtended: categoryIdsClone
		});
		setBudget(item);
	}

	function GetValueFromId(id: any) {
		return categories.find(o => o.categoryId === id)?.description ?? id;
	}

	async function OnChange(event: any, newValue: any[]) {
		if (typeof newValue[0] === 'string') {
			let existing = categories.find(o => o.description.trim().toLowerCase() === newValue[0].trim().toLowerCase());
			if (existing) {
				UpdateCategories(existing.categoryId);
			} else {
				let newItem = await repo.UpdateCategoryAsync({ categoryId: 0, description: newValue[0], isViewTransactions: true }, true);
				UpdateCategories(newItem.categoryId);
			}
		} else {
			UpdateCategories(newValue[0]);
		}
	}

	function GetTransactionsForNewDates(cat: IdStartEnd, transacts: TransactionItem[]) {
		return transacts?.filter(t => t.budgetCategoryAmounts.filter(c => c.categoryId === cat.id && c.budgetId === budget.budgetId).length > 0 && (!cat.startDate || cat.startDate.startOfDay() <= t.transactionDate) && (!cat.endDate || cat.endDate.addDays(1).startOfDay() > t.transactionDate)) ?? [];
	}

	function GetUncoveredTransactions() {
		let uncovered = [] as CategoryIdExtended[];

		for (let transact of transactions) {
			let covering = budget.categoryIds.filter(cat => transact.budgetCategoryAmounts.filter(c => c.categoryId === cat.id && c.budgetId === budget.budgetId).length > 0 && (!cat.startDate || cat.startDate.startOfDay() <= transact.transactionDate) && (!cat.endDate || cat.endDate.addDays(1).startOfDay() > transact.transactionDate));
			for (let cat of transact.budgetCategoryAmounts.filter(c => c.budgetId === budget.budgetId)) {
				if (covering.filter(c => c.id === cat.categoryId).length === 0) {
					let idx = uncovered.findIndex(u => u.item.id === cat.categoryId);
					if (idx > -1) {
						uncovered[idx].transactions.push(transact);
					} else {
						uncovered.push({
							active: false,
							item: {
								id: cat.categoryId,
								startDate: null,
								endDate: null
							},
							selected: false,
							transactions: [transact],
							checkRequests: []
						});
					}
				}
			}
		}

		for (let transact of checkRequests) {
			let covering = budget.categoryIds.filter(cat => transact.budgetCategoryAmounts.filter(c => c.categoryId === cat.id && c.budgetId === budget.budgetId).length > 0 && (!cat.startDate || cat.startDate.startOfDay() <= transact.transactionDate) && (!cat.endDate || cat.endDate.addDays(1).startOfDay() > transact.transactionDate));
			for (let cat of transact.budgetCategoryAmounts.filter(c => c.budgetId === budget.budgetId)) {
				if (covering.filter(c => c.id === cat.categoryId).length === 0) {
					let idx = uncovered.findIndex(u => u.item.id === cat.categoryId);
					if (idx > -1) {
						uncovered[idx].checkRequests.push(transact);
					} else {
						uncovered.push({
							active: false,
							item: {
								id: cat.categoryId,
								startDate: null,
								endDate: null
							},
							selected: false,
							transactions: [],
							checkRequests: [transact]
						});
					}
				}
			}
		}

		return uncovered;
	}

	function GetOverlappingTimePeriods() {
		let overlap = [] as CategoryIdExtended[];

		for (let cat of budget.categoryIdsExtended) {
			let matches = budget.categoryIdsExtended.filter(c => c.item.id === cat.item.id && budget.categoryIdsExtended.indexOf(c) !== budget.categoryIdsExtended.indexOf(cat));
			if (matches.length === 0) continue;

			let isOverlap = false;
			if ((matches.filter(m => m.item.startDate === cat.item.startDate).length > 0) ||
				(matches.filter(m => m.item.endDate === cat.item.endDate).length > 0) ||
				(matches.filter(m => m.item.startDate && cat.item.endDate && m.item.startDate === cat.item.endDate).length > 0) ||
				(matches.filter(m => m.item.endDate && cat.item.startDate && m.item.endDate === cat.item.startDate).length > 0) ||
				(matches.filter(m => !m.item.endDate && !m.item.startDate).length > 0) ||
				(matches.filter(m => !cat.item.endDate && !cat.item.startDate).length > 0) ||
				(matches.filter(m => !m.item.startDate && cat.item.startDate! < m.item.endDate!).length > 0) ||
				(matches.filter(m => !m.item.endDate && cat.item.endDate! > m.item.startDate!).length > 0) ||
				(matches.filter(m => !cat.item.startDate && m.item.startDate! < cat.item.endDate!).length > 0) ||
				(matches.filter(m => !cat.item.endDate && m.item.endDate! > cat.item.startDate!).length > 0) ||
				(matches.filter(m => m.item.startDate! < cat.item.startDate! && m.item.endDate! > cat.item.startDate!).length > 0) ||
				(matches.filter(m => m.item.startDate! < cat.item.endDate! && m.item.endDate! > cat.item.endDate!).length > 0)) {
				isOverlap = true;
			}

			if (isOverlap) overlap.push(cat);
		}

		return overlap;
	}
}

export default EditBudgetCategories;