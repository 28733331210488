import {  useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { CurrentUser, FamilyHasAccess } from '../../types';
import { useSelector } from 'react-redux';
import { Features } from '../../constants';

const NavBar = (props: any) => {
	let navigate = useNavigate();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	return (
		<h2>
			<BottomNavigation showLabels style={{ background: "transparent" }}>
				<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label={props.TransactionTitle} icon={<Icon.CurrencyDollar />} onClick={() => navigate("/")} />
				<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label="Budgets" icon={<Icon.PiggyBank />} onClick={() => navigate("/budgets")} />
				{FamilyHasAccess(user, Features.Recurring) ?
					<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label="Repeating" icon={<Icon.ArrowRepeat />} onClick={() => navigate("/recurrings")} />
					:
					<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label="Reports" icon={<Icon.FileBarGraph />} onClick={() => navigate("/reports")} />
				}
			</BottomNavigation>
		</h2>
		);
}

export default NavBar;