import { useDispatch, useSelector } from "react-redux";
import { AccountItem, AccountTransactionFilterModel, BudgetItem, BudgetVariant, CategoryItem, CurrentUser, CustomReportSettings, DateTime, FamilyCode, FamilyHasAccess, LocalData, ReceiptEmailItem, RecurringItem, TimeframeItem, TransactionItem, UserEmailItem, UserItem } from "../../types";
import { removeAccount, addAccount, setAccounts } from "../Slices/accountSlice";
import { remove as removeBudget, add as addBudget, set as setBudgets } from "../Slices/budgetSlice";
import { remove as removeCategory, add as addCategory, set as setCategories } from "../Slices/categorySlice";
import { remove as removeTimeframe, add as addTimeframe, set as setTimeframes } from "../Slices/timeframeSlice";
import { remove as removeTransaction, add as addTransaction, set as setTransactions } from "../Slices/transactionSlice";
import { remove as removeUser, add as addUser, set as setUsers } from "../Slices/userSlice";
import { useFetch } from "./Fetch";
import dayjs from "dayjs";
import { Features } from "../../constants";
import { addRecurring, removeRecurring, setRecurring } from "../Slices/recurringSlice";
import { addCheckRequest, removeCheckRequest, setCheckRequests } from "../Slices/checkRequestSlice";
import { addReceiptEmail, removeReceiptEmail, setReceiptEmails } from "../Slices/receiptEmailSlice";
import { Storage } from "../Saving/Storage";
import { setLocalData } from "../Slices/localDataSlice";

export const useItems = () => {
	let dispatch = useDispatch();
	let request = useFetch();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let localData: LocalData | undefined = useSelector((state: any) => state.localData.value);

	//General
	function ClearData() {
		dispatch(setCategories([]));
		dispatch(setTransactions([]));
		dispatch(setTimeframes([]));
		dispatch(setBudgets([]));
		dispatch(setUsers([]));
	}

	async function GetItemsAsync(set: any, target: string, feature: number) {
		if (user && FamilyHasAccess(user, feature)) {
			let items = await request.get(target);
			dispatch(set(await items));
			return items;
		} else {
			return [];
		}
	}

	function UpdateAndReturnList(target: string, isNew: boolean, isDelete: boolean, item: any, feature: number) {
		if (user && FamilyHasAccess(user, feature)) {
			if (isNew) {
				item = request.post(target, item);
			} else if (isDelete) {
				item = request.delete(target, item);
			} else {
				item = request.put(target, item);
			}
			return item;
		} else {
			return [];
		}
	}

	function UpdateLocalItemAfter(remove: any, add: any, item: any, itemId: number, isNew: boolean, isDelete: boolean) {
		if (isNew) {
			UpdateLocalItem(remove, add, item, 0, isDelete);
		}
		else if (!isDelete) {
			UpdateLocalItem(remove, add, item, itemId, isDelete);
		}
	}

	function UpdateLocalItem(remove: any, add: any, item: any, itemId: number, isDelete: boolean) {
		dispatch(remove(itemId));
		if (!isDelete) {
			return dispatch(add(item));
		}
	}

	//Accounts
	async function GetAccountsAsync() {
		let items = await GetItemsAsync(setAccounts, "Accounts", Features.Accounts);
		//Storage.SaveAccountsToStorage(items);
	}

	function SortedAccounts() {
		let items: AccountItem[] = useSelector((state: any) => state.accounts.value) ?? [] as AccountItem[];
		items = [ ...items ].sort((a, b) => a.description.localeCompare(b.description));
		items.push({ accountId: 0, description: 'Cash/Other', accountTypeId: 0, amount: 0, autoAdjust: false, isDefault: false, userAccountId: 0 });
		return items;
	}

	async function UpdateAccountAsync(item: AccountItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeAccount, addAccount, item, item.accountId, isDelete);

		item = await UpdateAndReturnList("Accounts", isNew, isDelete, item, Features.Accounts);
		UpdateLocalItemAfter(removeAccount, addAccount, item, item.accountId, isNew, isDelete);
	}

	//Budgets
	async function GetBudgetsAsync() {
		await GetItemsAsync(setBudgets, "Budgets", Features.Budgets);
	}

	async function GetBudgetsWithDateAsync(date: DateTime) {
		return await GetItemsAsync(setBudgets, `Budgets/${date.toQueryString()}`, Features.Budgets);
	}

	function GetBudgetTree() {
		let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
		let result = [] as BudgetItem[];
		let categories: CategoryItem[] | undefined = SortedCategories();
		budgets = budgets.filter(b => !b.isInactive);
		budgets?.forEach(b => {
			let cats = categories?.filter(c => b.categoryIds.map(c => c.id).includes(c.categoryId)) ?? [] as CategoryItem[];
			if (cats.length > 0) {
				result.push({ ...b, categories: cats });
			}
		});
		result = result?.sort((a, b) => a.description.localeCompare(b.description));
		result?.push({
				budgetId: 0,
				description: "Unbudgeted",
				categories: SortedCategories(),
				isViewTransactions: true
			} as BudgetItem);
		return result;
	}

	async function UpdateBudgetAsync(item: BudgetItem, variant: BudgetVariant, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, isDelete);

		var itemClone = { ...item };
		itemClone.categoryIds = [...item.categoryIds];
		itemClone.variant = variant;
		item = await UpdateAndReturnList("Budgets", isNew, isDelete, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, isNew, isDelete);
	}
	async function UpdateBudgetNameAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		item = await UpdateAndReturnList("Budgets/Name", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetColorAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		item = await UpdateAndReturnList("Budgets/Color", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetCategoriesAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.categoryIds = [...item.categoryIds];
		item = await UpdateAndReturnList("Budgets/Categories", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetAccountsAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		//itemClone.accountIds = [...item.accountIds];
		item = await UpdateAndReturnList("Budgets/Accounts", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetGoalAsync(item: BudgetItem, variant: BudgetVariant) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.variant = variant;
		item = await UpdateAndReturnList("Budgets/Goal", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetEndDateAsync(item: BudgetItem, variant: BudgetVariant) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.variant = variant;
		item = await UpdateAndReturnList("Budgets/EndDate", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetTimeframeAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.activeTimeframes = [...item.activeTimeframes];
		item = await UpdateAndReturnList("Budgets/Timeframe", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetTimeframeStartDateAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.activeTimeframes = [...item.activeTimeframes];
		item = await UpdateAndReturnList("Budgets/Timeframe/StartDate", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetTimeframeEndDateAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.activeTimeframes = [...item.activeTimeframes];
		item = await UpdateAndReturnList("Budgets/Timeframe/EndDate", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetTimeframeTimeframeAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.activeTimeframes = [...item.activeTimeframes];
		item = await UpdateAndReturnList("Budgets/Timeframe/Timeframe", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}
	async function UpdateBudgetTimeframeDeleteAsync(item: BudgetItem) {
		UpdateLocalItem(removeBudget, addBudget, item, item.budgetId, false);

		var itemClone = { ...item };
		itemClone.activeTimeframes = [...item.activeTimeframes];
		item = await UpdateAndReturnList("Budgets/Timeframe/Delete", false, false, itemClone, Features.Budgets);
		UpdateLocalItemAfter(removeBudget, addBudget, item, item.budgetId, false, false);
	}

	//Categories
	async function GetCategoriesAsync() {
		await GetItemsAsync(setCategories, "Categories", Features.Categories);
	}

	function SortedCategories() {
		let items: CategoryItem[] = useSelector((state: any) => state.categories.value) ?? [] as CategoryItem[];
		return [ ...items ].sort((a, b) => a.description.localeCompare(b.description));
	}

	function AddChildren(categoryId: number | undefined, categories: CategoryItem[] | undefined) {
		let result = [] as CategoryItem[];
		categories?.filter(c => c.parentCategoryId == categoryId)?.forEach(cat => {
			let category = { ...cat };
			category.childCategories = AddChildren(cat.categoryId, categories);
			result.push(category);
		});
		return result;
	}

	function GetCategoryTree() {
		let categories: CategoryItem[] = SortedCategories() ?? [] as CategoryItem[];
		let result = AddChildren(undefined, [...categories] as CategoryItem[]);
		return result.sort((a, b) => a.description.localeCompare(b.description));
	}

	async function UpdateCategoryAsync(item: CategoryItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeCategory, addCategory, item, item.categoryId, isDelete);

		item = await UpdateAndReturnList("Categories", isNew, isDelete, item, Features.Categories);

		UpdateLocalItemAfter(removeCategory, addCategory, item, item.categoryId, isNew, isDelete);
		return item;
	}

	//CheckRequests
	async function GetCheckRequestsAsync() {
		await GetItemsAsync(setCheckRequests, `CheckRequests`, Features.CheckRequests);
	}

	function SortedCheckRequests() {
		let items: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
		if (!items) return null;
		if (items.length === 0) return [];

		var result = [...items];
		return result?.sort((a, b) => {
			let first = new Date(a.checkRequest!.dateNeeded.Value);
			first.setHours(0, 0, 0, 0);
			let second = new Date(b.checkRequest!.dateNeeded.Value);
			second.setHours(0, 0, 0, 0);
			return (first.getTime() - second.getTime()) || (b.checkRequest!.accountTransactionCheckRequestId - a.checkRequest!.accountTransactionCheckRequestId)
		})?.sort((a, b) => {
			let first = new Date(a.transactionDate.Value);
			first.setHours(0, 0, 0, 0);
			let second = new Date(b.transactionDate.Value);
			second.setHours(0, 0, 0, 0);
			return (first.getTime() - second.getTime())
		}) ?? [] as TransactionItem[];
	}

	async function UpdateCheckRequestAsync(item: TransactionItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeCheckRequest, addCheckRequest, item, item.checkRequest!.accountTransactionCheckRequestId, isDelete);

		item = await UpdateAndReturnList("CheckRequests", isNew, isDelete, item, Features.CheckRequests);
		UpdateLocalItemAfter(removeCheckRequest, addCheckRequest, item, item.checkRequest!.accountTransactionCheckRequestId, isNew, isDelete);
	}

	async function ApproveCheckRequestAsync(item: TransactionItem) {
		UpdateLocalItem(removeCheckRequest, addCheckRequest, item, item.checkRequest!.accountTransactionCheckRequestId, false);

		item = await UpdateAndReturnList("CheckRequests/Approve", false, false, item, Features.CheckRequests);
		UpdateLocalItem(removeCheckRequest, addCheckRequest, item, item.checkRequest!.accountTransactionCheckRequestId, false);
	}

	async function GetCheckRequestPdfAsync(id: number) {
		return await request.getRaw(`CheckRequests/Pdf/${id}`);
	}

	//FamilyCodes
	async function GetFamilyCodesAsync() {
		if (user && FamilyHasAccess(user, Features.FamilyCodes)) {
			return await request.get("User/Codes");
		} else {
			return [];
		}
	}

	async function GenerateFamilyCodesAsync(codes: FamilyCode[], isAdmin: boolean, email: string) {
		if (user && FamilyHasAccess(user, Features.FamilyCodes)) {
			let item = await request.get(`User/Codes/Generate/${isAdmin.toString()}${(((email?.length ?? 0) > 0) ? '' : `/${email}`)}`);

			codes.push(item);
			return codes;
		} else {
			return [];
		}
	}

	async function SendFamilyCodesAsync(code: string, email: string) {
		if (user && FamilyHasAccess(user, Features.FamilyCodes)) {
			await request.get(`User/Codes/Send/${code}/${email}`);
		}
	}

	async function UpdateFamilyCodesAsync(item: FamilyCode, codes: FamilyCode[], isNew = false, isDelete = false) {
		if (codes.filter(c => c.familyCodeId === item.familyCodeId)) {
			codes.splice(codes.indexOf(codes.find(c => c.familyCodeId == item.familyCodeId) ?? {} as FamilyCode), 1);
		}

		item = await UpdateAndReturnList(`User/Codes/${item.familyCodeId}`, isNew, isDelete, item, Features.FamilyCodes);

		if (!isDelete) {
			codes.push(item);
		}
		return codes;
	}

	//LocalData
	function GetLocalData() {
		if (!localData) {
			localData = Storage.LoadLocalData();
		}
		if (!localData) {
			localData = {
				ShowHasTaxes: true,
				ShowNeedsEdit: true,
				ShowNeedsReimbursement: true,
				ShowRepeating: true
			}
			UpdateLocalData(localData);
		}
		return localData;
	}

	function UpdateLocalData(item: LocalData) {
		dispatch(setLocalData({ ...item }));
		Storage.SaveLocalData(item);
	}

	//Reports
	async function GetReportPdfAsync(settings: CustomReportSettings) {
		return await request.postRaw(`Reports/Pdf`, settings);
	}

	//Timeframes
	async function GetTimeframesAsync() {
		await GetItemsAsync(setTimeframes, "Timeframes", Features.Timeframes);
	}

	async function UpdateTimeframeAsync(item: TimeframeItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeTimeframe, addTimeframe, item, item.timeframeId, isDelete);

		await UpdateAndReturnList("Timeframes", isNew, isDelete, item, Features.Timeframes);
		UpdateLocalItemAfter(removeTimeframe, addTimeframe, item, item.timeframeId, isNew, isDelete);
	}

	//Transactions
	async function GetTransactionsAsync(count?: number) {
		await GetItemsAsync(setTransactions, `Transactions${count ? `/${count}` : ''}`, Features.Transactions);
	}

	function SortedTransactions(filter: AccountTransactionFilterModel | null) {
		let items: TransactionItem[] = useSelector((state: any) => state.transactions.value);
		if (!items) return null;
		if (items.length === 0) return [];

		var result = [...items];
		if (filter != null && result != null) {
			if ((filter.MinAmount ?? 0) > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.reduce((a, b) => a + b.amount, 0) >= (filter.MinAmount ?? 0));
			}
			if ((filter.MaxAmount ?? 0) > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.reduce((a, b) => a + b.amount, 0) <= (filter.MaxAmount ?? 0));
			}
			if (filter.Merchant) {
				result = result.filter(t => t.merchant.toLowerCase().includes(filter.Merchant.toLowerCase()));
			}
			if (filter.User) {
				result = result.filter(t => t.created.toLowerCase().includes(filter.User.toLowerCase()));
			}
			if (filter.IncludeAccountIds && filter.IncludeAccountIds.length > 0) {
				result = result.filter(t => filter.IncludeAccountIds.includes(t.accountId));
			}
			if (filter.IncludeBudgetIds && filter.IncludeBudgetIds.length > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.filter(tc => filter.IncludeBudgetIds.includes(tc.budgetId)).length > 0);
			}
			if (filter.ExcludeBudgetIds && filter.ExcludeBudgetIds.length > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.filter(tc => !filter.ExcludeBudgetIds.includes(tc.budgetId)).length > 0);
			}
			if (filter.IncludeCategoryIds && filter.IncludeCategoryIds.length > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.filter(tc => filter.IncludeCategoryIds.includes(tc.categoryId)).length > 0);
			}
			if (filter.ExcludeCategoryIds && filter.ExcludeCategoryIds.length > 0) {
				result = result.filter(t => t.budgetCategoryAmounts.filter(tc => filter.ExcludeCategoryIds.includes(tc.categoryId)).length == 0);
			}
			if (filter.StartDate) {
				result = result.filter(t => dayjs(t.transactionDate.startOfDay().Value) >= dayjs(filter.StartDate));
			}
			if (filter.EndDate != null) {
				var end = dayjs(filter.EndDate);
				end.add(1, 'day');
				result = result.filter(t => dayjs(t.transactionDate.startOfDay().Value) <= end);
			}
			if (filter.IsRecurring) {
				result = result.filter(t => t.recurringTransactionId != null);
			}
			if (filter.IsNotRecurring) {
				result = result.filter(t => t.recurringTransactionId == null);
			}
			if (filter.AccountId != null) {
				result = result.filter(t => t.accountId === filter.AccountId);
			}
			if (filter.NeedsReceipt) {
				result = result.filter(t => !t.hasReceipt);
			}
			if (filter.NeedsReimbursement) {
				result = result.filter(t => t.needsReimbursement && !t.completedReimbursement);
			}
			if (filter.IsAdminReview) {
				result = result.filter(t => t.isAdminReview);
			}
			if (filter.IsMine) {
				result = result.filter(t => t.isMine);
			}
			if (filter.IsRejected) {
				result = result.filter(t => t.budgetCategoryAmounts.filter(c => c.isRejected).length > 0);
			}
			if (filter.IsRecurring) {
				result = result.filter(t => (t.recurringTransactionId ?? 0) > 0);
			}
			if (filter.IsNotRecurring) {
				result = result.filter(t => (t.recurringTransactionId ?? 0) === 0);
			}
			if (filter.IsIncome) {
				result = result.filter(t => t.isIncome);
			}
			if (filter.IsExpense) {
				result = result.filter(t => !t.isIncome);
			}
		}
		return result!.sort((a, b) => {
			let first = new Date(a.transactionDate.Value);
			first.setHours(0, 0, 0, 0);
			let second = new Date(b.transactionDate.Value);
			second.setHours(0, 0, 0, 0);
			return (second.getTime() - first.getTime()) || (b.accountTransactionId - a.accountTransactionId)
		}) ?? [] as TransactionItem[];
	}

	async function UpdateTransactionAsync(item: TransactionItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeTransaction, addTransaction, item, item.accountTransactionId, isDelete);

		item = await UpdateAndReturnList("Transactions", isNew, isDelete, item, Features.Transactions);
		UpdateLocalItemAfter(removeTransaction, addTransaction, item, item.accountTransactionId, isNew, isDelete);
	}

	async function ApproveTransactionAsync(item: TransactionItem) {
		UpdateLocalItem(removeTransaction, addTransaction, item, item.accountTransactionId, false);

		item = await UpdateAndReturnList("Transactions/Approve", false, false, item, Features.Transactions);
		UpdateLocalItem(removeTransaction, addTransaction, item, item.accountTransactionId, false);
	}

	//ReceiptEmails
	async function GetReceiptEmailsAsync() {
		await GetItemsAsync(setReceiptEmails, `ReceiptEmails`, Features.Transactions);
	}

	async function UpdateReceiptEmailAsync(item: ReceiptEmailItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeReceiptEmail, addReceiptEmail, item, item.receiptEmailId, isDelete);

		item = await UpdateAndReturnList("ReceiptEmails", isNew, isDelete, item, Features.Transactions);
		UpdateLocalItemAfter(removeReceiptEmail, addReceiptEmail, item, item.receiptEmailId, isNew, isDelete);
	}

	//Recurring
	async function GetRecurringTransactionsAsync() {
		await GetItemsAsync(setRecurring, `Recurring`, Features.Recurring);
	}

	function SortedRecurringTransactions() {
		let items: RecurringItem[] = useSelector((state: any) => state.recurring.value);
		if (!items) return null;
		if (items.length === 0) return [];

		var result = [...items];
		return result?.sort((a, b) => {
			let first = new Date(a.nextDate.Value);
			first.setHours(0, 0, 0, 0);
			let second = new Date(b.nextDate.Value);
			second.setHours(0, 0, 0, 0);
			return (first.getTime() - second.getTime()) || (b.recurringTransactionId - a.recurringTransactionId)
		}) ?? [] as RecurringItem[];
	}

	async function UpdateRecurringTransactionAsync(item: RecurringItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeRecurring, addRecurring, item, item.recurringTransactionId, isDelete);

		item = await UpdateAndReturnList("Recurring", isNew, isDelete, item, Features.Recurring);
		UpdateLocalItemAfter(removeRecurring, addRecurring, item, item.recurringTransactionId, isNew, isDelete);
	}

	//Users
	async function GetUsersAsync() {
		await GetItemsAsync(setUsers, "User", Features.Users);
	}

	async function UpdateUserAsync(item: UserItem, isNew: boolean = false, isDelete: boolean = false) {
		UpdateLocalItem(removeUser, addUser, item, item.userAccountId, isDelete || item.familyId < 0);

		let target = "User";
		if (isDelete) {
			target += `/${item.userAccountId}`;
		}
		item = await UpdateAndReturnList(target, isNew, isDelete, item, Features.Users);
		UpdateLocalItemAfter(removeUser, addUser, item, item.userAccountId, isNew, isDelete || item.familyId < 0);
	}

	//CurrentUser
	async function UpdatePrimaryEmailAsync(email: string) {
		if (user && FamilyHasAccess(user, Features.UserEmails)) {
			return await request.get(`User/UpdateEmail/${email}`);
		}
	}

	async function UpdateUserEmailAsync(item: UserEmailItem, isNew: boolean = false, isDelete: boolean = false) {
		return await UpdateAndReturnList("UserEmails", isNew, isDelete, item, Features.UserEmails);
	}

	async function UpdateUserPasswordAsync(req: any) {
		try {
			return request.put("User/UpdatePassword", req);
		}
		catch
		{
			return false;
		}
	}

	async function SetSeenInactiveUserCount(count: number) {
		//SeenInactiveCount = count;
		//await _localStorage.SetItemAsync("SeenInactive", count);
		//SeenUpdated?.Invoke();
	}

	return {
		ApproveCheckRequestAsync,
		ApproveTransactionAsync,
		ClearData,
		GenerateFamilyCodesAsync,
		GetAccountsAsync,
		GetBudgetsAsync,
		GetBudgetsWithDateAsync,
		GetBudgetTree,
		GetCategoriesAsync,
		GetCategoryTree,
		GetCheckRequestPdfAsync,
		GetCheckRequestsAsync,
		GetFamilyCodesAsync,
		GetLocalData,
		GetReceiptEmailsAsync,
		GetRecurringTransactionsAsync,
		GetReportPdfAsync,
		GetTimeframesAsync,
		GetTransactionsAsync,
		GetUsersAsync,
		SendFamilyCodesAsync,
		SetSeenInactiveUserCount,
		SortedAccounts,
		SortedCategories,
		SortedCheckRequests,
		SortedRecurringTransactions,
		SortedTransactions,
		UpdateAccountAsync,
		UpdateBudgetAccountsAsync,
		UpdateBudgetAsync,
		UpdateBudgetCategoriesAsync,
		UpdateBudgetColorAsync,
		UpdateBudgetEndDateAsync,
		UpdateBudgetGoalAsync,
		UpdateBudgetNameAsync,
		UpdateBudgetTimeframeAsync,
		UpdateBudgetTimeframeDeleteAsync,
		UpdateBudgetTimeframeEndDateAsync,
		UpdateBudgetTimeframeStartDateAsync,
		UpdateBudgetTimeframeTimeframeAsync,
		UpdateCategoryAsync,
		UpdateCheckRequestAsync,
		UpdateLocalData,
		UpdateReceiptEmailAsync,
		UpdateRecurringTransactionAsync,
		UpdateTimeframeAsync,
		UpdateTransactionAsync,
		UpdateFamilyCodesAsync,
		UpdateUserAsync,
		UpdatePrimaryEmailAsync,
		UpdateUserEmailAsync,
		UpdateUserPasswordAsync
	};
}