import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { TransactionItem } from "../../types";

const AutoCompleteMerchant = (props: any) => {
	let [opts, setOpts] = useState([] as string[]);
	let [val, setVal] = useState(props.Value);

    return (
		<Grid item xs={props.Size}>
			<Autocomplete
				freeSolo
				options={GetListByMostUsed(props.Transactions)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={(props.NoLabel ? "" : props.Label ?? "Description")}
						variant="outlined"
						className={`form-control border-app  mb-3 ${props.Class} col-${props.Size}`}
						required={props.Required} />
				)}
				value={val}
				onInputChange={props.OnChange}
				onChange={setValue} />
		</Grid>
	);

	function GetListByMostUsed(items: TransactionItem[]) {
		if (opts.length > 0) return opts;

		let counts: any[] = [];
		items.forEach(item => {
			let count = counts.find(c => c.key === item.merchant.trim());
			if (count) {
				count.value++;
			} else {
				counts.push({ key: item.merchant.trim(), value: 1 });
			}
		});
		let sortedCounts = counts.sort((a, b) => b.value - a.value);
		sortedCounts = sortedCounts.filter(o => o.value > 2);
		let options = sortedCounts.map(c => c.key);
		if (options.length !== opts.length) {
			setOpts(options);
		}
		return opts;
	}

	function setValue(event: any, newValue: string) {
		setVal(newValue);
	}
};

export default AutoCompleteMerchant;