import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { DateTime } from "../../types";

const Date = (props: any) => {
	return (
		<Grid item xs={props.Size} className={props.Borderless ? "p-0" : "mb-3"}>
			<DatePicker label={props.Label} value={props.Value === undefined ? undefined : dayjs(props.Value)} className={`${props.Borderless ? 'border-none' : 'form-control'}${ props.NoError ? ' no-error' : ''}`} onChange={(e) => props.OnChange(e === null ? null : new DateTime(e?.toDate()))} />
        </Grid>
    );
};

export default Date;