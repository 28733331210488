import Date from "../Inputs/Date";
import { Backdrop, Button, CircularProgress, Grid, Paper, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { BudgetItem, BudgetTransaction, BudgetTransactionTotal, CategoryItem, CurrentUser, CustomReportFilter, CustomReportSettings, DateTime, FamilyFeatureValue, GroupBy, Grouping, SelectableItem, StyledTableCell, StyledTableRow, TransactionItem, USCurrency, UserItem } from "../../types";
import { useState } from "react";
import Dropdown from "../Inputs/Dropdown";
import { Features } from "../../constants";
import { useSelector } from "react-redux";
import ModalHeader from "./ModalHeader";
import ModalFooterCustom from "./ModalFooterCustom";
import { DownloadPdf } from "../../features/ReportService";
import { useItems } from "../../features/Fetch/Items";

const UserReport = (props: any) => {
	let budgets: BudgetItem[] = props.Budgets;
	let categories: CategoryItem[] = props.Categories;
	let transactions: TransactionItem[] = props.Transactions;
	let users: UserItem[] = props.Users;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	let [startDate, setStartDate] = useState(props.StartDate as DateTime);
	let [endDate, setEndDate] = useState(props.EndDate as DateTime);
	let [selectedUsers, setSelectedUsers] = useState(users.map((u: UserItem) => u.userAccountId));
	let [overlay, setOverlay] = useState(false);
	let budgetGroups: Grouping[] = GetBudgetGroups();

	let repo = useItems();

	let allUsersSelected = selectedUsers.length === users.length;

	return (<>
		<ModalHeader Title={users.length === 1 && users[0].isMe ? `My ${props.IsMileage ? 'Mile' : FamilyFeatureValue(user, Features.TransactionTitle)}s` : 'By User'} CanDelete={false} Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Button onClick={() => Export(budgetGroups)}>
						Download Report
					</Button>
				</Grid>
				<Date Size={4} Label="From" Value={startDate} OnChange={(v: any) => setStartDate(new DateTime(v))} />
				<Date Size={4} Label="To" Value={endDate} OnChange={(v: any) => setEndDate(new DateTime(v))} />
				{!props.IsMe &&
					<Dropdown Size={4}
						Class="mb-0"
						Label="Include Users"
						Options={users
							.map(u => {
								return {
									Id: u.userAccountId,
									Value: `${u.firstName} ${u.lastName}`
								} as SelectableItem;
							})}
						Index={0}
						Value={allUsersSelected ? [] : selectedUsers}
						Multiple
						OnChange={UpdateUsers} />
				}
				<Grid item xs={8} />
				<Grid item xs={4} >
					<h1 className="report-total">{USCurrency(budgetGroups.reduce((total: number, g: Grouping) => g.Value.reduce((sub: number, b: BudgetTransaction) => b.Amount.amount + sub, 0) + total, 0))}</h1>
				</Grid>
			</Grid>

			{budgetGroups.map((group: Grouping) => <>
				<h5>{group.Key.firstName} {group.Key.lastName} - {USCurrency(group.Value.reduce((total: number, b: BudgetTransaction) => b.Amount.amount + total, 0))}</h5>
				<TableContainer component={Paper} className="report">
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell>Date</StyledTableCell>
								<StyledTableCell>Merchant</StyledTableCell>
								<StyledTableCell>Budget</StyledTableCell>
								<StyledTableCell>Category</StyledTableCell>
								<StyledTableCell>Description</StyledTableCell>
								<StyledTableCell>Amount</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{group.Value.map((item: BudgetTransaction) =>
								<StyledTableRow key={item.Transaction.accountTransactionId}>
									<StyledTableCell>{item.Transaction.transactionDate.toString()}</StyledTableCell>
									<StyledTableCell>{item.Transaction.merchant}</StyledTableCell>
									<StyledTableCell>{item.Budget.description}</StyledTableCell>
									<StyledTableCell>{item.Category?.description}</StyledTableCell>
									<StyledTableCell>{item.Amount.details}</StyledTableCell>
									<StyledTableCell>{USCurrency(item.Amount.amount)}{!item.Transaction.hasReceipt ? '*' : ''}</StyledTableCell>
								</StyledTableRow>
							)}
						</TableBody>
						<TableFooter>
							<StyledTableRow>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell><b>TOTAL</b></StyledTableCell>
								<StyledTableCell><b>{USCurrency(group.Value.reduce((total: number, b: BudgetTransaction) => b.Amount.amount + total, 0))}</b></StyledTableCell>
							</StyledTableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</>)}
		</div>
		<ModalFooterCustom HideSave={true} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			Generating Report...<br />
			<CircularProgress />
		</Backdrop>
	</>);

	function GetBudgetGroups() {
		let includedUsers = users.filter(u => selectedUsers.includes(u.userAccountId));
		var transacts = transactions.filter(t => t.transactionDate >= startDate.startOfDay() && t.transactionDate <= endDate.startOfDay() && (t.rate != null && t.rate > 0) === props.IsMileage);
		let budgetTransactions = [] as BudgetTransaction[];
		transacts.forEach(transaction => {
			transaction.budgetCategoryAmounts.forEach(amount => {
				budgetTransactions.push({
					Budget: budgets.find(b => b.budgetId === amount.budgetId) as BudgetItem,
					Category: categories.find(c => c.categoryId === amount.categoryId),
					Transaction: transaction,
					Amount: amount,
					User: includedUsers.find(u => `${u.firstName} ${u.lastName}` === transaction.created)
				});
			});
		});
		budgetTransactions = budgetTransactions.filter(b => b.User)
			.sort((a, b) => a.Transaction.transactionDate.diff(b.Transaction.transactionDate) ||
				a.Budget.description.localeCompare(b.Budget.description) ||
				a.Transaction.created.localeCompare(b.Transaction.created) ||
				(a.Category?.description.localeCompare(b.Category?.description ?? '') ?? 1));

		return GroupBy(budgetTransactions, "User");
	}

	function UpdateUsers(newValue: number[]) {
		if (newValue.length === 0) {
			newValue = users.map((u: UserItem) => u.userAccountId);
		}
		setSelectedUsers(newValue);
	}

	async function Export(items: Grouping[]) {
		setOverlay(true);
		setTimeout(() => GenerateReport(items), 50);
	}

	async function GenerateReport(items: Grouping[]) {
		let includedUsers = users.filter(u => selectedUsers.includes(u.userAccountId));
		let name = `${props.IsMe ? 'My ' : ''}${props.IsMileage ? 'Mileage' : `${FamilyFeatureValue(user, Features.TransactionTitle)}s`}${props.IsMe ? '' : ' By User'}`;
		let settings: CustomReportSettings = {
			title: name,
			columns: ['Date', 'Merchant', 'Budget', 'Category', 'Description', 'Amount'],
			grouping: 'User',
			filter: {
				startDate: startDate,
				endDate: endDate,
				createdById: includedUsers.map(i => i.userAccountId),
				includeSpending: !props.IsMileage,
				includeMiles: props.IsMileage,
				includeCheckRequests: false,
				combineSplitBudgets: false,
				includeReceipts: true,
				groupReceipts: false
			} as CustomReportFilter
		}
		let file = await repo.GetReportPdfAsync(settings);
		DownloadPdf(await file.blob() as Blob, name);
		setOverlay(false);
	}
}

export default UserReport;