import React from "react";
import { Grid, styled, TextField, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

const AmountWithTooltip = (props: any) => {
	return (
		<Grid item xs={props.Size}>
			<Tooltip disableHoverListener title={props.Tooltip} placement='top-start' arrow classes={{
				tooltip: props.TooltipClass,
				arrow: props.ArrowClass
			}}>
				<TextField
					label={(props.NoLabel ? "" : props.Label ?? "Amount")}
					variant="outlined"
					className={`form-control border-app mb-3 ${props.Class} col-${props.Size}`}
					value={props.Value === 0 && !props.AllowZero ? null : props.Value}
					InputProps={{
						inputComponent: props.NoDollar ? NoDollarFormat as any : DollarFormat as any
					}}
					onChange={props.OnChange} />
			</Tooltip>
		</Grid>
	);
};

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const DollarFormat = React.forwardRef<NumericFormatProps, CustomProps>(
	function DollarFormat(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				valueIsNumericString
				prefix="$"
			/>
		);
	},
);

const NoDollarFormat = React.forwardRef<NumericFormatProps, CustomProps>(
	function DollarFormat(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				valueIsNumericString
			/>
		);
	},
);

export default AmountWithTooltip;