import { Grid } from "@mui/material";
import React from "react";
import { PencilSquare, XSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { BudgetItem, CategoryItem, CurrentUser, FamilyFeatureValue, TransactionItem } from "../../types";

const Category = (props: any) => {
	let category: CategoryItem = props.Item;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);

	let tCount = transactions?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
	let bCount = budgets?.filter(b => b.categoryIds.map(c => c.id).includes(category.categoryId)).length;
	let cCount = checkRequests?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
	let counts = [] as string[];
	if (tCount > 0) {
		counts.push(`${tCount} ${FamilyFeatureValue(user, Features.TransactionTitle)}${tCount > 1 ? 's' : ''}`);
	}
	if (bCount > 0) {
		counts.push(`${bCount} Budget${bCount > 1 ? 's' : ''}`);
	}
	if (cCount > 0) {
		counts.push(`${cCount} Check Request${cCount > 1 ? 's' : ''}`);
	}
	let inUse = counts.length > 0;

	let editable = category.categoryId > 0;

	return (
		<Grid container className={`py-2 ${props.IsPrimary ? '' : 'text-app cursor-pointer'}`} style={{ textAlign: 'left' }} onClick={editable ? props.OnClick : () => { }}>
			<Grid item xs={1} className={editable ? inUse ? "text-warning" : "text-success" : 'text-danger'} style={{ textAlign: 'right', paddingRight: '10px' }} >
				{editable ? <PencilSquare /> : <XSquare />}
			</Grid>
			<Grid item xs={11} xl={7} >
				<h3>{category.description}</h3>
			</Grid>
			<Grid item className="text-black" style={{ textAlign: 'right' }} xs={12} xl={4} >
				{counts.join(', ')}
			</Grid>
		</Grid >
    );
}

/*
		<div className="col-12">
			<Grid container className="btn-list text-app" onClick={category.categoryId > 0 ? () => props.OnClick() : () => { }}>
				<Grid item xs={6} className="left">
					<h5>{category.description}{category.categoryId < 1 ? " (cannot edit)" : ""}</h5>
				</Grid>
			</Grid>
        </div>
*/
export default Category;