/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Amount from "../Inputs/Amount";
import Date from "../Inputs/Date";
import { AccountItem, BudgetCategoryAmount, BudgetItem, CategoryItem, CurrentUser, DateTime, FamilyFeatureValue, FamilyHasAccess, ReceiptEmailItem, RecurringItem, SelectableItem, TransactionItem, USCurrency, UserItem, VisuallyHiddenInput } from "../../types";
import { useSelector } from "react-redux";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { BoxArrowInRight, BoxArrowInUp, CloudUploadFill, ExclamationCircleFill, Map, ReceiptCutoff } from "react-bootstrap-icons";
import FileResizer from "react-image-file-resizer";
import Divider from "../Inputs/Divider";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import MapView from "./MapView";
import { useFetch } from "../../features/Fetch/Fetch";
import { Features } from "../../constants";
import EditRecurring from "./EditRecurring";
import Toggle from "../Inputs/Toggle";
import DropdownMenu from "../Inputs/DropdownMenu";
import ImportQuickReceipt from "./ImportQuickReceipt";
import ImportManualEntry from "./ImportManualEntry";
import ModalHeader from "./ModalHeader";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import AmountWithTooltip from "../Inputs/AmountWithTooltip";
import { useItems } from "../../features/Fetch/Items";
import Q, { Deferred } from "q";
import AutoCompleteMerchant from "../Inputs/AutoCompleteMerchant";

const EditTransaction = (props: any) => {
	let repo = useItems();

	let [transaction, setTransaction] = useState(props.Transaction as TransactionItem);
	let transactions: TransactionItem[] = props.Transactions;
	let allTransactions = repo.SortedTransactions(null) ?? [] as TransactionItem[];
	let budgets: BudgetItem[] = CurrentBudgets(props.Budgets);
	let categories: CategoryItem[] = props.Categories;
	let receiptEmails: ReceiptEmailItem[] = props.ReceiptEmails;
	let myBudgets = budgets.filter(b => b.isMine || b.budgetId < 1 || !b.requiresApproval);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let accounts: AccountItem[] = useSelector((state: any) => state.accounts.value);
	let recurrings = repo.SortedRecurringTransactions();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let request = useFetch();

	useEffect(() => {
		if (props.AttachedReceipt) {
			LoadReceipts(true);
		}
	}, []);

	let PDFJS = require('pdfjs-dist/webpack');
	let [receiptOpen, setReceiptOpen] = useState(false);
	let [openQuickReceipt, setOpenQuickReceipt] = useState(false);

	let actualTotal: number | null = ActualTotal(transaction);
	if (actualTotal === 0) actualTotal = null;
	let [total, setTotal] = useState(actualTotal);
	let [imageChoice, setImageChoice] = useState(0);
	let [openImport, setOpenImport] = useState(false);
	let [setTaxes, setSetTaxes] = useState(transaction.rate == null || transaction.rate > 0);
	let [noTaxes, setNoTaxes] = useState(transaction.rate === 0);
	let [overlay, setOverlay] = useState(false);
	let [showReceipt, setShowReceipt] = useState(!transaction.hasReceipt);

	let isMiles = transaction.isMiles;
	let isMileSelection = imageChoice === 0;

	if (isMiles && isMileSelection && (transaction.accountTransactionId > 0)) {
		setImageChoice(2);
	}

	if (isMiles && isMileSelection && transaction.accountTransactionId === 0) {
		return (<>
			<ModalHeader Title={`${transaction.accountTransactionId > 0 ? 'Edit' : 'Add'} ${isMiles ? 'Mileage' : FamilyFeatureValue(user, Features.TransactionTitle)}`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(transaction)} />
			<div className="modal-body">
				<Grid container>
					<Button className="mx-3 right" component="label" variant="contained" startIcon={<Map />} onClick={() => setImageChoice(1)}>
						Use Map
					</Button>
					<Button className="mx-3 right" component="label" variant="contained" onClick={() => setImageChoice(2)}>
						Enter Manually
					</Button>
				</Grid>
			</div>
			<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(transaction)} Close={props.Close} />
		</>);
	}

	let fullyImportable = [] as TransactionItem[];
	if (transaction.isExternal) {
		let withinWeek = transactions.filter(t => !t.isExternal && Math.abs(t.transactionDate.dayDiff(transaction.transactionDate)) < 7);
		fullyImportable = withinWeek.filter(t => ActualTotal(t) === actualTotal);
	} else if (transaction.accountTransactionId > 0 && !props.Nested) {
		let withinWeek = transactions.filter(t => t.isExternal && Math.abs(t.transactionDate.dayDiff(transaction.transactionDate)) < 7);
		fullyImportable = withinWeek.filter(t => ActualTotal(t) === actualTotal);
	}

	let isMapView = imageChoice === 1;
	let isMainView = !isMiles || imageChoice === 2;
	let showMapView = isMiles &&
		transaction.accountTransactionId === 0 &&
		isMapView;
	let isSynced = transaction.isExternal;
	let showEnteredBy = user.isHoH && transaction.accountTransactionId !== 0 && transaction.created;
	let showManualImport = fullyImportable.length > 0;
	let showQuickReceipt = isMainView && (receiptEmails?.length ?? 0) > 0;
	let showImageDropdown = isMainView && (showManualImport || showQuickReceipt);
	let showTaxes = FamilyHasAccess(user, Features.Taxes) && !transaction.isIncome && !isMiles;
	let ttColor = 'info';

	return (<>
		<ModalHeader Title={`${transaction.accountTransactionId > 0 ? 'Edit' : 'Add'} ${isMiles ? 'Mileage' : FamilyFeatureValue(user, Features.TransactionTitle)}`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(transaction)} />
		<div className="modal-body">
			<Grid container columnSpacing={1}>
				{showMapView &&
					<MapView OnCalc={UpdateMiles} OnSave={SetMapReceipt} OnCancel={() => setImageChoice(2)} />
				}
				{showEnteredBy && (
					<Grid item xs={12} className="mb-1">Entered by {transaction.created}</Grid>
				)}
				{showReceipt && transaction.receipts.map((receipt: any) => {
					var rowNum = transaction.receipts.indexOf(receipt);
					return (
						<div className="col-12 centered">
							<InnerImageZoom src={`data:image/bmp;base64,${receipt}`} className="img-zoom mb-3" zoomType="hover" height={600} hideHint={true} zoomScale={1} />
							<Button
								className="mb-0"
								component="label"
								variant="text"
								onClick={() => RemoveReceipt(rowNum)}>
								Remove
							</Button>
							<Divider />
						</div>
					);
				})}
				<Grid item xs={12} />
				{isMainView && (
					isMiles ?
						<Amount NoDollar Size={6} Value={transaction.miles} Label="Miles" OnChange={(val: any) => UpdateMiles(val.target.value)} />
					: isSynced ? 
						<Grid item xs={6} style={{ alignSelf: 'center' }}>
							<h4>Total: {USCurrency(total ?? 0)}</h4>
						</Grid>
					:
						<Amount Size={6} Value={total} OnChange={(val: any) => UpdateAmount(val.target.value, -1)} />
				)}
				<Grid item xs={6}>
					{showReceipt ?
						!showImageDropdown ?
							isMainView ?
								(
									<Button
										className="mb-3 right"
										component="label"
										variant="contained"
										startIcon={<CloudUploadFill />}>
										Add Receipt
										<VisuallyHiddenInput type="file" onChange={UploadFile} />
									</Button>
								) :
								<></> :
							(
								<DropdownMenu OpenElement={
									<Button
										className="mb-3"
										component="label"
										variant="contained"
										startIcon={<BoxArrowInUp />}>
										Import
									</Button>
									}
									Items={AddMenuItems() ?? []}
									Open={receiptOpen}
									OnOpen={() => setReceiptOpen(true)}
									OnClose={() => setReceiptOpen(false)}>
								</DropdownMenu>
							) :
						<Button
							className="mb-3 right"
							component="label"
							variant="contained"
							onClick={() => LoadReceipts()}>
							View Receipts
						</Button>
					}
				</Grid>
				{isMainView && <>
					{isMiles ?
						<Grid item xs={12} className="mb-3">
							@{USCurrency(transaction.rate ?? 0)}/mile = {USCurrency((transaction.miles ?? 0) * (transaction.rate ?? 0))}
						</Grid>
						:
						<AutoCompleteMerchant Transactions={allTransactions} Label={transaction.isIncome ? "Payee" : "Merchant"} Value={transaction.merchant} OnChange={UpdateMerchant} Size={12} />
					}
					{showTaxes && <>
						{setTaxes ?
							<Amount Size={6} Value={transaction.rate ?? 0} Label="Taxes" OnChange={(val: any) => UpdateRate(val.target.value)} /> :
							<Grid xs={6} />
						}
						<Toggle Size={6} OnChange={UpdateNoTax} Checked={noTaxes} Label="No Sales Tax?" />
					</>}
					{transaction.budgetCategoryAmounts.map(budget => {
						var rowNum = transaction.budgetCategoryAmounts.indexOf(budget);
						return (<>
							{!isMiles && <Divider />}
							<Grid container>
								{budget.isRejected && (
									<Grid item xs={12} className="mb-3 bg-warning" >
										<ExclamationCircleFill className="text-danger mx-3" />
										{budget.approvalNote?.length > 0 && (
											<>
												From {users.find(u => u.userAccountId === budget.reviewerId)?.firstName} {users.find(u => u.userAccountId === budget.reviewerId)?.lastName}: {budget.approvalNote}
											</>
										)}
									</Grid>
								)}
								{budgets.filter(b => b.budgetId === budget.budgetId).length === 0 && <>
									This part of this transaction is assigned to a budget that is not available to you on the selected day.
								</>}
								<Dropdown Size={6}
									Class="mb-0"
									Label="Budget"
									Options={GetBudgetDict()}
									Index={rowNum}
									Value={budget.budgetId}
									OnChange={UpdateBudget} />
								{budget.categoryId === 0 ?
									<Description Class="mx-3" Label="Category" Value={budget.categoryName} OnChange={(val: any) => UpdateCategoryName(val.target.value, rowNum)} Size={5} /> :
									<Dropdown Size={5}
										Class="mb-0 mx-3"
										Label="Category"
										Options={GetCategoryDict(budget)}
										Index={rowNum}
										Value={budget.categoryId}
										OnChange={UpdateCategory} />
								}
								{transaction.budgetCategoryAmounts.length > 1 ? (
									<AmountWithTooltip
										Size={5}
										Class="mb-0"
										Tooltip={DisplayBudgetedAmounts(true)}
										TooltipClass={`tooltip-${ttColor}`}
										ArrowClass={`arrow-${ttColor}`}
										Value={budget.amount}
										OnChange={(val: any) => UpdateAmount(val.target.value, rowNum)} />
								) : (<Grid item xs={5} />)}
								{budgets.filter(b => b.budgetId === budget.budgetId).length > 0 && myBudgets.filter(b => b.budgetId === budget.budgetId).length === 0 ? (
									<Dropdown Size={6}
										Class="mb-0 mx-3"
										Label="Select an approver"
										Options={users
											.filter(u => u.isActive && budgets.find(b => b.budgetId === budget.budgetId)?.userIds.includes(u.userAccountId))
											?.map(u => {
												return {
													Id: u.userAccountId,
													Value: `${u.firstName} ${u.lastName}`
												} as SelectableItem;
											})}
										Index={rowNum}
										Value={budget.reviewerId}
										OnChange={UpdateApprover} />
								) : (
									<Grid item xs={6}></Grid>
								)}
								<Description Size={12} Label="Additional Details" Value={budget.details} OnChange={(val: any) => UpdateDetails(val.target.value, rowNum)} />
							</Grid>
						</>);
					})}
					{!isMiles && (
						<Grid container>
							<Grid item xs={6}>
								<Button onClick={AddBudget}>
									Split{transaction.budgetCategoryAmounts.length > 1 ? " Again" : ""}
								</Button>
							</Grid>
							{transaction.budgetCategoryAmounts.length > 1 && (
								<Grid item xs={6}>
									<Button onClick={() => RemoveBudget(transaction.budgetCategoryAmounts.length - 1)}>
										Remove
									</Button>
								</Grid>
							)}
						</Grid>
					)}
					<Divider />
					{transaction.budgetCategoryAmounts.length > 1 && DisplayBudgetedAmounts()}
					<div className="mb-5"></div>
					{FamilyHasAccess(user, Features.Income) && transaction.isExternal &&
						<Grid item xs={12} className="mb-3">
							Account: {accounts.find(a => a.accountId === transaction.accountId)?.description}
						</Grid>
					}
					{!props.Nested && !transaction.isExternal && accounts.filter(a => a.financialInstitution).length > 1 && FamilyHasAccess(user, Features.Income) &&
						<Dropdown Size={12}
							Class="mb-0"
							Label="Account"
							Options={GetAccountDict()}
							Value={transaction.accountId}
							OnChange={UpdateAccount} />
					}
					<Date Size={12} Label="Transaction Date" Value={transaction.transactionDate} OnChange={UpdateDate} />
					<Toggle Size={12} OnChange={UpdateIsAdminReview} Checked={transaction.isAdminReview ?? false} Label="Request Review" />
					{!props.Nested && FamilyHasAccess(user, Features.Recurring) && <>
						<Toggle Size={12} OnChange={UpdateIsRecurring} Checked={transaction.isRecurring ?? false} Label="Repeating" />
						{transaction.isRecurring && <>
							<Dropdown Size={12}
								Class="mb-0"
								Label="Recurring Transaction"
								Options={GetRecurringDict()}
								Value={transaction.recurringTransactionId}
								OnChange={UpdateRecurring} />
						</>}
						{transaction.recurringTransaction &&
							<EditRecurring Nested Accounts={accounts} Transaction={transaction.recurringTransaction} Budgets={budgets} OnChange={(t: RecurringItem) => UpdateTransaction(t)} />
						}
					</>}
					{FamilyHasAccess(user, Features.Income) &&
						<Toggle Size={12} OnChange={UpdateIsIncome} Checked={transaction.isIncome ?? false} Label="Income" />
					}
				</>}
				{showQuickReceipt &&
					<ImportQuickReceipt Open={openQuickReceipt} Close={CloseQuickReceipt} Items={receiptEmails} Confirm={ConfirmQuickReceipt} />
				}
				{showManualImport &&
					<ImportManualEntry Open={openImport} Close={CloseImport} FullItems={fullyImportable} ConfirmFull={ConfirmImport} Categories={categories} />
				}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={Save} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
    </>);

	//Page Load
	function CurrentBudgets(allBudgets: BudgetItem[]) {
		return allBudgets?.filter(b => !b.activeTimeframes || b.activeTimeframes.length === 0 || b.activeTimeframes.filter(t => (t.startDate?.startOfDay() ?? new DateTime()) < transaction.transactionDate && (!t.endDate || t.endDate!.addDays(1).startOfDay() > transaction.transactionDate)).length > 0) ?? [] as BudgetItem[];
	}
	function ActualTotal(item: TransactionItem) {
		return Math.round(item.budgetCategoryAmounts.reduce((total, t) => total + (t.amount * 100), 0)) / 100;
	}
	function ShouldShowSave() {
		if (isMiles) {
			if (imageChoice === 2) return true;
			return false;
		}
		if (!transaction.merchant && !transaction.hasReceipt && !transaction.miles) return false;
		if (transaction.budgetCategoryAmounts.length > 1 && total !== ActualTotal(transaction)) return false;
		return true;
	}
	function AddMenuItems() {
		let result: any[] = [];

		result.push({
			Text: <Button className="mb-3" component="label" variant="text" startIcon={<CloudUploadFill />}>
				Upload Image
				<VisuallyHiddenInput type="file" onChange={UploadFile} />
			</Button>,
			OnClick: () => { }
		});

		if (showQuickReceipt) {
			result.push({
				Icon: <ReceiptCutoff />,
				Text: 'Import Quick Receipt',
				OnClick: QuickReceipt
			});
		}

		if (showManualImport) {
			result.push({
				Icon: <BoxArrowInRight />,
				Text: `Import ${isSynced ? 'Manual' : 'Automatic'} ${FamilyFeatureValue(user, Features.TransactionTitle)}`,
				OnClick: Import
			});
		}

		return result;
	}

	function GetAccountDict() {
		let result: SelectableItem[] = [];
		accounts.forEach(b => result.push({
			Id: b.accountId,
			Value: b.description
		}));
		result.push({
			Id: 0,
			Value: "Cash/Other"
		});
		return result;
	}
	function GetBudgetDict() {
		let result: SelectableItem[] = [];
		result.push({
			Id: -2,
			Value: "Your Budgets"
		});
		myBudgets.forEach(b => result.push({
			Id: b.budgetId,
			Value: b.description
		}));
		result.push({
			Id: -2,
			Value: "Needs Approval"
		});
		budgets
			.filter(b => !b.isMine && b.budgetId > 0 && b.requiresApproval && b.userIds.length > 0)
			.forEach(b => {
				if (users.filter(u => u.isActive && b.userIds.includes(u.userAccountId)).length > 0) {
					result.push({
						Id: b.budgetId,
						Value: b.description
					});
				}
			});
		return result;
	}
	function GetRecurringDict() {
		let result: SelectableItem[] = [];
		recurrings
			?.filter(r => r.isIncome === transaction.isIncome)
			?.forEach(b => result.push({
				Id: b.recurringTransactionId,
				Value: `${b.description} - ${b.nextDate} - ${USCurrency(b.nextAmount)}`
			}));
		result.push({
			Id: 0,
			Value: "+ New Recurring"
		});
		return result;
	}
	function GetCategoryDict(budget: BudgetCategoryAmount) {
		let result: SelectableItem[] = [];
		var thisBudget = budgets
			.find(b => b.budgetId === budget.budgetId);
		thisBudget
			?.categories
			?.filter(cat => !thisBudget?.categoryIds || (thisBudget?.categoryIds?.filter(c => c.id === cat.categoryId && (!c.startDate || c.startDate.startOfDay() < transaction?.transactionDate) && (!c.endDate || c.endDate!.addDays(1).startOfDay() > transaction?.transactionDate)).length ?? 0) > 0)
			?.forEach(b => result.push({
				Id: b.categoryId,
				Value: b.description
			}));
		if (user.isHoH) {
			result.push({
				Id: 0,
				Value: "+ Add Category"
			});
		}
		return result;
	}

	//Quick Receipt
	function QuickReceipt() {
		setReceiptOpen(false);
		setOpenQuickReceipt(true);
	}

	function CloseQuickReceipt() {
		setOpenQuickReceipt(false);
	}

	function ConfirmQuickReceipt(selectedReceipt: number) {
		setOpenQuickReceipt(false);
		setTransaction({ ...transaction, hasReceipt: true });
		request
			.get(`ReceiptEmails/Receipt/${selectedReceipt}`)
			.then((receipt: ReceiptEmailItem) => {
				let item = {
					...transaction,
					receipts: [...transaction.receipts].concat(receipt.receipts!),
					editedReceipt: true,
					hasReceipt: true,
					receiptEmailIds: [...transaction.receiptEmailIds ?? [], selectedReceipt]
				};
				setTransaction({
					...item
				});
			});
	}

	//Import Manual
	function Import() {
		setReceiptOpen(false);
		setOpenImport(true);
	}

	function CloseImport() {
		setOpenImport(false);
	}

	function ConfirmImport(linked: TransactionItem) {
		setOverlay(true);
		setOpenImport(false);
		if (!isSynced) {
			let tmp = { ...linked };
			linked = { ...transaction };
			transaction = { ...tmp };
		}
		linked = { ...linked };
		let item = {
			...transaction,
			budgetCategoryAmounts: [...linked.budgetCategoryAmounts],
			hasReceipt: linked.hasReceipt || transaction.hasReceipt,
			needsReimbursement: linked.needsReimbursement,
			isAdminReview: linked.isAdminReview,
			transactionDate: linked.transactionDate,
			importedTransactionId: linked.accountTransactionId
		};
		setTransaction({ ...item });
		if (linked.hasReceipt) {
			request
				.get(`Transactions/Receipt/${linked.accountTransactionId}`)
				.then((receipt: TransactionItem) => {
					item = {
						...item,
						receipts: [...item.receipts].concat(receipt.receipts),
						editedReceipt: true
					};
					setTransaction({
						...item
					});
					setOverlay(false);
				});
		} else {
			setOverlay(false);
		}
	}

	//Edit Fields
	function UpdateMerchant(event: any, newValue: string) {
		var item = ({ ...transaction, merchant: newValue });
		setTransaction(item);
	}
	function AddBudget() {
		if (transaction.budgetCategoryAmounts.length === 1 && transaction.budgetCategoryAmounts[0].amount === total) {
			let bcas = [] as BudgetCategoryAmount[];
			bcas.push({ ...transaction.budgetCategoryAmounts[0], amount: 0 });
			transaction = { ...transaction, budgetCategoryAmounts: bcas };
		}

		let bca: BudgetCategoryAmount[] = [...(transaction.budgetCategoryAmounts)];
		bca.push({
			budgetId: budgets[0].budgetId,
			categoryId: budgets[0].categoryIds[0].id,
			amount: 0
		} as BudgetCategoryAmount)
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateBudget(newValue: any, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				let reviewerId: number | null = null;
				if (myBudgets.filter(b => b.budgetId === newValue).length === 0) {
					reviewerId = budget.reviewerId;
					let budgetUsers = users
						.filter(u => u.isActive && budgets.find(b => b.budgetId === newValue)?.userIds.includes(u.userAccountId));
					if (budgetUsers.length > 0 && budgetUsers.filter(b => b.userAccountId === (reviewerId ?? 0)).length === 0) {
						reviewerId = budgetUsers[0].userAccountId;
					}
				}
				bca.push({ ...budget, budgetId: newValue, categoryId: budgets.find(b => b.budgetId === newValue)?.categories[0]?.categoryId ?? 0, reviewerId: reviewerId });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function RemoveBudget(idx: number) {
		let bca: BudgetCategoryAmount[] = [...(transaction.budgetCategoryAmounts)];
		bca.splice(idx, 1);;
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateCategory(newValue: any, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, categoryId: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateCategoryName(newValue: string, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, categoryName: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateApprover(newValue: any, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, reviewerId: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateAccount(newValue: any) {
		let item = ({ ...transaction, accountId: newValue });
		setTransaction(item);
	}
	function UpdateDate(newValue: any) {
		let item = ({ ...transaction, transactionDate: new DateTime(newValue) });
		setTransaction(item);
	}
	function UpdateMiles(newValue: string) {
		let val: number | null = null;
		if (newValue) val = parseFloat(newValue);
		let item = ({ ...transaction, miles: val, merchant: `Mileage: ${val} miles` });
		setTransaction(item);
	}
	function UpdateRate(newValue: string) {
		let val: number | null = null;
		if (newValue) val = parseFloat(newValue);
		if (val === 0) val = null;
		let item = ({ ...transaction, rate: val });
		setTransaction(item);
	}
	function UpdateAmount(newValue: string, index: number) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
			if (isNaN(val)) {
				setTransaction({ ...transaction });
				return;
			}
		}
		if (index === -1) {
			setTotal(val);
		}
		if (index > -1) {
			let amounts = [] as BudgetCategoryAmount[];
			for (let i in transaction.budgetCategoryAmounts) {
				let amount = transaction.budgetCategoryAmounts[i].amount;
				if (parseInt(i) === index) {
					amount = val;
				}
				amounts.push({ ...transaction.budgetCategoryAmounts[i], amount: amount });
			}
			let item = { ...transaction, budgetCategoryAmounts: amounts };
			setTransaction(item);
		}
	}
	function UpdateDetails(newValue: string, index: number) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, details: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateIsAdminReview(event: any, newValue: boolean) {
		let item = ({ ...transaction, isAdminReview: newValue });
		setTransaction(item);
	}
	function UpdateIsRecurring(event: any, newValue: boolean) {
		let item = ({
			...transaction,
			isRecurring: newValue,
			recurringTransaction: undefined,
			recurringTransactionId: undefined
		});
		setTransaction(item);
	}
	function UpdateIsIncome(event: any, newValue: boolean) {
		let item = ({ ...transaction, isIncome: newValue });
		setTransaction(item);
	}
	function UpdateRecurring(newValue: any) {
		if (newValue > 0) {
			let item = ({ ...transaction, recurringTransaction: undefined, recurringTransactionId: newValue });
			setTransaction(item);
		} else {
			let rec = transaction.recurringTransaction;
			if (!rec) {
				rec = {
					recurringTransactionId: 0,
					accountId: 0,
					budgetId: 0,
					categoryId: 0,
					days: 0,
					months: 1,
					description: '',
					nextAmount: 0,
					isVariable: false,
					minAmount: 0,
					maxAmount: 0,
					nextDate: new DateTime(),
					isIncome: transaction.isIncome,
				} as RecurringItem;
			}
			let item = ({
				...transaction,
				recurringTransaction: rec,
				recurringTransactionId: newValue
			});
			setTransaction(item);
		}
	}
	function UpdateTransaction(recurring: RecurringItem) {
		let item = { ...transaction, recurringTransaction: recurring };
		setTransaction(item);
	}
	function UpdateNoTax(event: any, newValue: boolean) {
		if (newValue) {
			let item = ({ ...transaction, rate: 0 });
			setTransaction(item);
		} else {
			let item = ({ ...transaction, rate: null });
			setTransaction(item);
		}
		setNoTaxes(newValue);
		setSetTaxes(!newValue);
	}

	//Images
	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		setReceiptOpen(false);
		let { files } = event.target;
		let file = (files as FileList)[0];
		if (file.type.startsWith("image")) {
			let resizedFile = await new Promise(resolve =>
				FileResizer.imageFileResizer(file, 1280, 1280, file.type.replace("image/", ""), 100, 0, uri => resolve(uri))
			);
			let item = {
				...transaction,
				receipts: [...transaction.receipts, resizedFile!.toString().substring(resizedFile!.toString().indexOf(',') + 1)],
				editedReceipt: true,
				hasReceipt: true,
				receiptEmailIds: undefined
			};
			setTransaction(item);
		} else {
			let data = await ReadFileData(file);
			let pdfjs = await PDFJS;
			let pdfPromise = await pdfjs.getDocument(data).promise;
			
			var pdf = await pdfPromise;
			iterate(pdf, 1);
		}
	}
	async function iterate(pdf: any, currentPage: number = 1) {
		var pages: any[] = [];
		if (currentPage <= pdf.numPages) pages = await getPage(pdf, currentPage, pages);

		if (pages.length > 0) {
			let item = {
				...transaction,
				receipts: [...transaction.receipts].concat(pages.map((final: any) => final!.toString().substring(final.toString().indexOf(',') + 1))),
				editedReceipt: true,
				hasReceipt: true,
				receiptEmailIds: undefined
			};
			setTransaction(item);
		}
	}
	async function getPage(pdf: any, currentPage: number, pages: any[]): Promise<any[]> {
		var deferred: Deferred<any[]> = Q.defer();

		var canvas = document.createElement('canvas');
		let page = await pdf.getPage(currentPage);
		let viewport = page.getViewport({ scale: 3 });
		let context = canvas.getContext("2d");
		canvas.height = viewport.height;
		canvas.width = viewport.width;
		await page.render({ canvasContext: context, viewport: viewport }).promise;

		await canvas.toBlob(async (result) => {
			var resizedFile: any = await new Promise(resolve =>
				FileResizer.imageFileResizer(result!, 1280, 6400, 'jpg', 100, 0, uri => resolve(uri))
			);
			pages.push(resizedFile);

			if (currentPage < pdf.numPages) {
				currentPage++;
				pages = await getPage(pdf, currentPage, pages);
			}
			deferred.resolve(pages);
		});

		return deferred.promise;
	}
	function combinePages(pages: any[]) {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		canvas.width = 800;
		canvas.height = pages.reduce((total: number, page: HTMLImageElement) => page.height + total, 0);

		var y = 0;
		for (var i in pages) {
			context!.drawImage(pages[i], 0, y);
			y += pages[i].height;
		}

		return canvas.toDataURL();
	}
	function ReadFileData(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(e.target!.result);
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsDataURL(file);
		});
	};

	function SetMapReceipt(file: string) {
		let t = {
			...transaction,
			receipts: [file],
			receiptString: '',
			receiptItem: null,
			hasReceipt: true,
			editedReceipt: true
		}
		setTransaction(t);
		setImageChoice(2);
	}

	function LoadReceipts(attach: boolean = false) {
		if (transaction.accountTransactionId === 0) {
			setShowReceipt(true);
			return;
		}
		setOverlay(true);
		request
			.get(`Transactions/Receipt/${transaction.accountTransactionId}`)
			.then((receipt: TransactionItem) => {
				let rec = receipt.receipts;
				if (attach) {
					for (let i in props.AttachedReceipt.receipts)
					rec.push(props.AttachedReceipt.receipts[i]);
				}
				let item = {
					...transaction,
					receipts: receipt.receipts,
					receiptEmailIds: attach ? [...[], props.AttachedReceipt!.receiptEmailId] : undefined,
					hasReceipt: true,
					editedReceipt: true
				};
				setTransaction({
					...item
				});
				setOverlay(false);
				setShowReceipt(true);
			});
	}

	function RemoveReceipt(idx: number) {
		let rct: string[] = [...(transaction.receipts)];
		rct.splice(idx, 1);;
		let item = ({ ...transaction, receipts: rct, editedReceipt: true, hasReceipt: rct.length > 0, receiptEmailIds: ((transaction.receiptEmailIds?.length ?? 0) > rct.length) ? undefined : transaction.receiptEmailIds });
		setTransaction(item);
	}

	//Budget Split
	function DisplayBudgetedAmounts(isTooltip: boolean = false) {
		if (actualTotal === total) {
			let text = `All ${USCurrency(total ?? 0)} Budgeted!`;
			if (isTooltip) {
				ttColor = 'success';
				return text;
			}
			return (<>
				<Grid item className="text-success mx-3">
					{text}
				</Grid>
				<Divider />
			</>);
		} else if ((actualTotal ?? 0) > (total ?? 0)) {
			let text = `Budgeted ${USCurrency((actualTotal ?? 0) - (total ?? 0))} over total amount of ${USCurrency(total ?? 0)}`;
			if (isTooltip) {
				ttColor = 'error';
				return text;
			}
			return (<>
				<Grid item className="text-danger mx-3">
					{text}					
				</Grid>
				<Divider />
			</>);
		} else {
			let text = `${USCurrency((total ?? 0) - (actualTotal ?? 0))} of ${USCurrency(total ?? 0)} remaining`;
			if (isTooltip) {
				ttColor = 'info';
				return text;
			}
			return (<>
				<Grid item className="text-app mb-3 mx-3" xs={12}>
					{text}
				</Grid>
				<Button className="mb-1 mx-1 right" component="label" variant="contained" startIcon={<ReceiptCutoff />} onClick={SplitEvenly}>
					Split Remainder Evenly
				</Button>
				<Button className="mb-1 mx-1 right" component="label" variant="contained" startIcon={<ReceiptCutoff />} onClick={SplitProportionally}>
					Split Remainder Proportionally
				</Button>
				<Divider />
			</>);
		}
	}
	function SplitEvenly() {
		let dif = (total ?? 0) - (actualTotal ?? 0);
		let amounts = [] as BudgetCategoryAmount[];
		for (let i in transaction.budgetCategoryAmounts) {
			let amount = transaction.budgetCategoryAmounts[i].amount ?? 0;
			let perc = 1 / transaction.budgetCategoryAmounts.length;

			if (parseInt(i) === transaction.budgetCategoryAmounts.length - 1) {
				let leftover = (total ?? 0) - amounts.reduce((tot, t) => tot + t.amount, 0)
				amount = leftover;
			} else {
				amount += dif * perc;
			}

			amount = Math.round(amount * 100) / 100;
			amounts.push({ ...transaction.budgetCategoryAmounts[i], amount: amount });
		}
		let item = { ...transaction, budgetCategoryAmounts: amounts };
		setTransaction(item);
	}
	function SplitProportionally() {
		if (!actualTotal) SplitEvenly();

		let dif = (total ?? 0) - (actualTotal ?? 0);
		let amounts = [] as BudgetCategoryAmount[];
		for (let i in transaction.budgetCategoryAmounts) {
			let amount = transaction.budgetCategoryAmounts[i].amount ?? 0;
			let perc = amount / actualTotal!;

			if (parseInt(i) === transaction.budgetCategoryAmounts.length - 1) {
				let leftover = (total ?? 0) - amounts.reduce((tot, t) => tot + t.amount, 0)
				amount = leftover;
			} else {
				amount += dif * perc;
			}

			amount = Math.round(amount * 100) / 100;
			amounts.push({ ...transaction.budgetCategoryAmounts[i], amount: amount });
		}
		let item = { ...transaction, budgetCategoryAmounts: amounts };
		setTransaction(item);
	}

	function Save() {
		if (transaction.budgetCategoryAmounts.length === 1 && transaction.budgetCategoryAmounts[0].amount !== total) {
			let bcas = [] as BudgetCategoryAmount[];
			bcas.push({ ...transaction.budgetCategoryAmounts[0], amount: total ?? 0 });
			transaction = { ...transaction, budgetCategoryAmounts: bcas, rate: (!showTaxes || setTaxes) ? transaction.rate : null };
		}
		props.OnSave(transaction);
	}
}


export default EditTransaction;