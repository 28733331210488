import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IdStartEnd, SelectableItem, TimeframeDescription, TimeframeItem } from "../../types";
import Dropdown from "../Inputs/Dropdown";

const EditTimePeriodTimeframe = (props: any) => {
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let [period, setPeriod] = useState(props.Item as IdStartEnd);

	return (
		<Dialog open={true} onClose={props.Close}>
			<DialogContent className="px-5 mt-3">
				<Dropdown Size={12}
					Class="mb-0"
					Label="Timeframe"
					Options={timeframes
						.map(t => {
							return {
								Id: t.timeframeId,
								Value: TimeframeDescription(t)
							} as SelectableItem;
						})}
					Index={0}
					Value={period.id}
					OnChange={UpdateTimeframe} />
			</DialogContent>
			<DialogActions className="px-3">
				<Button className="mx-3 my-4" onClick={props.Close} variant="outlined">Cancel</Button>
				<Button onClick={() => props.Confirm(period, props.Item)} variant="contained" className="bg-success mx-3">Confirm</Button>
			</DialogActions>
		</Dialog>
	);

	function UpdateTimeframe(newValue: any) {
		let item = ({ ...period, id: newValue, timeframe: timeframes.find(t => t.timeframeId === newValue) });
		setPeriod(item);
	}
}

export default EditTimePeriodTimeframe;